import React from 'react';
import * as Sentry from '@sentry/browser';
import { actionNames } from './actions';
import {
  matchChannelToExternal,
  forceLastMessageChannel,
  addOrUpdateReceivedMessage,
  getTypeChannel,
  getInfosChannel,
  //treatChannel,
  createListenerPaste,
  createListenerDrag,
  treatMessageType,
  addChatToChatList,
  hasProductInStock,
  hashHistoryMobile,
  removeHashChat,
} from './functions';
import { toast } from 'react-toastify';
import checkAvatar from '../../../utils/checkAvatar';
import formatPhone from '../../../utils/formatPhone';
import { QuickMessages } from '../../../components/chat/content/chat/quickMessages/QuickMessages';
import notification from '../../../utils/notification';
import { isMobile } from 'react-device-detect';
export const MenuTypes = {
  TRANSFER_MENU: 'tranfer',
  TAG_MENU: 'tag',
  FINISH_MENU: 'finish',
  PAYMENT_MENU: 'payment',
  CART_MENU: 'cart',
};

const MenuTypesBeforeInit = {
  TRANSFER_MENU: 'tranfer',
  TAG_MENU: 'tag',
  FINISH_MENU: 'finish',
  PAYMENT_MENU: 'payment',
  CART_MENU: 'cart',
};

export default function chat(state = {}, action) {
  let general;
  let activeTabNavigation;
  let noAttendantTabActive;
  let chats;
  let chat;

  let activeFilters;
  let quick_messages;
  let search_quick_messages;

  let _settings;
  let activeWallet;

  // State padrao de filtros
  const activeFiltersModel = {
    attendant: [],
    date: [],
    department: [],
    tag: [],
    chat: [],
    channel: [],
  };
  let menus = [];
  if (MenuTypesBeforeInit) {
    // State padrao dos menus ( modais )
    menus = [
      {
        type: MenuTypesBeforeInit.TRANSFER_MENU,
        isOpen: false,
        operators: [],
        operator_id: null,
        wallet: 0,
        isLoading: false,
        hasAsyncRequest: false,
      },
      {
        type: MenuTypesBeforeInit.TAG_MENU,
        isOpen: false,
        contactTags: [],
        availableTags: [],
        isLoading: false,
        hasAsyncRequest: false,
      },
      {
        type: MenuTypesBeforeInit.FINISH_MENU,
        isLoading: false,
        hasAsyncRequest: false,
      },
      {
        type: MenuTypesBeforeInit.PAYMENT_MENU,
        isOpen: false,
      },
      {
        type: MenuTypesBeforeInit.CART_MENU,
        isOpen: false,
      },
    ];
  }

  switch (action.type) {
    /** MOBILE */
    case actionNames.SHOW_BLOCK_ONE: {
      let currentState = Object.assign({}, state);
      currentState.mobile = {
        blockOne: true,
        blockTwo: false,
        blockThree: false,
      };
      return {
        ...currentState,
      };
    }

    case actionNames.SHOW_BLOCK_TWO: {
      let currentState = Object.assign({}, state);
      currentState.mobile = {
        blockOne: false,
        blockTwo: true,
        blockThree: false,
      };
      return {
        ...currentState,
      };
    }

    case actionNames.SHOW_BLOCK_THREE: {
      let currentState = Object.assign({}, state);
      currentState.mobile = {
        blockOne: false,
        blockTwo: false,
        blockThree: true,
      };
      return {
        ...currentState,
      };
    }

    case actionNames.GET_PROTOCOL: {
      if (action.chat_id > 0) {
        state = Object.assign({}, state);
        state.chats = state.chats.map((chat) => {
          if (chat.id === action.chat_id) {
            chat.loading_protocol = true;
          }
          return chat;
        });
      }
      return state;
    }
    case actionNames.GET_PROTOCOL_SUCCESS: {
      action.payload.chat_id = parseInt(action.payload.chat_id);

      if (action.payload.chat_id > 0) {
        state = Object.assign({}, state);
        state.chats = state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            chat.loading_protocol = false;
            chat.protocol_number = action.payload.protocol_number;
          }
          return chat;
        });
      }
      return state;
    }
    case actionNames.GET_PROTOCOL_FAILURE: {
      toast.warning('Erro ao buscar o protocolo.');
      if (action.payload.chat_id > 0) {
        state = Object.assign({}, state);
        state.chats = state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            chat.loading_protocol = false;
          }
          return chat;
        });
      }
      return state;
    }

    case actionNames.CLOSE_ALL_OPENED_CHATS: {
      // Used to close all opened chats in global state
      // This action is a lot of different that close_chat
      let currentState = Object.assign({}, state);

      let currentOpenedChat = currentState.chats.find((chat) => chat.open);

      currentOpenedChat.open = false;
      currentState.chatSelectedId = 0;

      return {
        ...currentState,
      };
    }
    /**
     * Reducers for socket
     */
    case actionNames.OPEN_SOCKET_REQUEST: {
      if (action.tryAgain) {
        state.tryConnection = 0;
      }
      return {
        ...state,
        tryConnection: (state.tryConnection || 0) + 1,
        socket: null,
        error: '',
        errorCode: null,
        errorAction: null,
        hasAsyncRequest: true,
        loading: true,
      };
    }

    case actionNames.OPEN_SOCKET_FAILURE: {
      return {
        ...state,
        socket: null,
        error: action.error,
        errorCode: 1001,
        errorAction: 'OPEN_SOCKET_FAILURE',
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
      };
    }

    case actionNames.OPEN_SOCKET_SUCCESS: {
      return {
        ...state,
        socket: action.socket,
        error: '',
        errorCode: null,
        errorAction: null,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
      };
    }

    case actionNames.OPEN_PASSIVE_SOCKET_SUCCESS: {
      return {
        ...state,
        passiveSocket: action.passiveSocket,
      };
    }

    case actionNames.ADD_MESSAGE: {
      let newMessage = action.data;

      let foundChat = state.chats.find(
        (c) => c.contact.id === newMessage.contact_id
      );
      if (!foundChat) return state;
      let text_before = '';

      if (
        newMessage.body &&
        state.config &&
        state.config &&
        state.config.general &&
        state.config.general.settings &&
        state.config.general.settings.agent_name > 0
      ) {
        let show = true;
        if (
          state.config.general.settings.agent_name === 2 &&
          foundChat.last_message &&
          foundChat.last_message.user_id
        ) {
          ///se apenas para a primeira mensagem de cada atendente
          show = false;
          if (foundChat.last_message.user_id !== state.config.user.id) {
            show = true;
          }
        }
        if (show) {
          text_before = '*';
          if (
            state.config.general.settings.before_agent_name &&
            state.config.general.settings.before_agent_name.length
          ) {
            text_before +=
              state.config.general.settings.before_agent_name + ' ';
          }
          let parts = state.config.user.name.split(' ');
          text_before += parts[0] + (parts[1] ? ' ' + parts[1] : '');
          if (
            state.config.general.settings.after_agent_name &&
            state.config.general.settings.after_agent_name.length
          ) {
            text_before += ' ' + state.config.general.settings.after_agent_name;
          }
          text_before += '*\r\n\r\n';
          newMessage.body_preview = text_before + newMessage.body;
        }
      }

      newMessage.ack = newMessage.ack || '';
      newMessage.dir = 'o';
      if (!foundChat.messages) foundChat.messages = [];
      foundChat.messages.push(newMessage);

      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === foundChat.id) {
            return foundChat;
          }
          return chat;
        }),
      });
    }
    case actionNames.ON_USER_LOGIN: {
      let config = Object.assign({}, state.config);
      if (config && config.general && config.general.users) {
        config.general.users = config.general.users.map((user) => {
          if (user.id == action.payload.id) {
            user.status = action.payload.user.status;
          }
          return user;
        });
      }
      return {
        ...state,
        config,
      };
    }
    case actionNames.ON_USER_LOGOUT: {
      let config = Object.assign({}, state.config);
      if (config && config.general && config.general.users) {
        config.general.users = config.general.users.map((user) => {
          if (user.id == action.payload.id) {
            user.status = 0;
          }
          return user;
        });
      }
      return {
        ...state,
        config,
      };
    }
    case actionNames.SEND_MESSAGE_SUCCESS: {
      if (
        action.payload.contact_id &&
        state.chats.find((chat) => chat.id === action.payload.contact_id)
      ) {
        state = Object.assign({}, state);
        state.chats = state.chats.map((chat) => {
          if (
            chat.id === action.payload.contact_id &&
            chat.messages.find((msg) => msg.cuid === action.payload.front_cuid)
          ) {
            chat.messages = chat.messages.map((message) => {
              if (
                (!chat.last_message || chat.last_message.dtm < message.dtm) &&
                message.dir !== 'l'
              ) {
                chat.last_message = message;
              }
              if (message.cuid === action.payload.front_cuid) {
                message.id = action.payload.id;
                message.cuid = action.payload.cuid;
                message.ack = 0;
              }
              return message;
            });
          }

          return chat;
        });
      }
      return state;
    }

    case actionNames.SEND_MESSAGE_FAILURE: {
      if (
        action.payload.contact_id &&
        state.chats.find((chat) => chat.id === action.payload.contact_id)
      ) {
        state = Object.assign({}, state);
        state.chats = state.chats.map((chat) => {
          if (
            chat.id === action.payload.contact_id &&
            chat.messages.find((msg) => msg.cuid === action.payload.front_cuid)
          ) {
            chat.messages = chat.messages.map((message) => {
              if (message.cuid === action.payload.front_cuid) {
                message.ack = -1;
              }
              return message;
            });
          }
          return chat;
        });
        if (action.payload.msg) {
          let msgToShow = `${action.payload.msg} Verificar se o canal selecionado está conectado.`;
          try {
            if (action.payload.msg) {
              let msgAux = JSON.parse(action.payload.msg);
              if (
                msgAux &&
                msgAux.poli_error_code &&
                msgAux.poli_error_message
              ) {
                msgToShow = msgAux.poli_error_message;
              }
            }
          } catch (e) {}

          toast.error(msgToShow);
        }
      }
      return state;
    }

    /**
     * Reducers for user data
     *
     */
    case actionNames.STORE_USER_SETTINGS: {
      // Configurações do sentry
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: action.payload.user.id,
          email: action.payload.user.email,
          name: action.payload.user.name,
        });
      });

      let configUser = action.payload.user;
      configUser.picture = checkAvatar(configUser.name, configUser.picture);
      if (state.chats) {
        console.log('Conexão reestabelecida', action.payload);
        return {
          ...state,
          config: { ...state.config, ...action.payload },
        };
      }
      return {
        ...state,
        config: action.payload,
        filters: {
          view: {
            isMainMenuOpen: false,
            isAttendanceMenuOpen: false,
            isDeparmentsMenuOpen: false,
            isTagsMenuOpen: false,
            isDateMenuOpen: false,
            isChatMenuOpen: false,
            isChannelMenuOpen: false,
          },
          activeFilters: activeFiltersModel,
        },
        currentPage: {
          // ...activeFiltersModel,
          all: { page: 0 },
          inProgress: { page: 0 },
          unReads: { page: 0 },
          search: { page: 0 },
          customFilter: { page: 0 },
        },

        search: {
          current: '',
          history: [],
          chatsSearched: [],
        },
      };
    }

    /**
     * Responsável por armazenar a mensagem atual do textarea no estado
     */
    case actionNames.CREATE_MESSAGE_TEXTAREA: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          let currentChat = Object.assign({}, chat);

          currentChat.messageTextArea = '';

          return currentChat;
        }),
      });
    }

    case actionNames.UPDATE_MESSAGE_TEXTAREA: {
      let chats = Object.assign([], state.chats);

      let openChatIndex = chats.findIndex((chat) => chat.id === action.id);
      if (openChatIndex >= 0) {
        chats[openChatIndex].messageTextArea = action.message;

        return {
          ...state,
          chats: chats,
        };
      }

      return state;
      /*return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.id) {
            let currentChat = Object.assign({}, chat);

            currentChat.messageTextArea = action.message;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
      */
    }

    case actionNames.UPDATE_MESSAGE_URL: {
      let currentState = Object.assign({}, state);

      let currentChat = currentState.chats.find(
        (c) => c.id === action.payload.chat_id
      );
      if (!currentChat) return state;

      let currentMessage = currentChat.messages.find(
        (m) => m.cuid === action.payload.message_cuid
      );
      if (!currentMessage) return state;

      currentMessage.url = action.payload.message_url;

      return currentState;
    }

    /**
     * Responsáveis pelo Upload
     */
    case actionNames.CREATE_UPLOAD_LIST: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          let currentChat = Object.assign({}, chat);

          currentChat.uploadingFiles = [];
          currentChat.chatDropzone = false;

          return currentChat;
        }),
      });
    }

    case actionNames.ADD_UPLOAD_TO_LIST: {
      if (!action.id) {
        action.id = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.id) {
            let currentChat = Object.assign({}, chat);

            let uploadedFiles = currentChat.uploadingFiles || [];

            action.file.forEach((file) => {
              uploadedFiles.push(file);
            });

            if (action.openDropzone && currentChat.chatDropzone == false) {
              currentChat.chatDropzone = true;
            }

            currentChat.uploadingFiles = uploadedFiles;
            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.UPDATE_STATE_UPLOAD_FILES_MESSAGE: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            let currentChat = Object.assign({}, chat);

            let uploadedFiles = currentChat.uploadingFiles;

            action.payload.files.forEach((file) => {
              uploadedFiles.forEach((uploadedFile) => {
                if (uploadedFile.id === file.id) {
                  if (action.payload.error !== undefined)
                    uploadedFile.error = action.payload.error;
                  if (action.payload.url !== undefined)
                    uploadedFile.url = action.payload.url;
                  if (action.payload.uploaded !== undefined)
                    uploadedFile.uploaded = action.payload.uploaded;
                  if (action.payload.progress !== undefined)
                    uploadedFile.progress = action.payload.progress;
                  return uploadedFile;
                }
              });
            });

            currentChat.uploadingFiles = uploadedFiles;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.REMOVE_ITEM_UPLOAD_LIST: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.idChat) {
            let currentChat = Object.assign({}, chat);

            let uploadedFiles = currentChat.uploadingFiles;

            uploadedFiles.splice(action.idItem, 1);

            currentChat.uploadingFiles = uploadedFiles;
            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLEAR_LIST_UPLOAD: {
      let uploadingFiles = [];
      return {
        uploadingFiles,
      };
    }

    /**
     * Responsável por abrir e fechar o dropzone
     */

    case actionNames.UPDATE_CHAT_DROPZONE: {
      if (!action.id) {
        action.id = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.id) {
            let currentChat = Object.assign({}, chat);

            currentChat.chatDropzone = action.value;

            // Remove todos os dados dentro do array de upload se for a ação de Fechar
            // Usados na hora de fechar ou enviar arquivos
            if (action.clear) {
              currentChat.uploadingFiles = [];
            }

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLEAR_UPLOAD_AFTER_SEND: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);

            currentChat.uploadingFiles = [];

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }
    case actionNames.FETCH_CHAT_CONFIG_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        loading: true,
      };

    case actionNames.FETCH_CHAT_CONFIG_SUCCESS:
      general = action.payload;
      activeTabNavigation = 'inprogress';
      noAttendantTabActive = false;
      let config = {
        ...state.config,
        general,
        activeTabNavigation,
        noAttendantTabActive,
      };
      if (config.general.channels)
        config.general.channels.map((channel) => {
          channel.type = getTypeChannel(channel);
        });
      if (config.user && config.user.channels) {
        config.user.channels.map((channel) => {
          channel.type = getTypeChannel(channel);
        });
      }
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        config: config,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_CHAT_CONFIG_FAILURE:
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        chats: [],
        error: action.error?.msg || 'Erro ao carregar as configurações do chat',
        errorCode: 1002,
        errorAction: 'FETCH_CHAT_CONFIG_FAILURE',
        fetch_chat_config_fail: true,
      };

    case actionNames.DELETE_CONTACT_EXTERNAL_SUCCESS:
      let newArrayExternals = action.data.externals;
      let chatSelectedIdNew = action.data.chatSelectedId;

      // Transformando o array recebido em um array com mais informações
      newArrayExternals.map((external) => {
        getTypeChannel(external);
      });

      let newState = Object.assign({}, state);
      let selectedChat = newState.chats.find(
        (chat) => chat.id === chatSelectedIdNew
      );

      if (selectedChat && selectedChat !== -1) {
        selectedChat.contact.externals = newArrayExternals;
      }

      let selectedContact = newState.contacts.find(
        (contact) => contact.id === chatSelectedIdNew
      );

      if (selectedContact && selectedContact !== -1) {
        selectedContact.externals = newArrayExternals;
      }

      // Direcionando o contato a tela de chat
      newState.config.activeTabNavigation = 'chat';
      newState.config.noAttendantTabActive = false;

      // Mensagem de sucesso ao excluir
      toast.success(action.data.msg);

      return {
        ...state,
        newState,
      };

    case actionNames.DELETE_CONTACT_EXTERNAL_FAILURE:
      toast.error(action.data.msg);

      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
      };

    case actionNames.FETCH_OPERATORS_LIST_SUCCESS:
      let operators = action.payload;
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        operators: operators,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_OPERATORS_LIST_FAILURE:
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        operators: [],
        error: action.error || 'FETCH_OPERATORS_LIST_FAILURE',
        errorCode: 1003,
        errorAction: 'FETCH_OPERATORS_LIST_FAILURE',
      };

    /**
     * Reducer for fetching count no reads
     */
    case actionNames.FETCH_COUNT_NO_READS:
      return {
        ...state,
        hasAsyncRequest: true,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_COUNT_NO_READS_SUCCESS:
      const localStorageEnabledSound =
        localStorage.getItem('@poli/notificationsEnabled') === 'true';
      notification.updateTitle(action.quantity);
      // Configuração do laravel (adm/gestor) para notificar APENAS mensagens para eles
      const shouldPlayOnlyMySound = Boolean(
        state?.config?.user?.settings?.notifications_per_user
      );

      // Verifica se o usuário habilitou para tocar áudio
      const shouldPlaySound =
        Boolean(state?.config?.general?.settings?.can_send_notifications) &&
        localStorageEnabledSound &&
        shouldPlayOnlyMySound;

      return {
        ...state,
        count_no_read: action.quantity,
        hasAsyncRequest: false,
        loadingMessagesOneChat: false,
        error: '',
        errorCode: null,
        errorAction: null,
        shouldPlaySound,
      };

    case actionNames.FETCH_COUNT_NO_READS_FAILURE:
      toast.error("Não foi possível carregar o contador de 'Não lidos'");
      return {
        ...state,
        count_no_read: 0,
        hasAsyncRequest: false,
        loadingMessagesOneChat: false,
        /*error: "Não foi possível carregar o contador de 'Não lidos'",
        errorCode: 1004,
        errorAction: 'FETCH_COUNT_NO_READS_FAILURE',*/
      };

    /**
     * Reducer for showing details
     */
    case actionNames.SHOW_DETAILS_COMPONENT:
      /**
       * Caso mobile, adiciona um '&contactDetails' ao hash
       * da URL, para indicar que a aba de detalhes
       * do contato está aberta.
       */
      // if (
      //   isMobile &&
      //   !window.history.state?.isContactDetailsOpen &&
      //   action.showDetails
      // ) {
      //   window.history.pushState(
      //     { isContactDetailsOpen: true },
      //     '',
      //     window.location.href + '&contactDetails'
      //   );
      // }

      return {
        ...state,
        showDetails: action.showDetails,
      };

    case actionNames.OPEN_TAG_IN_DETAILS:
      return {
        ...state,
        openTagList: action.openTagList,
      };

    /**
     * Reducer for loading chats
     */
    case actionNames.FETCH_CHAT_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_CHAT_SUCCESS: {
      chats = action.chats;
      let contacts = state.contacts || [];

      _settings = state?.config?.general?.settings;
      activeWallet = _settings?.customer_portfolio == 1;
      chats.forEach((chat) => {
        // Criando o contador de novas mensagens (usado na bolinha para voltar para baixo)
        if (!chat.newMsgCounter) {
          chat.newMsgCounter = 0;
        }
        // Se nao existe o array de upload entao cria
        if (!chat.uploadingFiles) {
          chat.uploadingFiles = [];
        }
        chat.view = {
          menus: menus,
        };
        if (!contacts.find((contact) => contact.id === chat.contact.id)) {
          if (
            (chat.contact.name === null || chat.contact.name == '') &&
            chat.contact.phone
          ) {
            chat.contact.name = formatPhone(chat.contact.phone);
          }
          chat.contact.picture = checkAvatar(
            chat.contact.name,
            chat.contact.picture
          );

          if (chat.contact.externals) {
            chat.contact.externals.map((external) => {
              external.type = getTypeChannel(external);
            });
          }
          contacts.push(chat.contact); //garante que sempre terá o contato na lista de contatos
        }
      });

      const nextPage = action.nextPage;
      let currentState = Object.assign({}, state);

      const currentActiveNavigation = currentState.config.activeTabNavigation;
      const stateCurrentPage = currentState.currentPage;

      // Grava no estado de páginas qual página deve buscar
      if (currentActiveNavigation === 'chat' && nextPage) {
        if (stateCurrentPage.all.page === 1) {
          // Pode dar ruim se essa ação inicial for chamada mais de uma vez
          if (currentState.chats.length > 20) {
            stateCurrentPage.all.page = stateCurrentPage.all.page + 1;
          }
        } else {
          stateCurrentPage.all.page = stateCurrentPage.all.page + 1;
        }
      } else if (currentActiveNavigation === 'inprogress' && nextPage) {
        // 🩹 ->  a action 'FETCH_CHAT_SUCCESS' está sendo chamada mais de uma vez, e com essa verificação
        // não pulará a paginação quando o estado for usado pela 'FETCH_MOR_CHAT_SUCCESS' para buscar novos chats
        if (stateCurrentPage.inProgress.page == 0) {
          stateCurrentPage.inProgress.page =
            stateCurrentPage.inProgress.page + 1;
        }
      } else if (currentActiveNavigation === 'unreads' && nextPage) {
        stateCurrentPage.unReads.page = stateCurrentPage.unReads.page + 1;
      }

      /**
       * Se tiver algum chat aberto, manter aberto
       * - Correcao do bug quando abre chat pelo link é fechado pelo APP sozinho
       * #PS-1607
       */
      const foundOpened = currentState?.chats?.find((e) => e.open === true);
      if (foundOpened?.id) {
        const i = action.chats?.findIndex((e) => e.id === foundOpened.id);
        if (i >= 0) {
          action.chats[i] = { ...action.chats[i], ...foundOpened };
          action.chats[i].open = true;
        } else {
          action.chats.push(foundOpened);
        }
      }
      /* end */

      return {
        ...state,
        chats: action.chats,
        loading: false,
        initiatedApp: true,
        hasAsyncRequest: false,
        loadingMessagesOneChat: false,
        error: '',
        errorCode: null,
        errorAction: null,
      };
    }
    case actionNames.FETCH_CHAT_FAILURE: {
      let currentState = Object.assign({}, state);
      if (!currentState.fetch_chat_failure_count) {
        currentState.fetch_chat_failure_count = 0;
      }

      if (currentState.fetch_chat_failure_count > 5) {
        return {
          ...state,
          chats: [],
          hasAsyncRequest: false,
          loading: false,
          loadingMessagesOneChat: false,
          error: action.error,
          errorCode: 1005,
          errorAction: 'FETCH_CHAT_FAILURE',
          fetch_chat_failure_count: ++currentState.fetch_chat_failure_count,
        };
      } else {
        return {
          ...state,
          chats: null,
          hasAsyncRequest: false,
          loading: false,
          loadingMessagesOneChat: false,
          fetch_chat_failure_count: ++currentState.fetch_chat_failure_count,
        };
      }
    }
    case actionNames.FETCH_PENDENCES_REQUEST: {
      if (state.pendences) {
        return { ...state, pendencesLoading: true };
      }
      return state;
    }

    case actionNames.FETCH_PENDENCES_CHATS_SUCCESS: {
      const isPendences = true;
      let pendences = state.pendences;
      let existsPendences = state.pendences && state.pendences.length;
      let new_chats = action.payload;
      let existing_chats = Object.assign([], state.chats);

      let contacts = state.contacts || [];
      let channels = [];
      let new_chats_to_add = [];

      if (state.config && state.config.user && state.config.user.channels) {
        channels = state.config.user.channels;
      }

      if (existsPendences && Array.isArray(new_chats)) {
        let updatedPendences = [...state.pendences]; // Cria uma cópiada     lista   de pendências original

        state.pendences.forEach((chat_id) => {
          let index = new_chats.findIndex((chat) => chat.id === chat_id);

          if (index > -1) {
            new_chats_to_add.push(new_chats[index]);
            updatedPendences = updatedPendences.filter((id) => id !== chat_id); // Remove o chat encontrado da lista de pendências
          }
        });

        pendences = updatedPendences; // Atualiza a lista de pendênciascom     os chats restantes
      }

      _settings = state?.config?.general?.settings;
      activeWallet = _settings?.customer_portfolio == 1;

      existing_chats = addChatToChatList(
        activeWallet,
        new_chats_to_add,
        existing_chats,
        channels,
        contacts,
        menus,
        isPendences
      );

      return {
        ...state,
        pendences,
        pendencesError: false,
        pendencesLoading: false,
        hasAsyncRequest: false,
        chats: existing_chats,
      };
    }

    case actionNames.FETCH_PENDENCES_CHATS_FAILURE: {
      return {
        ...state,
        pendencesError: true,
        pendencesLoading: false,
      };
    }

    case actionNames.FETCH_MORE_CHATS_REQUEST: {
      return {
        ...state,
        isLoadingMoreChats: true,
        hasAsyncRequest: true,
      };
    }

    case actionNames.FETCH_MORE_CHATS_SUCCESS: {
      let new_chats = action.payload.data;
      const pageSended = action.payload.pageSended;
      const nextPage = action.nextPage;
      let currentState = Object.assign({}, state);

      const currentSearchState = currentState.search;
      const currentActiveNavigation = currentState.config.activeTabNavigation;

      let activeFiltersIsFill = false;

      if (
        state.filters.activeFilters.attendant.length > 0 ||
        state.filters.activeFilters.date.length > 0 ||
        state.filters.activeFilters.department.length > 0 ||
        state.filters.activeFilters.tag.length > 0 ||
        state.filters.activeFilters.chat.length > 0 ||
        state.filters.activeFilters.channel.length > 0
      ) {
        activeFiltersIsFill = true;
      }

      const stateCurrentPage = currentState.currentPage;

      let existing_chats = Object.assign([], state.chats);
      let contacts = currentState.contacts || [];
      let channels = [];

      // Grava no estado de páginas qual página deve buscar
      if (
        currentActiveNavigation === 'chat' &&
        nextPage &&
        !activeFiltersIsFill
      ) {
        stateCurrentPage.all.page = pageSended + 1;
      } else if (
        currentActiveNavigation === 'inprogress' &&
        nextPage &&
        !activeFiltersIsFill
      ) {
        stateCurrentPage.inProgress.page = pageSended + 1;
      } else if (
        currentActiveNavigation === 'unreads' &&
        nextPage &&
        !activeFiltersIsFill
      ) {
        stateCurrentPage.unReads.page = pageSended + 1;
      }

      // Incremento do Custom Filter
      else if (activeFiltersIsFill && new_chats && new_chats.length > 0) {
        stateCurrentPage.customFilter.page = pageSended + 1;
      }

      // Incrementa a pesquisa
      if (
        currentSearchState.current &&
        !activeFiltersIsFill &&
        currentSearchState.current.length > 0 &&
        new_chats &&
        new_chats.length > 0
      ) {
        stateCurrentPage.search.page = pageSended + 1;
      }

      if (
        currentState.config &&
        currentState.config.user &&
        currentState.config.user.channels
      ) {
        channels = currentState.config.user.channels;
      }

      _settings = state?.config?.general?.settings;
      activeWallet = _settings?.customer_portfolio == 1;

      existing_chats = addChatToChatList(
        activeWallet,
        new_chats,
        existing_chats,
        channels,
        contacts,
        menus
      );

      currentState.chats = existing_chats;

      // Preenchendo Lista de Chats Pesquisados com o resultado da busca
      if (
        currentSearchState.current &&
        currentSearchState.current.length > 0 &&
        currentSearchState.chatsSearched
      ) {
        new_chats.forEach((newChat) => {
          currentSearchState.chatsSearched.push(newChat);
        });
      }

      return {
        ...currentState,
        hasAsyncRequest: false,
        isLoadingMoreChats: false,
        error: '',
        errorCode: null,
        errorAction: null,
      };
    }

    case actionNames.FETCH_MORE_CHATS_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
        isLoadingMoreChats: false,
      };
    }

    case actionNames.START_FETCHING_CHATS_SEARCH_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          history: [...state.search.history, action.payload],
        },
        hasAsyncRequest: true,
        isLoadingMoreChats: true,
      };

    case actionNames.SUBMIT_FILTER: {
      return {
        ...state,
        hasAsyncRequest: true,
        isLoadingMoreChats: true,
      };
    }
    /**
     * Reducers for requesting messages
     */
    case actionNames.FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        loading: true,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_MESSAGES_REQUEST_ONE_CHAT:
      return {
        ...state,
        hasAsyncRequest: true,
        loading: false,
        loadingMessagesOneChat: true,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        chats: state.chats.map((chat, i) => {
          if (chat.id === parseInt(action.payload.contact_id)) {
            return {
              ...chat,
              messages: action.payload.messages,
              loadMessages: true,
            };
          } else {
            return { ...chat };
          }
        }),
        error: '',
        errorCode: null,
        errorAction: null,
        messagesLoaded: true,
      };

    case actionNames.ONE_CHAT_LOADED:
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        messagesLoaded: true,
      };

    case actionNames.FETCH_MESSAGES_FAILURE:
      toast.error(
        'Não foi possível buscar mensagens! Verique se esta conectado a internet'
      );
      return {
        ...state,
        hasAsyncRequest: false,
        loading: false,
      };

    /**
     * Reducers for requesting users from customer
     */
    case actionNames.FETCH_USERS_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        loading: true,
        loadingUsers: true,
      };

    case actionNames.FETCH_USERS_SUCCESS:
      chat = Object.assign({}, state);
      if (chat.loadingUsers) delete chat.loadingUsers;

      chat.loading = false;
      chat.hasAsyncRequest = false;

      chat.config = {
        ...chat.config,
        general: { ...state.config.general, users: action.payload },
      };

      return {
        ...chat,
      };

    case actionNames.FETCH_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        errorCode: 1006,
        errorAction: 'FETCH_USERS_FAILURE',
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        loadingUsers: false,
        config: { general: { ...state.config.general, users: [] } },
      };

    /**
     * Reducers for requesting more messages
     */
    case actionNames.FETCH_MORE_MESSAGES_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        chats: state.chats.map((chat, i) => {
          return chat.id === parseInt(action.chat)
            ? {
                ...chat,
                isLoadingNewMessages: true,
                failure: false,
              }
            : { ...chat };
        }),
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_MORE_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          if (chat.id === parseInt(action.payload.contact_id)) {
            let currentChat = Object.assign({}, chat);

            let existingMessages = currentChat.messages;
            let newMessages = action.payload.messages;

            if (newMessages.length) {
              newMessages.forEach((newMessage) => {
                if (
                  existingMessages.find(
                    (existingMessage) => existingMessage.id === newMessage.id
                  ) === undefined
                ) {
                  existingMessages.push(newMessage);
                }
              });
            } else {
              currentChat.finishingNewMessages = true;
            }

            currentChat.messages = existingMessages;
            currentChat.failure = false;

            if (currentChat.isLoadingNewMessages) {
              currentChat.isLoadingNewMessages = false;
            }

            return currentChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.FETCH_MORE_MESSAGES_FAILURE:
      return {
        ...state,
        hasAsyncRequest: false,
        chats: state.chats.map((chat, i) => {
          if (chat.id === parseInt(action.payload.contact_id)) {
            if (!chat.failure) {
              toast.error('Erro ao buscar mensagens antigas');
            }
            return {
              ...chat,
              isLoadingNewMessages: false,
              failure: true,
            };
          } else {
            return { ...chat };
          }
        }),
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.STORE_LAST_SCROLL_HEIGHT:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            let openChat = Object.assign({}, chat);
            openChat.last_scroll_height = action.payload.last_scroll_height;
            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * CLOSE CHAT
     */

    case actionNames.CLOSE_CHAT:
      _settings = state?.config?.general?.settings;
      activeWallet = _settings?.customer_portfolio == 1;

      return {
        ...state,
        chats: state.chats.map((chat, i) => {
          chat = Object.assign({}, chat);
          if (chat.id === action.id) {
            ////Verificando se existe na lista de contacts
            if (
              !state.contacts ||
              !state.contacts.length ||
              !state.contacts.find((contact) => chat.contact.id === contact.id)
            ) {
              if (!state.contacts) {
                state.contacts = [];
              }

              if (!activeWallet) {
                state.contacts.push(chat.contact);
              }
            }
            //////
            let config = Object.assign({}, state.config);
            let channels = [];
            if (config && config.user && config.user.channels) {
              channels = config.user.channels;
            }
            chat = matchChannelToExternal(chat, channels);
            chat.openTextBox = false;
            chat.open = false;
            return chat;
          } else {
            chat.openTextBox = false;
            chat.open = false;
            return chat;
          }
        }),
      };

    /**
     * Open chat
     */
    case actionNames.OPEN_CHAT:
      localStorage.removeItem('chat_id');
      let listeners = Object.assign({}, state.listeners || {});
      if (!listeners.paste) {
        listeners.paste = createListenerPaste();
      }
      if (!listeners.drag) {
        listeners.drag = createListenerDrag();
      }

      _settings = state?.config?.general?.settings;
      activeWallet = _settings?.customer_portfolio == 1;

      return {
        ...state,
        //loadingMessagesOneChat: true,
        chats: state.chats.map((chat, i) => {
          chat = Object.assign({}, chat);
          if (chat.id === action.id) {
            ////Verificando se existe na lista de contacts
            if (
              !state.contacts ||
              !state.contacts.length ||
              !state.contacts.find((contact) => chat.contact.id === contact.id)
            ) {
              if (!state.contacts) {
                state.contacts = [];
              }

              if (!activeWallet) {
                state.contacts.push(chat.contact);
              }
            }
            //////
            let config = Object.assign({}, state.config);
            let channels = [];
            if (config && config.user && config.user.channels) {
              channels = config.user.channels;
            }
            // Serve para criar os externals dentro do estado contatos
            chat = matchChannelToExternal(chat, channels);
            // Setando o canal como o mesmo da ultima mensagem enviada
            chat = forceLastMessageChannel(chat, channels);

            chat.openTextBox = true;
            chat.open = true;

            // Se nao existe o array de upload entao cria
            if (!chat.uploadingFiles) {
              chat.uploadingFiles = [];
            }

            // O chat só é marcado como lido se for acessado pelo seu dono
            // Ou se a ultima mensagem for do tipo 'o' -> output && não for do tipo bot
            if (
              chat.chat.origin_id === state.config.user.id ||
              (chat.last_message.dir === 'o' &&
                !chat.last_message.cuid.includes('bot'))
            ) {
              if (chat.last_message.user_id === chat.chat.origin_id) {
                chat.newMsgCounter = 0;
              }
            }

            return chat;
          }
          // Se o chat selecionado não for igual ao origin_id
          else {
            chat.openTextBox = false;
            chat.open = false;
            return chat;
          }
        }),
        listeners,
      };

    case actionNames.SELECTED_CHAT_ID:
      hashHistoryMobile(action.id);
      return {
        ...state,
        //loadingMessagesOneChat: true,
        chatSelectedId: action.id,
      };

    /**
     * PUT MESSAGE IN STORE
     */
    case actionNames.PUT_MESSAGE_IN_STORE:
      return {
        ...state,
        chats: state.chats.map((chat, i) => {
          return chat.id === action.id
            ? { ...chat, message: action.message, openTextBox: true }
            : { ...chat, message: action.message, openTextBox: false };
        }),
      };

    /**
     * Reducer for marking chat as read
     */
    case actionNames.MARK_READ: {
      let newState = Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chat_id) {
            if (
              chat.chat.origin_id === state.config.user.id ||
              !action.sendBack
            ) {
              let _chat = Object.assign({}, chat);
              _chat.chat.chat_read = 1;
              //Volta o valor de count_messages_no_read para zero quando
              //operador abre o chat ou gestor abre chat sem atendente
              _chat.chat.count_messages_no_read = 0;
              _chat.newMsgCounter = 0;
              return _chat;
            } else if (
              chat.chat.origin_id === null &&
              chat.last_message &&
              chat.last_message.cuid &&
              chat.last_message.cuid.includes('bot')
            ) {
              let _chat = Object.assign({}, chat);
              _chat.chat.chat_read = 1;
              _chat.chat.count_messages_no_read = 0;
              _chat.newMsgCounter = 0;
              return _chat;
            } else {
              let _chat = Object.assign({}, chat);
              _chat.chat.chat_read = 0;
              return _chat;
            }
          } else {
            return chat;
          }
        }),
        count_no_read: state.count_no_read > 1 ? state.count_no_read - 1 : 0,
      });
      notification.updateTitle(newState.count_no_read);
      return newState;
    }
    /**
     * Reducer for opening finish chat modal
     */
    case actionNames.OPEN_FINISH_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            return { ...chat, finish_chat_modal_open: true };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.CLOSE_FINISH_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let _chat = Object.assign({}, chat);
            if (_chat.finish_chat_modal_open)
              delete _chat.finish_chat_modal_open;
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Reducer for opening transfer chat modal
     */
    case actionNames.OPEN_TRANSFER_CHAT_MODAL:
      return Object.assign({}, state, {
        // hasAsyncRequest: true, /* estava travando outras requisicoes */
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            openChat.transfer_chat_modal_loading = true;
            openChat.transfer_chat_modal_open = true;
            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.OPEN_TRANSFER_CHAT_MODAL_SUCCESS:
      return {
        ...state,
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            if (openChat.transfer_chat_modal_loading) {
              delete openChat.transfer_chat_modal_loading;
            }

            return openChat;
          } else {
            return chat;
          }
        }),
        config: {
          ...state.config,
          general: {
            ...state.config.general,
            has_wallet: action.payload.has_wallet,
          },
        },
      };

    case actionNames.CLOSE_TRANSFER_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            if (openChat.transfer_chat_modal_loading)
              delete openChat.transfer_chat_modal_loading;

            if (openChat.transfer_chat_modal_open)
              delete openChat.transfer_chat_modal_open;

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Reducer for transfer chat
     */
    case actionNames.TRANSFER_CHAT_REQUEST:
      return Object.assign({}, state, {
        hasAsyncRequest: true,
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            openChat.transfering = true;
            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.TRANSFER_CHAT_SUCCESS: {
      let departments = [],
        count_no_read = 0,
        department_id = null;
      let chat_id, origin_id, superRole, selectedContact;
      let shouldPlaySound = false;
      const localStorageEnabledSound =
        localStorage.getItem('@poli/notificationsEnabled') === 'true';
      try {
        if (action?.payload) {
          const payload = action.payload;
          if (payload.contact_id && !isNaN(parseInt(payload.contact_id)))
            chat_id = parseInt(payload.contact_id);
          if (payload.origin_id && !isNaN(parseInt(payload.origin_id)))
            origin_id = parseInt(payload.origin_id);
          if (payload.department_id && !isNaN(parseInt(payload.department_id)))
            department_id = Number(payload.department_id);
        }
        if (state) {
          let chats = [...state.chats];
          if (
            state.count_no_read &&
            !isNaN(parseInt(state.count_no_read)) &&
            parseInt(state.count_no_read) > 0
          )
            count_no_read = parseInt(state.count_no_read);
          if (state.config?.user) {
            if (
              Array.isArray(state.config.user.departments) &&
              state.config.user.departments.length > 0
            )
              departments = state.config.user.departments;
            if (state.config.user.superRole) {
              const superRole = state.config.user.superRole.toString();
              if (
                superRole === 'agent' ||
                (superRole === 'supervisor' &&
                  !departments.some(
                    (r) => Number(r.id) === Number(department_id)
                  ))
              ) {
                const index = chats.findIndex((chat) => chat.id === chat_id);
                if (index > -1) chats.splice(index, 1);
              } //else {
              if (chats.length > 0) {
                chats = chats.map((chat) => {
                  if (
                    typeof chat === 'object' &&
                    chat_id &&
                    chat.id === chat_id
                  ) {
                    chat['transfering'] = false;
                    chat['open'] = false;
                    if (chat.chat) {
                      //se o chat que eu estou transferindo for um chat já lido e eu não sou operador,
                      //preciso incrementar 1 no não lidos
                      if (chat.chat['chat_read'] && chat.chat.chat_read === 1) {
                        if (
                          state.config.general?.settings
                            ?.can_send_notifications &&
                          Array.isArray(state.config.user['roles'])
                        ) {
                          //
                          if (
                            !state.config.user['roles'].some(
                              (role) => role.name.toString() === 'agent'
                            )
                          ) {
                            notification.updateTitle(count_no_read++);
                            shouldPlaySound =
                              Boolean(
                                state?.config?.general?.settings
                                  ?.can_send_notifications
                              ) && localStorageEnabledSound;
                          }
                          //
                        }
                      }
                      chat.chat['chat_read'] = 0;
                      chat.chat['count_messages_no_read'] = 0;
                      chat['newMsgCounter'] = 1;
                      chat.chat['department_id'] = department_id;
                      if (typeof chat.contact === 'object') {
                        chat.contact['department_id'] = department_id;
                        if (origin_id) chat.contact['operator'] = origin_id;
                      }
                      if (origin_id) {
                        chat.chat['origin_id'] = origin_id;
                        if (typeof chat.last_message === 'object')
                          chat.last_message['user_id'] = origin_id;
                      }
                      //
                    }
                    if (chat.transfer_chat_modal_open)
                      delete chat.transfer_chat_modal_open;
                    return chat;
                  } else return chat;
                });
              }
              //}
              //
              if (Array.isArray(state.contacts)) {
                for (let i = state.contacts - 1; i >= 0; i--) {
                  const contact = state.contacts[i];
                  if (
                    typeof contact === 'object' &&
                    contact.id &&
                    Number(contact.id) === Number(chat_id)
                  ) {
                    // Se o contato existir dentro do estado de contatos
                    // Entao o operador e user_id serão atualizados
                    state.contacts[i]['operador'] = origin_id; // Trocar o operador dentro do state > Contacts
                    state.contacts[i]['user_id'] = origin_id; // Trocar o user_id dentro do state > Contacts
                  }
                }
              }
              removeHashChat();
              toast.success('Contato transferido com sucesso.');
            }
          }
          return {
            ...state,
            hasAsyncRequest: false,
            chats: chats,
            count_no_read: count_no_read,
            shouldPlaySound,
          };
        }
      } catch (err) {
        console.log(err);
      }
      return {
        ...state,
        hasAsyncRequest: false,
      };
    }

    case actionNames.TRANSFER_CHAT_FAILURE:
      toast.error('Não foi possível transferir o contato, tente novamente.');
      return Object.assign({}, state, {
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            openChat.transfering = false;
            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Reducer for finishing chat
     */
    case actionNames.FINISH_CHAT_REQUEST:
      return Object.assign({}, state, {
        hasAsyncRequest: true,
        chats: state.chats.map((chat) => {
          if (chat.id === action.chat_id) {
            return { ...chat, finishing: true };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.FINISH_CHAT_SUCCESS:
      return Object.assign({}, state, {
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.id) {
            let _chat = Object.assign({}, chat);

            _chat.chat.finished_at = new Date()
              .toISOString()
              .slice(0, 19)
              .replace('T', ' ');

            if (_chat.finishing) delete _chat.finishing;
            if (_chat.finish_chat_modal_open)
              delete _chat.finish_chat_modal_open;

            _chat.chat.status = 0;

            return _chat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.FINISH_CHAT_FAILURE:
      action.error?.message && toast.error(action.error?.message);

      return Object.assign({}, state, {
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let _chat = Object.assign({}, chat);

            if (_chat) {
              _chat.chat.finished_at = null;
              if (_chat.finishing) delete _chat.finishing;
              if (_chat.finish_chat_modal_open)
                delete _chat.finish_chat_modal_open;

              toast.warning(action.error);
            }
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Reducer para receber ACK
     */
    case actionNames.RECEIVE_ACK:
      if (action.payload) {
        if (state.chats.find((chat) => chat.id === action.payload.contact_id)) {
          //verifica se já existe este chat ou não
          return Object.assign({}, state, {
            chats: state.chats.map((chat) => {
              if (chat.id === action.payload.contact_id) {
                chat.chat.status = 1;
                if (chat.messages) {
                  chat.messages = chat.messages.map((message) => {
                    const cuid_by_front = message.cuid.replace(
                      '-' + message.id,
                      ''
                    );

                    if (
                      message.id === action.payload.id ||
                      message.cuid === action.payload.cuid ||
                      message.cuid === cuid_by_front
                    ) {
                      message.ack = action.payload.ack;
                    }
                    return message;
                  });
                }
              }
              return chat;
            }),
          });
        }
      }
      return state;

    /**
     * Armazena a mensagem que chegou e possui chat
     */
    case actionNames.STORE_RECEIVED_MESSAGE:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            let currentChat = Object.assign({}, chat);

            currentChat.messages.push(action.payload.message);

            // Mensagens de Log não são computadas como última mensagem
            if (action.payload.message.dir !== 'l') {
              currentChat.last_message = action.payload.message;
            }

            return currentChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.RESET_COUNTER_NEW_MESSAGE: {
      if (action.chatId) {
        let currentState = Object.assign({}, state);

        let currentChat = currentState.chats.find(
          (c) => c.id === action.chatId
        );
        // Apenas muda o chat_read e zera o contador se o usuário for o dono do chat
        if (
          currentChat.chat.origin_id === currentState.config.user.id ||
          currentChat.chat.origin_id === null
        ) {
          currentChat.newMsgCounter = 0;
          currentChat.chat.chat_read = 1;
        }
        return currentState;
      }
    }

    case actionNames.RECEIVE_MESSAGE:
      let messageUpdated = false;

      if (action.payload) {
        // ID do operador
        const chatOperatorId = action.payload.user_id;

        // ID do usuário logado
        const userId = state?.config?.user?.id;
        // Role do usuário logado
        const isManager = state?.config?.user?.superRole !== 'agent';

        // Configuração do laravel (adm/gestor) para notificar APENAS mensagens para eles
        const shouldPlayOnlyMySound = Boolean(
          state?.config?.user?.settings?.notifications_per_user
        );
        // Configuração do laravel se pode receber notificação
        const canSendNotifications = Boolean(
          state?.config?.general?.settings?.can_send_notifications
        );
        // Se foi atribuido a um atendente
        const hasOperator =
          chatOperatorId !== null && chatOperatorId !== undefined;

        // Configuração geral se deveria tocar som
        let shouldPlaySound = false;
        const localStorageEnabledSound =
          localStorage.getItem('@poli/notificationsEnabled') === 'true';

        // nos casos de quando a mensagem é do sistema - tipo encaminhamento
        if (action.payload.type === 'sys_cfw') {
          // caso seja operador e chat não pertence ao operador
          // caso gestor escolha ouvir apenas os proprios chats e chat não pertence a ele
          // a notificação NÃO é tocada
          if (
            (!isManager || shouldPlayOnlyMySound) &&
            chatOperatorId !== userId
          ) {
            shouldPlaySound = false;
          } else {
            // se a configuração de tocar apenas minhas conversar estiver desligada
            // verifica apenas a configuração geral da empresa e o local storage do usuário
            shouldPlaySound = canSendNotifications && localStorageEnabledSound;
          }
        }

        // Só validar para mensagens recebidas, nao enviadas
        if (action.payload.dir === 'i') {

          // Se for uma conversa sem atendente
          if (!hasOperator) {
            // Usuário logado é ADM/Gestor/Supervisor
            if (isManager) {
              // Tocar o som se não estiver habilitado a opção de tocar apenas chats atribuídos a min
              shouldPlaySound =
                canSendNotifications &&
                localStorageEnabledSound &&
                !shouldPlayOnlyMySound;
            }
          }
          // Se for uma conversa COM atendente
          else {
            // Usuário Logado é ADM/Gestor/Supervisor
            if (isManager) {
              // Checamos se marcou a opção de só tocar som para mensagens dele
              if (shouldPlayOnlyMySound) {
                // Só deve tocar o som se o ID do operador responsável pelo chat é igual ao ID do usuário logado
                shouldPlaySound =
                  canSendNotifications &&
                  chatOperatorId === userId &&
                  localStorageEnabledSound;
              } else {
                shouldPlaySound =
                  canSendNotifications && localStorageEnabledSound;
              }
            }
            // Sou operador
            else {
              shouldPlaySound =
                canSendNotifications &&
                chatOperatorId === userId &&
                localStorageEnabledSound;
            }
          }
        }

        //verifica se já existe este chat ou não
        // Neste caso, existe chat
        if (state.chats.find((chat) => chat.id === action.payload.contact_id)) {
          return Object.assign({}, state, {
            shouldPlaySound,
            chats: state.chats.map((chat) => {
              if (chat.id === action.payload.contact_id) {
                // Resolvendo problema quando mais de uma action é disparada para mesma mensagem (Contador ficava duplicado)
                let sumUnreadCounter = true;
                if (chat?.last_message?.id === action?.payload?.id) {
                  sumUnreadCounter = false;
                }

                let oldLastMessage = chat.last_message;
                // Atribuindo o last_message
                chat.last_message = action.payload;
                //Troca o Canal de Envio para o que o canal do contato que enviou
                let channels = [];
                if (
                  state.config &&
                  state.config.user &&
                  state.config.user.channels
                ) {
                  channels = state.config.user.channels;
                }
                chat.view.selectedChannel = getInfosChannel(
                  channels,
                  action.payload.channel_id
                );

                // Valida se a ultima mensagem foi respondida - usado para ocultar a cartinha de não lido
                // Se foi respondida entao o atendente leu.
                if (
                  chat.last_message.dir === 'o' &&
                  !action.payload.cuid?.includes('bot')
                ) {
                  if (chat.last_message.user_id === chat.chat.origin_id) {
                    chat.newMsgCounter = 0;
                    chat.chat.chat_read = 1;
                  }

                  // Verifica se o chat está encerrado. Se estiver ele abre novamente
                  // console.log("RECEIVE_MESSAGE -> if(dir=='o' && bot)");
                  chat.chat.finished_at = null;
                }

                if (
                  action.payload.dir === 'i' ||
                  action.payload.type === 'sys_cfw'
                ) {
                  // Mensagens Recebidas
                  // Incrementando o Contador de Novas Mensagens
                  // Atribuindo Chat_Read = 0
                  if (sumUnreadCounter) {
                    chat.newMsgCounter++;
                  }

                  //Quando recebe mensagem em um chat finalizado que foi transferido, reabre o mesmo
                  if (
                    oldLastMessage.type === 'sys_cfw' &&
                    chat.chat.finished_at
                  ) {
                    chat.chat.finished_at = null;
                  }

                  chat.chat.chat_read = 0;
                } else {
                  // Mensagens Enviadas
                  if (chat.last_message.user_id === chat.chat.origin_id) {
                    chat.chat.chat_read = 1;
                  }
                }

                const isOutgoingMessage = action.payload.dir === 'o';
                const isSystemMessage = action.payload.type === 'sys_cfw';
                const isAssigned = chat.chat.origin_id !== null;

                // Quando o contato é transferido é exibido uma mensagem,
                // a partir desta mensagem, atualizamos para todos os usuários
                // O novo dono do chat
                if (
                  action.payload.user_id
                  && (isSystemMessage || (isOutgoingMessage && !isAssigned))
                ) {
                  // Marca como não lido mesmo que a ultima mensagem tenha sido de encaminhamento.
                  if (action.payload.user_id !== chat.chat.origin_id) {
                    chat.chat.origin_id = action.payload.user_id;
                    chat.contact.operator = action.payload.user_id;
                    chat.contact.user_id = action.payload.user_id;
                  }

                  if (!(isOutgoingMessage && !isAssigned)) {
                    chat.chat.chat_read = 0;

                    const { id: userId, superRole } = state.config.user;
                    if (
                      superRole === 'agent' &&
                      userId !== action.payload.user_id &&
                      chat.open
                    ) {
                      chat.open = false;
                      removeHashChat();
                    }
                  }
                }

                chat = addOrUpdateReceivedMessage(chat, action.payload);
                messageUpdated = chat.messageUpdated;

                if (action.payload.dir === 'i') {
                  if (chat.last_message)
                    notification.lastTime(chat.last_message.dtm);

                  // Ultima mensagem recebida de canais especifico
                  const cannnelSelected = chat.view.selectedChannel;
                  if (chat?.last_message && cannnelSelected?.type) {
                    const _channelId = chat?.last_message?.channel_id;

                    const isWabaApi =
                      cannnelSelected?.api == 33 ||
                      cannnelSelected?.api == 34 ||
                      cannnelSelected?.api == 10 ||
                      cannnelSelected?.channel_id == 6;

                    const lastMessageReceived = {
                      id: chat?.last_message?.id,
                      channel_id: _channelId,
                      dtm: chat?.last_message?.dtm,
                      channel_api: cannnelSelected?.api,
                      type_channel: cannnelSelected?.channel_id,
                    };

                    if (!chat.last_message_received) {
                      chat.last_message_received = {};
                    }

                      if (isWabaApi) {
                        if (!chat.last_message_received[cannnelSelected.type]) {
                          chat.last_message_received[cannnelSelected.type] = {};
                        }
                        chat.last_message_received[cannnelSelected.type][
                          _channelId
                        ] = lastMessageReceived;
                      } else {
                        chat.last_message_received[cannnelSelected.type] =
                          lastMessageReceived;
                      }
                    }
                  }

                if (action.payload.contact) {
                  chat.chat.status = action.payload.contact.status;
                }

                // Atualiza o id do chat_history caso ele tenha sido enviado na mensagem
                chat.chat.id = action.payload.chat_history_id ?? chat.chat.id;
              }
              return chat;
            }),
          }, { messageUpdated });
        } else {
          ///é um novo chat
          let pendences = state.pendences || [];
          let shouldPlaySound = false;
          let count_no_read = state.count_no_read || 0;
          notification.lastTime(action.payload.dtm);
          notification.updateTitle(++count_no_read);
          if (!pendences.find((id) => id == action.payload.contact_id)) {
            let shouldPlaySound = false
            // Tem que entrar o contador de novas mensagens por aqui
            pendences.push(action.payload.contact_id);
            let count_no_read = state.count_no_read || 0;
            notification.lastTime(action.payload.dtm);
            notification.updateTitle(++count_no_read);
            // Se é gestor e é um chat não atribuido (double check).
            if (isManager && !hasOperator) {
              shouldPlaySound =
                Boolean(
                  state?.config?.general?.settings?.can_send_notifications
                ) &&
                localStorageEnabledSound &&
                !shouldPlayOnlyMySound;
            }
            return {
              ...state,
              pendences,
              count_no_read,
              shouldPlaySound,
            };
          }
        }
      }
      return state;

    case actionNames.RECEIVE_OPEN_CHAT: {
      if (!action.payload || !action.payload.contact) return state;

      // retorna -1 se não encontrar
      const updatedChatIndex = state.chats.findIndex(
        (chat) => chat.id === action.payload.contact.id
      );

      const updatedContactIndex = state.contacts.findIndex(
        (contact) => contact.id === action.payload.contact.id
      );

      const updatedReadIndex = state.contacts.findIndex(
        (contact) => contact.chat_read === action.payload.contact.chat_read
      );

      if (updatedChatIndex < 0 || updatedContactIndex < 0) return state;

      // Atualiza o chat com novas informações

      const updatedContact = state.contacts[updatedContactIndex];

      const updatedChat = state.chats[updatedChatIndex];

      updatedChat.chat.finished_at = null;

      if (action.payload.contact.user_id) {
        updatedChat.chat.origin_id = action.payload.contact.user_id;
      }

      if (action.payload.chat.id) {
        updatedChat.chat.id = action.payload.chat.id;
      }

      if (action.payload.chat.department_id) {
        updatedChat.chat.department_id = action.payload.chat.department_id;
        updatedContact.department_id = action.payload.chat.department_id;
      }

      // Insere o chat atualizado dentro do estado
      return {
        ...state,
        chats: [
          ...state.chats.slice(0, updatedChatIndex),
          updatedChat,
          ...state.chats.slice(updatedChatIndex + 1),
        ],
        contacts: [
          ...state.contacts.slice(0, updatedContactIndex),
          updatedContact,
          ...state.contacts.slice(updatedContactIndex + 1),
        ],
      };
    }

    case actionNames.STORE_RECEIVED_CHAT_WITH_MESSAGE:
      return {
        ...state,
        chats: state.chats.push(action.payload),
      };

    /**
     * Busca contact external
     */
    case actionNames.FETCH_CONTACT_EXTERNAL:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat.id === action.chat_id) {
            let current = Object.assign({}, chat);
            if (current) {
              current.isLoadingExternal = true;
            }
            return current;
          } else {
            return chat;
          }
        }),
      };

    /**
     * Armazena o contact_external de um chat
     */
    case actionNames.FETCH_CONTACT_EXTERNAL_SUCCESS:
      return {
        ...state,
        hasAsyncRequest: false,
        chats: state.chats.map((chat) => {
          let externals = action.payload;
          if (externals.length) {
            let chat_id = externals[0].contact_id;

            if (chat.id === chat_id) {
              let current = Object.assign({}, chat);

              // se tiver última mensagem, precisamos pegar o contato externo desta mensagem e selecionar ele
              if (current.last_message) {
                let contact_uid = current.last_message.contact_uid;

                if (contact_uid) {
                  externals.map((external) => {
                    if (external.uid === contact_uid) {
                      external.selected = true;
                      return external;
                    }
                    return external;
                  });
                } else {
                  externals[0].selected = true;
                }
              } else {
                externals[0].selected = true;
              }
              if (externals && externals.length)
                externals.map((channel) => {
                  channel.type = getTypeChannel(channel);
                });
              current.contact.externals = externals;

              if (current.isLoadingExternal) delete current.isLoadingExternal;

              return current;
            } else {
              return chat;
            }
          } else {
            return chat;
          }
        }),
      };

    /**
     * REDUCERS RELACIONADOS AO MENU DE CHATS - TODOS, EM ATENDIMENTO, NAO LIDOS, CONTATOS
     *
     * RESPONSÁVEL POR ALTERAR A TAB DE NAVEGAÇÃO QUE ESTÁ ATIVA
     */

    // return state;
    case actionNames.OPEN_CHAT_TAB:
      chat = Object.assign({}, state);

      chat.config.activeTabNavigation = 'chat';
      chat.config.noAttendantTabActive = false;

      return chat;

    case actionNames.OPEN_CONTACT_TAB:
      chat = Object.assign({}, state);

      chat.config.activeTabNavigation = 'contact';
      chat.config.noAttendantTabActive = false;

      chat.isSavingContact = false;

      return chat;

    case actionNames.OPEN_INPROGRESS_CHATS_TAB:
      chat = Object.assign({}, state);

      chat.config.activeTabNavigation = 'inprogress';
      chat.config.noAttendantTabActive = false;

      return chat;
    case actionNames.OPEN_NOATTENDANT_TAB:
      chat = Object.assign({}, state);

      chat.config.noAttendantTabActive = true;

      return chat;

    case actionNames.OPEN_UNREADS_TAB:
      chat = Object.assign({}, state);

      chat.config.activeTabNavigation = 'unreads';
      chat.config.noAttendantTabActive = false;

      return chat;

    case actionNames.SETUP_INITIAL_CONTACT_CONTENT:
      chat = Object.assign({}, state);

      chat.config.openAddContact = false;
      chat.config.openImportContact = false;
      chat.config.openEditContact = false;

      return chat;

    case actionNames.SET_OPEN_ADD_CONTACT:
      chat = Object.assign({}, state);

      chat.config.openAddContact = action.value;

      if (action.value) {
        chat.config.activeTabNavigation = 'contact';
        chat.config.noAttendantTabActive = false;
      }

      return chat;

    case actionNames.SET_ADD_NEW_COMPANY:
      chat = Object.assign({}, state);

      chat.config.openAddCompany = action.value;

      if (action.value) {
        chat.config.activeTabNavigation = 'company';
        chat.config.noAttendantTabActive = false;
      }

      return chat;

    // Set e Unset são usadas para Vcard
    case actionNames.SET_ADD_CARD_CONTACT:
      chat = Object.assign({}, state);
      chat.contacts.currentContactToAdd = action.payload;

      return chat;

    case actionNames.UNSET_ADD_CARD_CONTACT:
      chat = Object.assign({}, state);
      delete chat.contacts.currentContactToAdd;

      return chat;

    case actionNames.SET_OPEN_IMPORT_CONTACT:
      chat = Object.assign({}, state);

      chat.config.openImportContact = action.value;

      return chat;

    case actionNames.SET_OPEN_EDIT_CONTACT:
      chat = Object.assign({}, state);

      chat.config.openEditContact = action.value;
      if (chat.contacts) chat.contacts.currentContactToUpdate = action.data;

      return chat;

    case actionNames.STORE_CHAT_CONTACT: {
      let newContact = action.payload;
      let newContactsList = [];

      if (state.contacts) {
        newContactsList = state.contacts.filter((c) => c.id !== newContact.id);
        let currentContact = state.contacts.find((c) => c.id === newContact.id);
        newContact = { ...currentContact, ...newContact }; ///mantem alguns dados gerados no front
      }

      newContact.picture = checkAvatar(newContact.name, newContact.picture);
      let isVisitorExternal = [];
      if (newContact.externals) {
        newContact.externals.map((channel) => {
          channel.type = getTypeChannel(channel);
          if (channel.type === 'webchat') {
            let uid = channel.uid.split('@');
            if (uid[0]) isVisitorExternal.push(uid[0]);
          }
        });
      }
      if (
        isVisitorExternal.length &&
        state.visitors &&
        state.visitors.sessions &&
        state.visitors.sessions.length
      ) {
        let pattern = isVisitorExternal.join('|');
        state.visitors.sessions.map((session) => {
          if (RegExp(pattern, 'g').test(session._id)) {
            session.user = newContact;
          }
        });
      }
      newContact.operator = newContact.user_id;
      newContactsList.push(newContact);
      if (
        state.chats.find(
          (chat) => chat.contact && chat.contact.id === newContact.id
        )
      ) {
        ///se existir na lista de chat
        state.chats.map((chat) => {
          if (chat.contact.id === newContact.id) {
            chat.contact = newContact;
            if (
              chat.contact.externals &&
              !chat.contact.externals.find(
                (channel) => channel.selected && channel.selected === true
              )
            ) {
              let channels = [];
              if (
                state.config &&
                state.config.user &&
                state.config.user.channels
              ) {
                channels = state.config.user.channels;
              }
              chat = matchChannelToExternal(chat, channels);
            }
          }
        });
      }

      return {
        ...state,
        contacts: newContactsList,
      };
    }

    case actionNames.REMOVE_CHAT_CONTACT: {
      let contact_id = action.payload.contact_id;

      let newContactsList = state.contacts.filter(() => true);
      newContactsList = newContactsList.filter((c) => c.id !== contact_id);

      let chats = state.chats.filter(
        (chat) => chat.contact && chat.contact.id !== contact_id
      );

      return {
        ...state,
        chats,
        contacts: newContactsList,
      };
    }

    case actionNames.STORE_CHAT_USER: {
      let newUser = action.payload;

      let newUsersList = [];

      if (state.config.general && state.config.general.users) {
        newUsersList = Object.assign({}, state.config.general).users;
        newUsersList = newUsersList.filter((c) => c.id !== newUser.id);
      }

      newUsersList.push(newUser);

      let newState = Object.assign({}, state);
      newState.config.general.users = newUsersList;

      if (state.config.user.id === newUser.id) {
        newState.config.user = { ...newState.config.user, ...newUser };
      }

      return newState;
    }

    case actionNames.REMOVE_CHAT_USER: {
      let user_id = action.payload.user_id;

      let newUsersList = Object.assign({}, state.config.general).users;
      newUsersList = newUsersList.filter((c) => c.id !== user_id);

      let newState = Object.assign({}, state);
      newState.config.general.users = newUsersList;

      return newState;
    }

    // Ações para incluir os contatos ou não no estado
    case actionNames.FETCH_CONTACTS_SUCCESS: {
      chat = Object.assign({}, state);
      chat.loading = false;
      chat.hasAsyncRequest = false;

      let contacts = state.contacts || [];
      let newContacts = action.data.data;

      // Se o contato não tiver avatar será atribuído as Iniciais do Nome
      newContacts.map((contact) => {
        if (!contacts.find((c) => c.id === contact.id)) {
          contact.picture = checkAvatar(contact.name, contact.picture);
          if (contact.externals) {
            contact.externals.map((external) => {
              external.type = getTypeChannel(external);
            });
          }
          contacts.push(contact);
        }
        // contact.bgColor = backgroundAvatar();
        return null;
      });

      return {
        ...chat,
        contacts,
        searchContact: {
          current: '',
        },
      };
    }
    case actionNames.FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        errorCode: 1007,
        errorAction: 'FETCH_CONTACTS_FAILURE',
        hasAsyncRequest: false,
        loading: false,
        loadingMessagesOneChat: false,
        contacts: null,
      };

    case actionNames.FETCH_MORE_CONTACTS_REQUEST: {
      return {
        ...state,
        hasAsyncRequest: true,
      };
    }

    case actionNames.FETCH_MORE_CONTACTS_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
      };
    }

    case actionNames.FETCH_MORE_CONTACTS_SUCCESS:
      let new_contacts = action.payload.data;
      let existing_contacts = Object.assign([], state.contacts);
      if (new_contacts.length) {
        new_contacts.forEach((newContact) => {
          if (newContact && newContact.id) {
            let id = newContact.id;

            if (
              existing_contacts.find((contact) => contact.id === id) ===
              undefined
            ) {
              existing_contacts.push(newContact);
            }
          }
        });

        // Se o contato não tiver avatar será atribuído as Iniciais do Nome
        new_contacts.forEach((contact) => {
          // Caso o contato não tenha nome será atribuído o número de telefone
          if ((contact.name === null || contact.name == '') && contact.phone) {
            contact.name = formatPhone(contact.phone);
          }

          contact.picture = checkAvatar(contact.name, contact.picture);
          if (contact.externals) {
            contact.externals.map((channel) => {
              channel.type = getTypeChannel(channel);
            });
          }
        });
      }

      return {
        ...state,
        hasAsyncRequest: false,
        isLoadingMoreContacts: false,
        contacts: existing_contacts,
        error: '',
        errorCode: null,
        errorAction: null,
      };

    case actionNames.FETCH_UNIQUE_CHAT_REQUEST:
      return {
        ...state,
        hasAsyncRequest: true,
        loadingMessagesOneChat: true,
        loadingChatRequest: true,
        loading: false,
      };

    case actionNames.FETCH_UNIQUE_CHAT_FAILURE:
      localStorage.removeItem('chat_id');
      removeHashChat();
      return {
        ...state,
        hasAsyncRequest: false,
        loadingMessagesOneChat: false,
        loadingChatRequest: false,
        loading: false,
      };

    // Esta ação pega o chat buscado e coloca no Estado
    // É responsável por transmitir dados da aba CONTATO PARA O CHAT
    case actionNames.FETCH_UNIQUE_CHAT_SUCCESS:
      localStorage.removeItem('chat_id');
      let listenersUnique = Object.assign({}, state.listeners || {});
      if (!listenersUnique.paste) {
        listenersUnique.paste = createListenerPaste();
      }
      if (!listenersUnique.drag) {
        listenersUnique.drag = createListenerDrag();
      }

      let newUniqueChat = action.data;
      //chat = Object.assign({}, state);

      // Verificando se o contato do chat tem avatar
      newUniqueChat.contact.picture = checkAvatar(
        newUniqueChat.contact.name,
        newUniqueChat.contact.picture
      );

      if (!newUniqueChat.uploadingFiles) {
        newUniqueChat.uploadingFiles = [];
      }
      newUniqueChat.chatDropzone = false;

      newUniqueChat.view = { menus: menus };

      // Criando o contador de novas mensagens (usado na bolinha para voltar para baixo)
      if (!newUniqueChat.newMsgCounter) {
        newUniqueChat.newMsgCounter = 0;
      }

      // Setando os chats abertos como falso
      state.chats.map((chat) => {
        if (chat.id !== newUniqueChat.id) {
          chat.open = false;
          chat.openTextBox = false;
        }
        return null;
      });

      let channels = [];
      if (state.config && state.config.user && state.config.user.channels) {
        channels = state.config.user.channels;
      }

      newUniqueChat = matchChannelToExternal(newUniqueChat, channels);
      // Setando o canal como o mesmo da ultima mensagem enviada
      newUniqueChat = forceLastMessageChannel(newUniqueChat, channels);

      if (newUniqueChat?.contact?.externals) {
        newUniqueChat.contact.externals.map((external) => {
          external.type = getTypeChannel(external);
        });
      }
      // Abrindo o chat
      newUniqueChat.open = true;
      newUniqueChat.openTextBox = true;
      // Inclusão do Chat no array Chats que está no Estado
      const foundUniqueChatIndex = state.chats.findIndex(
        (e) => e.id === newUniqueChat.id
      );
      // Verificando se ja existe este chat para evitar duplicidade
      if (foundUniqueChatIndex > -1) {
        state.chats[foundUniqueChatIndex] = newUniqueChat;
      } else {
        state.chats.push(newUniqueChat);
      }

      // Redirecionando para Aba Chat
      state.config.activeTabNavigation = 'chat';
      state.config.noAttendantTabActive = false;
      state.loading = false;
      state.error = '';
      ////Verificando se existe na lista de contacts
      if (
        !state?.contacts?.find(
          (contact) => newUniqueChat.contact.id === contact.id
        )
      ) {
        if (!state.contacts) {
          state.contacts = [];
        }
        state.contacts.push(newUniqueChat.contact);
      }
      //////
      return {
        ...state,
        hasAsyncRequest: false,
        loadingChatRequest: false,
        chatSelectedId: newUniqueChat.id,
        listeners: listenersUnique,
        loadingMessagesOneChat: false,
        loading: false,
      };

    case actionNames.FETCH_UNIQUE_CHAT:
      if (state) {
        let chat = Object.assign({}, state);
        // Verificando se o contato do chat tem avatar
        action.data.contact.picture = checkAvatar(
          action.data.contact.name,
          action.data.contact.picture
        );

        // Caso o contato não tenha nome será atribuído o número de telefone
        if (
          (action.data.contact.name === null ||
            action.data.contact.name == '') &&
          action.data.contact.phone
        ) {
          action.data.contact.name = formatPhone(action.data.contact.phone);
        }

        action.data.view = { menus: menus };

        let channels = [];
        if (chat.config && chat.config.user && chat.config.user.channels) {
          channels = chat.config.user.channels;
        }

        action.data = matchChannelToExternal(action.data, channels);
        chat.chats.push(action.data);
        return { ...chat };
      }
      return state;
    case actionNames.START_FETCHING_CONTACTS_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          history: [...state.search.history, action.payload],
        },
        isLoadingMoreContcts: true,
      };

    // Reducers para resultado da tentativa de criar um novo contato

    case actionNames.CREATE_CONTACT_REQUEST: {
      return {
        ...state,
        hasAsyncRequest: true,
        isSavingContact: true,
      };
    }
    case actionNames.CREATE_CONTACT_SUCCESS:
      // Criando uma cópia do estado atual
      chat = Object.assign({}, state);

      // Se for contato vindo de um vcard
      if (chat.contacts?.currentContactToAdd) {
        // fecha a tela de contatos
        chat.config.openAddContact = false;
        // Abre a tela de chat
        chat.config.activeTabNavigation = 'chat';
        chat.config.noAttendantTabActive = false;
        // Remove o estado de que armazena os dados do vcard
        delete chat.contacts.currentContactToAdd;
      }

      // Mensagem de Sucesso
      toast.success('Contato Salvo com Sucesso');

      chat.loading = false;
      if (!chat.contacts.find((contact) => contact.id === action.data.id)) {
        let contact = action.data;
        contact.picture = checkAvatar(contact.name, contact.picture);
        if (contact.externals) {
          contact.externals.map((channel) => {
            channel.type = getTypeChannel(channel);
          });
        }
        chat.contacts.push(contact);
      }

      // Fechando o Form de Adicionar Contato
      chat.config.openAddContact = false;

      // Trocando a flag de requisição asíncrona
      chat.hasAsyncRequest = false;

      chat.isSavingContact = false;

      return chat;

    case actionNames.CREATE_CONTACT_FAILURE:
      toast.error(action.error.msg);
      return {
        ...state,
        hasAsyncRequest: false,
        errorCreateContact: action.error,
        isSavingContact: false,
      };

    // Reducers para resultado da tentativa de atualizar um contato existente

    case actionNames.UPDATE_CONTACT_REQUEST: {
      return {
        ...state,
        hasAsyncRequest: true,
        isSavingContact: true,
      };
    }
    case actionNames.UPDATE_CONTACT_SUCCESS:
      const contactId = action.data.id;
      // Criando uma cópia do estado atual
      chat = Object.assign({}, state);

      // Fazendo alterações no Estado do Chat
      chat.chats.map((chat) => {
        if (chat.contact.id === contactId) {
          for (let key in chat.contact) {
            chat.contact[key] = action.data[key] ?? chat.contact[key];
          }
          chat.contact.picture = checkAvatar(
            action.data.name,
            action.data.picture
          );
          if (chat.contact.externals) {
            chat.contact.externals.map((external) => {
              external.type = getTypeChannel(external);
            });
          }
          let config = Object.assign({}, state.config);
          let channels = [];
          if (config && config.user && config.user.channels) {
            channels = config.user.channels;
          }
          chat = matchChannelToExternal(chat, channels);
        }
        return null;
      });

      // Fazendo alterações no Estado de Contatos
      chat.contacts.map((contact) => {
        if (contact.id === contactId) {
          for (let key in contact) {
            contact[key] = action.data[key] ?? contact[key];
          }
          contact.picture = checkAvatar(action.data.name, action.data.picture);
          contact.operator = action.data.user_id;
        }
        return null;
      });

      // Mensagem de Sucesso
      toast.success('Informações atualizadas com Sucesso.');

      // Trocando a flag de requisição asíncrona
      chat.hasAsyncRequest = false;

      chat.isSavingContact = false;

      //Fechando a Edição de Contatos
      chat.config.openEditContact = false;

      // Fechando Aba Contatos e Abrindo Chat
      chat.config.activeTabNavigation = 'chat';
      chat.config.noAttendantTabActive = false;
      return chat;

    case actionNames.UPDATE_CONTACT_FAILURE:
      toast.error(`${action.error.msg}`);
      return {
        ...state,
        hasAsyncRequest: false,
        isSavingContact: false,
      };

    case actionNames.SEARCH: {
      state.searchContact.current = action.payload;
      return {
        ...state,
        hasAsyncRequest: true,
      };
    }

    case actionNames.UPDATE_CHAT_SEARCH: {
      // Quando a caixa de pesquisa está vazia
      if (action.payload.length === 0) {
        return Object.assign({}, state, {
          search: {
            ...state.search,
            current: action.payload,
            chatsSearched: [],
          },
        });
      }
      return Object.assign({}, state, {
        search: {
          ...state.search,
          current: action.payload,
        },
      });
    }

    case actionNames.PUT_RESULTS_IN_SEARCH_CHATS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          chatsSearched: action.chats,
        },
      });

    case actionNames.CLEAR_RESULTS_IN_SEARCH_CHATS:
      return Object.assign({}, state, {
        currentPage: {
          ...state.currentPage,
          search: { page: 0 },
        },
      });

    /**
     * Atualiza o search de contatos
     */
    case actionNames.UPDATE_CONTACT_SEARCH:
      return Object.assign({}, state, {
        searchContact: {
          ...state.searchContact,
          current: action.payload,
        },
      });

    case actionNames.CONTACT_SEARCH_SUCCESS:
      let searchResult = action.data.data;
      if (searchResult.length !== 0) {
        searchResult.map((contact) => {
          // Caso o contato não tenha nome será atribuído o número de telefone
          if ((contact.name === null || contact.name == '') && contact.phone) {
            contact.name = formatPhone(contact.phone);
          }

          contact.picture = checkAvatar(contact.name, contact.picture);
          if (contact.externals) {
            contact.externals.map((channel) => {
              channel.type = getTypeChannel(channel);
            });
          }
          return null;
        });
        return Object.assign({}, state, {
          hasAsyncRequest: false,
          searchContact: {
            ...state.searchContact,
            searchResult,
          },
        });
      } else {
        return Object.assign({}, state, {
          hasAsyncRequest: false,
          searchContact: {
            ...state.searchContact,
            searchResult: undefined,
          },
        });
      }

    case actionNames.CONTACT_SEARCH_FAILURE:
      return Object.assign({}, state, {
        hasAsyncRequest: false,
        searchContact: {
          ...state.searchContact,
          searchResult: undefined,
        },
      });
    case actionNames.MERGE_CONTACT_SEARCH:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.searchingContactsToMerge) {
            contact.searchingContactsToMerge.searchingStatus = 0;
          }
          if (contact.id == action.payload.contact_id) {
            contact.searchingContactsToMerge = {
              searchingStatus: 1, ///loading
              suggestion: action.payload?.suggestion || false,
              term: action.payload.term,
              results: [],
            };
          }
        });
      }
      return { ...state };
    case actionNames.MERGE_CONTACT_SEARCH_SUCCESS:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (
            contact.searchingContactsToMerge &&
            contact.searchingContactsToMerge.searchingStatus === 1 &&
            contact.id == action.payload.contact_id &&
            contact.searchingContactsToMerge.term === action.payload.term
          ) {
            contact.searchingContactsToMerge = {
              searchingStatus: 2, ///sucesso
              suggestion: contact.searchingContactsToMerge.suggestion || false,
              term: action.payload.term,
              results: action.payload.data,
            };
            contact.searchingContactsToMerge.results.map((result_contact) => {
              result_contact.picture = checkAvatar(
                result_contact.name,
                result_contact.picture
              );
            });
          } /*else if (contact.searchingContactsToMerge) {
            contact.searchingContactsToMerge.searchingStatus = 0;
          }*/
        });
      }
      return { ...state, hasAsyncRequest: false };
    case actionNames.MERGE_CONTACT_SEARCH_FAILURE:
      if (state.contacts) {
        state.contacts.map((contact) => {
          //contact.searchingContactsToMerge = null;
          if (contact.id == action.payload.contact_id) {
            contact.searchingContactsToMerge = {
              searchingStatus: -1, ///failure
              suggestion: contact.searchingContactsToMerge?.suggestion || false,
              term: action.payload.term,
              results: [],
            };
          }
        });
      }
      return { ...state, hasAsyncRequest: false };
    case actionNames.UNMERGE_CONTACTS:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_from) {
            contact.statusUnmerging = 1;
          }
        });
      }
      return { ...state };
    case actionNames.UNMERGE_CONTACTS_FAILURE:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_from) {
            contact.statusUnmerging = -1;
            contact.errorMsg = action.payload.msg || null;
            if (action.payload.msg) {
              toast.error(
                <div className="toastAlertChat">
                  <strong>Erro ao tentar desvincular contatos!</strong>
                  <small>{'Error: ' + action.payload.msg}</small>
                </div>
              );
            }
          }
        });
      }
      return { ...state, hasAsyncRequest: false };
    case actionNames.UNMERGE_CONTACTS_SUCCESS:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_from) {
            if (contact.statusUnmerging === 1) {
              toast.success('Alteração realizada com sucesso!');
              contact.statusUnmerging = 0;
              contact.errorMsg = null;
            }
            let aux_contacts_externals = [];
            for (let i in contact.externals) {
              if (
                !contact.externals[i].data ||
                contact.externals[i].data.contact_id_origin !==
                  action.payload.contact_id_to
              ) {
                aux_contacts_externals.push(contact.externals[i]);
              }
            }
            contact.externals = aux_contacts_externals;
          }
        });
      }
      return { ...state, hasAsyncRequest: false };
    case actionNames.MERGE_CONTACTS:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_to) {
            contact.searchingContactsToMerge =
              contact.searchingContactsToMerge || {};
            contact.searchingContactsToMerge.mergingStatus = 1;
          }
        });
      }
      return { ...state };
    case actionNames.MERGE_CONTACTS_SUCCESS:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_to) {
            contact.searchingContactsToMerge =
              contact.searchingContactsToMerge || {};
            if (contact.searchingContactsToMerge.mergingStatus === 1) {
              toast.success('Alteração realizada com sucesso!');
              contact.searchingContactsToMerge.mergingStatus = 0;
            }
            if (
              action.payload.contact_id_from &&
              contact.searchingContactsToMerge.results
            ) {
              ////tirar da lista de resultados da pesquisa
              let aux_contacts_result = [];
              for (let i in contact.searchingContactsToMerge.results) {
                if (
                  contact.searchingContactsToMerge.results[i].id !==
                  action.payload.contact_id_from
                ) {
                  aux_contacts_result.push(
                    contact.searchingContactsToMerge.results[i]
                  );
                }
              }
              contact.searchingContactsToMerge.results = aux_contacts_result;
            }
          }
        });
      }
      return { ...state, hasAsyncRequest: false };
    case actionNames.MERGE_CONTACTS_FAILURE:
      if (state.contacts) {
        state.contacts.map((contact) => {
          if (contact.id == action.payload.contact_id_to) {
            contact.searchingContactsToMerge =
              contact.searchingContactsToMerge || {};
            contact.searchingContactsToMerge.mergingStatus = -1;
            contact.searchingContactsToMerge.errorMsg =
              action.payload.msg || null;
            if (action.payload.msg) {
              toast.error(
                <div className="toastAlertChat">
                  <strong>Erro ao tentar vincular contatos!</strong>
                  <small>{'Error: ' + action.payload.msg}</small>
                </div>
              );
            }
          }
        });
      }
      return { ...state, hasAsyncRequest: false };
    /**
     * Altera o channel_id da conversa quando escolhe outro canal
     */
    case actionNames.SELECT_CHANNEL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            if (openChat.chat && !openChat.chat.channel_id) {
              if (
                state.config?.user?.channels &&
                state.config?.user?.channels.length > 0
              ) {
                openChat.chat.channel_id = state.config.user.channels[0].id;
              }
            }

            // Atualiza o canal atual
            openChat.view.selectedChannel = action.payload;
            openChat.chat.channel_id = action.payload.id;

            // verifica, entre os contact_externals, o que está selecionado.
            // Caso o contato externo que esteja selecionado tenha canal do tipo diferente do canal atual, precisamos alterar para um contato externo do mesmo canal
            if (openChat.contact && openChat.contact.externals) {
              let externals = openChat.contact.externals;

              if (externals.length > 0) {
                let current_selected = externals.find(
                  (external) => external.selected === true
                );

                if (
                  !current_selected ||
                  (current_selected &&
                    current_selected.channel_id !== action.payload.channel_id)
                ) {
                  // removo todos os selecteds
                  externals.forEach((external) => {
                    if (external.selected) {
                      delete external.selected;
                    }
                  });

                  // seto como selecionado apenas o contact_external do canal diferente
                  for (let i = 0; i < externals.length; i++) {
                    if (externals[i].channel_id === action.payload.channel_id) {
                      externals[i].selected = true;
                      break;
                    }
                  }
                }
              }
            }
            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Altera o contact_externaal da conversa
     */
    case actionNames.SELECT_CONTACT_EXTERNAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            if (openChat.contact && openChat.contact.externals?.length) {
              openChat.contact.externals.map((external) => {
                if (external.id === action.external_id) {
                  external.selected = true;
                } else {
                  if (external.selected) {
                    delete external.selected;
                  }
                }

                return external;
              });

              // verifica, entre os channels, o que está selecionado.
              //Caso o channel que esteja selecionado tenha type diferente do contact_external atual, precisamos alterar para outro canal do mesmo type
              if (state.config.user && state.config.user.channels) {
                let channels = Object.assign([], state.config.user.channels);

                let current_channel_id = openChat.view.selectedChannel.id;
                let current_channel_object = channels.find(
                  (channel) => channel.id === current_channel_id
                );

                if (
                  current_channel_object &&
                  current_channel_object.channel_id !== action.channel_type
                ) {
                  let new_channel_selected = channels.find(
                    (channel) => channel.channel_id === action.channel_type
                  );

                  if (new_channel_selected) {
                    openChat.view.selectedChannel = new_channel_selected;
                  }
                }
              }
            }

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Incoming Channel Events
     */

    case actionNames.STORE_CHANNEL:
      if (state.config && state.config.general) {
        let config = Object.assign({}, state.config);
        config.general.channels.push(action.payload);
        state.config.user.channels = config.general.channels;
        return { ...state, config: config };
      }
      return state;
    case actionNames.UPDATE_CHANNEL:
      if (state.config) {
        let config = Object.assign({}, state.config);
        config.general.channels = config.general.channels.map((channel) => {
          channel.type = getTypeChannel(channel);
          if (channel.id === action.payload.id) {
            return action.payload;
          } else {
            return channel;
          }
        });
        state.config.user.channels = config.general.channels;
        return { ...state, config };
      }
      return state;
    case actionNames.DELETE_CHANNEL:
      if (
        state.config &&
        state.config.general &&
        state.config.general.channels
      ) {
        let config = Object.assign({}, state.config);
        let channels = [];
        config.general.channels.map((channel) => {
          if (channel.id !== action.payload.id) {
            channels.push(channel);
          }
          return channel;
        });
        config.general.channels = channels;
        state.config.user.channels = config.general.channels;
        return { ...state, config: config };
      }
      return state;

    /**
     * Reducers relacionados a filtros
     */
    case actionNames.TOGGLE_FILTER_MENU:
      switch (action.filterName) {
        case 'main':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isMainMenuOpen: !state.filters.view.isMainMenuOpen,
              },
            },
          });

        case 'attendants':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isAttendanceMenuOpen: !state.filters.view.isAttendanceMenuOpen,
              },
            },
          });

        case 'departments':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isDepartmentsMenuOpen:
                  !state.filters.view.isDepartmentsMenuOpen,
              },
            },
          });

        case 'tags':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isTagsMenuOpen: !state.filters.view.isTagsMenuOpen,
              },
            },
          });

        case 'channels':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isChannelMenuOpen: !state.filters.view.isChannelMenuOpen,
              },
            },
          });

        case 'chats':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isChatMenuOpen: !state.filters.view.isChatMenuOpen,
              },
            },
          });

        case 'date':
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              view: {
                ...state.filters.view,
                isDateMenuOpen: !state.filters.view.isDateMenuOpen,
              },
            },
          });

        default:
          return state;
      }

    case actionNames.TOGGLE_ATTENDANCE_FILTER_MENU:
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          isAttendanceMenuOpen: !state.filters.isAttendanceMenuOpen,
        },
      });

    case actionNames.UPDATE_FILTERS:
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          activeFilters: action.payload,
        },
        currentPage: {
          ...state.currentPage,
          customFilter: { page: 0 },
        },
      });

    case actionNames.UPDATE_CURRENT_PAGE_CUSTOM_FILTER:
      return Object.assign({}, state, {
        currentPage: {
          ...state.currentPage,
          customFilter: { page: action.quantity },
        },
      });

    case actionNames.REMOVE_FILTER:
      let filtersObj = Object.assign({}, state.filters);

      activeFilters = filtersObj.activeFilters;

      switch (action.payload) {
        case 'attendant':
          activeFilters.attendant = [];
          break;
        case 'date':
          activeFilters.date = [];
          break;
        case 'department':
          activeFilters.department = [];
          break;
        case 'tag':
          activeFilters.tag = [];
          break;
        case 'channel':
          activeFilters.channel = [];
          break;
        case 'chat':
          activeFilters.chat = [];
          break;
        default:
          break;
      }

      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          activeFilters: activeFilters,
        },
      });

    case actionNames.CLEAR_FILTER:
      return Object.assign({}, state, {
        filters: {
          ...state.filters,
          activeFilters: activeFiltersModel,
        },
      });

    case actionNames.CLEAR_CUSTOM_FILTER_PAGE:
      return Object.assign({}, state, {
        currentPage: {
          ...state.currentPage,
          customFilter: { page: 0 },
        },
      });

    /**
     * Reducer for opening tags chat modal
     */
    case actionNames.OPEN_TAG_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            let tagMenuIndex = openChat.view.menus.findIndex(
              (menu) => menu.type === MenuTypes.TAG_MENU
            );

            if (tagMenuIndex) {
              openChat.view.menus[tagMenuIndex].isOpen = true;

              let allTags = Object.assign([], state.config.general.tags);

              openChat.view.menus[tagMenuIndex].availableTags = [];
              openChat.view.menus[tagMenuIndex].contactTags = [];

              if (openChat.tags && openChat.tags.length) {
                if (allTags && allTags.length) {
                  //preenche as tags disponiveis
                  allTags.forEach((tag) => {
                    if (
                      openChat.tags.find((t) => t.tag_id === tag.id) ===
                      undefined
                    ) {
                      openChat.view.menus[tagMenuIndex].availableTags.push(tag);
                    }
                  });
                }
              } else {
                openChat.view.menus[tagMenuIndex].availableTags = allTags;
              }
            }

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.GET_TAGS:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);
            let tagMenuIndex = openChat.view.menus.findIndex(
              (menu) => menu.type === MenuTypes.TAG_MENU
            );

            if (tagMenuIndex) {
              let allTags = Object.assign([], state.config.general.tags);

              openChat.view.menus[tagMenuIndex].availableTags = [];
              openChat.view.menus[tagMenuIndex].contactTags = [];
              openChat.view.menus[tagMenuIndex].closeBadge = false;

              if (openChat.tags && openChat.tags.length) {
                if (allTags && allTags.length) {
                  //preenche as tags disponiveis
                  allTags.forEach((tag) => {
                    if (
                      openChat.tags.find((t) => t.tag_id === tag.id) ===
                      undefined
                    ) {
                      openChat.view.menus[tagMenuIndex].availableTags.push(tag);
                    }
                  });
                }
              } else {
                openChat.view.menus[tagMenuIndex].availableTags = allTags;
              }
            }

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.CLOSE_TAG_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            openChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).isOpen = false;

            openChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).isLoading = false;

            openChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).closeBadge = true;

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Request ao inserir uma tag ao contato
     */
    case actionNames.INSERT_TAG_TO_CONTACT:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            let tagsMenu = openChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            );

            if (tagsMenu) {
              tagsMenu.contactTags.push(action.payload);
              tagsMenu.closeBadge = false;

              let index = tagsMenu.availableTags.findIndex(
                (tag) => tag.id === action.payload.id
              );

              if (index >= 0) tagsMenu.availableTags.splice(index, 1);
            }

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.REMOVE_TAG_FROM_CONTACT:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let openChat = Object.assign({}, chat);

            let tagsMenu = openChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            );

            if (tagsMenu) {
              let index = tagsMenu.contactTags.findIndex(
                (tag) => tag.id === action.payload.id
              );

              tagsMenu.closeBadge = false;

              if (index >= 0) tagsMenu.contactTags.splice(index, 1);

              //tagsMenu.availableTags = [];
              tagsMenu.availableTags.push(action.payload);
            }

            return openChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.INSERT_TAG_TO_CONTACT_REQUEST:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload) {
            let opennedChat = Object.assign({}, chat);

            opennedChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).isLoading = true;

            opennedChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).closeBadge = false;

            return opennedChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.INSERT_TAG_TO_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.contact_id) {
            let opennedChat = Object.assign({}, chat);

            //let tags = action.payload.tags;

            if (action.payload.tags && action.payload.tags.length)
              opennedChat.tags = [...action.payload.tags, ...opennedChat.tags];

            return opennedChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.INSERT_TAG_TO_CONTACT_FAILURE:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.contact_id) {
            let opennedChat = Object.assign({}, chat);

            opennedChat.view.menus.find(
              (menu) => menu.type === MenuTypes.TAG_MENU
            ).isLoading = false;

            if (action.payload.tags && action.payload.tags.length)
              opennedChat.tags = [...opennedChat.tags, ...action.payload.tags];

            return opennedChat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.REMOVE_TAG_FROM_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            let opennedChat = Object.assign({}, chat);

            if (opennedChat.tags && opennedChat.tags.length) {
              let tagIndex = opennedChat.tags.findIndex(
                (t) => t.id === action.payload.tag_id
              );

              if (tagIndex > -1) {
                opennedChat.tags.splice(tagIndex, 1);
              }
            }

            return opennedChat;
          } else {
            return chat;
          }
        }),
      });

    /**
     * Atualizar as tags (etiquetas)
     */
    case actionNames.INCOMING_TAG_CREATED: {
      let newTag = action.payload;

      let newTagsList = Object.assign({}, state.config.general).tags;
      newTagsList = newTagsList.filter((tag) => tag.id !== newTag.id);

      newTagsList.push(newTag);

      let newState = Object.assign({}, state);
      newState.config.general.tags = newTagsList;

      return newState;
    }

    case actionNames.INCOMING_TAG_DELETED: {
      let tagId = action.payload.id;

      let newTagsList = Object.assign({}, state.config.general).tags;
      newTagsList = newTagsList.filter((tag) => tag.id !== tagId);

      let newState = Object.assign({}, state);
      newState.config.general.tags = newTagsList;

      return newState;
    }

    case actionNames.FETCH_QUICK_MESSAGES:
      return {
        ...state,
        hasAsyncRequest: true,
        quickMessages: {
          loading: true,
          messages: [],
          isOpen: state.quickMessages?.isOpen || false,
        },
      };
    case actionNames.FETCH_QUICK_MESSAGES_SUCCESS:
      return {
        ...state,
        hasAsyncRequest: false,
        quickMessages: {
          loading: false,
          loadingMessagesOneChat: false,
          messages: action.payload,
          isOpen: state.quickMessages?.isOpen || false,
        },
      };
    case actionNames.FETCH_QUICK_MESSAGES_FAILURE:
      return {
        ...state,
        hasAsyncRequest: false,
        quickMessages: {
          loading: false,
          loadingMessagesOneChat: false,
          messages: [],
          isOpen: false,
          failure: true,
        },
      };
    case actionNames.CANCEL_EDIT_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          edit: null,
          editError: false,
        },
      };
    case actionNames.CANCEL_DELETE_QUICK_MESSAGE:
    case actionNames.DELETE_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          delete: null,
          loadingDelete: true,
          removeSuccess: undefined,
        },
      };
    case actionNames.ALERT_DELETE_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          delete: action.payload,
        },
      };
    case actionNames.EDIT_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          edit: action.payload,
        },
      };
    case actionNames.TOGGLE_QUICK_MESSAGES_SELECTOR:
      let open = !state.quickMessages?.isOpen;
      if (action.payload === 'open') {
        open = true;
      } else if (action.payload === 'close') {
        open = false;
      }
      // Lógica responsável por colorir o botão de mensagens rápidas quando ele estiver ativo
      let buttonHandleQuickMessages = document.querySelector(
        '#buttonHandleQuickMessages'
      );
      if (
        buttonHandleQuickMessages &&
        buttonHandleQuickMessages !== null &&
        buttonHandleQuickMessages !== undefined
      ) {
        // Não mudar para className
        if (open) {
          buttonHandleQuickMessages.innerHTML =
            '<i icon="poli-icon pi-close-line" class="poli-icon pi-close-line" style="font-size: 20px; color: var(--red-polichat);"></i>';
        } else {
          buttonHandleQuickMessages.innerHTML =
            '<i icon="poli-icon pi-rocket-fill" class="poli-icon pi-rocket-fill" style="font-size: 20px; color: var(--principal-icons-color)"></i>';
        }
      }

      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          term: !open ? null : state.quickMessages?.term,
          search: !open ? [] : state.quickMessages?.search,
          isOpen: open,
          createErrorData: null,

          // estados para auxiliar na versão 2 do QuickMessageSelector
          //resetar valores ao abrir
          createOrUpdateSuccess: undefined,
          createOrUpdateError: undefined,
          loadingDelete: undefined,
          removeSuccess: undefined,
        },
      };
    case actionNames.NAV_QUICK_MESSAGES_SELECTOR:
      let dir =
        typeof state.quickMessages.nav === 'undefined'
          ? 0
          : state.quickMessages.nav;
      let max = dir;
      if (state.quickMessages.search && state.quickMessages.term) {
        max = state.quickMessages.search.length;
      } else {
        max = state.quickMessages.messages.length;
      }
      if (dir >= max) {
        dir = max - 1;
      }
      if (action.dir === 'up') {
        dir -= dir > 0 ? 1 : 0;
      } else {
        dir += dir < max - 1 ? 1 : 0;
      }
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          nav: dir < 0 ? 0 : dir >= max ? max - 1 : dir,
        },
      };
    case actionNames.SEARCH_QUICK_MESSAGES:
      let _term = action.term;
      if (_term) {
        _term = _term.replace(/['|"]/g, '');
      }

      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          term: _term,
          search: !action.isFlagQuickMessageV2Active
            ? QuickMessages.search(_term, state.quickMessages.messages)
            : undefined,
        },
      };
    case actionNames.CREATE_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          loadingForm: true,
          createErrorData: null,
          editError: false,
          // resetar valores de status para componente QuickMessageSelector v2
          createOrUpdateSuccess: undefined,
          createOrUpdateError: undefined,
        },
      };
    case actionNames.DELETED_QUICK_MESSAGE:
      quick_messages = [];
      search_quick_messages = null;
      for (let key in state.quickMessages.messages) {
        if (state.quickMessages.messages[key].id !== action.payload.id) {
          quick_messages.push(state.quickMessages.messages[key]);
        }
      }
      if (state.quickMessages.term) {
        search_quick_messages = QuickMessages.search(
          state.quickMessages.term,
          quick_messages
        );
      }
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          ...(state.quickMessages.loadingDelete && {
            loadingDelete: false,
            removeSuccess: true,
          }),
          messages: quick_messages,
          search: search_quick_messages,
        },
      };
    case actionNames.CREATE_QUICK_MESSAGE_SUCCESS:
    case actionNames.CREATED_QUICK_MESSAGE:
    case actionNames.UPDATED_QUICK_MESSAGE:
    case actionNames.UPDATE_QUICK_MESSAGE_SUCCESS:
      quick_messages = state.quickMessages.messages;
      search_quick_messages = state.quickMessages.search || null;
      let only_updated = false;
      for (let key in quick_messages) {
        if (quick_messages[key].id === action.payload.id) {
          quick_messages[key] = action.payload;
          only_updated = true;
        }
      }
      if (!only_updated) {
        quick_messages.push(action.payload);
      }
      if (state.quickMessages.term) {
        search_quick_messages = QuickMessages.search(
          state.quickMessages.term,
          quick_messages
        );
      }

      return {
        ...state,
        messages: quick_messages,
        quickMessages: {
          ...state.quickMessages,
          messages: [...quick_messages],
          search: search_quick_messages,
          createErrorData: null,
          editError: false,
          edit: null,
          // altera valor de status apenas se estivesse carregando
          ...(state.quickMessages.loadingForm && {
            createOrUpdateSuccess: true,
            createOrUpdateError: false,
            }),
          loadingForm: false,
        },
      };
    case actionNames.CREATE_QUICK_MESSAGE_FAILURE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          loadingForm: false,
          createErrorData: !state.quickMessages?.isOpen ? null : action.payload,
          editError: false,
          createOrUpdateSuccess: false,
          createOrUpdateError: { ...action.payload?.error },
        },
      };
    case actionNames.UPDATE_QUICK_MESSAGE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          loadingForm: true,
          createErrorData: null,
          editError: false,
          edit: null,
          // resetar valores de status para componente QuickMessageSelector v2
          createOrUpdateSuccess: undefined,
          createOrUpdateError: undefined,
        },
      };
    case actionNames.UPDATE_QUICK_MESSAGE_FAILURE:
      return {
        ...state,
        quickMessages: {
          ...state.quickMessages,
          loadingForm: false,
          editError: true,
          edit: !state.quickMessages?.isOpen ? null : action.payload,
          createOrUpdateSuccess: false,
          createOrUpdateError: { ...action.payload?.error },
        },
      };

    case actionNames.UPDATE_SETTINGS:
      let auxConfig = Object.assign({}, state.config);
      auxConfig.general.settings = action.payload;
      return {
        ...state,
        config: auxConfig,
      };

    case actionNames.UPDATE_CUSTOMER_ONBOARDING:
      let _auxConfig = Object.assign({}, state.config);
      _auxConfig.general.customer_onboarding = action.payload;
      return {
        ...state,
        config: _auxConfig,
      };

    case actionNames.REPLYING_MESSAGE: {
      let currentState = Object.assign({}, state);

      let currentChat = currentState.chats.find(
        (c) => c.id === action.payload.chat_id
      );
      if (!currentChat) return state;

      let currentMessage = currentChat.messages.find(
        (m) => m.uid === action.payload.message_uid
      );
      if (!currentMessage) return state;

      currentChat.replying_message = {
        uid: currentMessage.uid,
        dir: currentMessage.dir,
        type: currentMessage.type,
        author_name:
          currentMessage.dir === 'o'
            ? 'Você'
            : currentMessage.author_name
            ? currentMessage.author_name
            : currentChat.contact.name,
        file_name: currentMessage.file_name,
        text: currentMessage.body,
        thumb: currentMessage.thumb,
        url: currentMessage.url,
        mimeType: currentMessage.mimetype,
        caption: currentMessage.caption,
        vcard: currentMessage.vcard ? true : false,
      };

      currentChat.replying_message = treatMessageType(
        currentChat.replying_message
      );

      return currentState;
    }

    case actionNames.CLEAR_REPLYING_MESSAGE: {
      let currentState = Object.assign({}, state);

      let currentChat = currentState.chats.find(
        (c) => c.id === action.payload.chat_id
      );

      if (!currentChat) return state;

      currentChat.replying_message = null;

      return currentState;
    }
    /*
    case actionNames.START_VISITORS_SERVICE: {
      let currentState = Object.assign({}, state);
      //if (!currentState.visitors) {
      currentState.visitors = {};
      //}
      currentState.visitors.loading = true;
      currentState.hasAsyncRequest = true; // patryck ver se vale a pena fazer isso
      return { ...currentState };
    }
    case actionNames.START_VISITORS_SERVICE_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
        visitors: {
          loading: false,
          loadingMessagesOneChat: false,
          error: true,
        },
      };
    }
    case actionNames.START_VISITORS_SERVICE_SUCCESS: {
      return {
        ...state,
        hasAsyncRequest: false,
        visitors: {
          loading: false,
          loadingMessagesOneChat: false,
          error: false,
          sessions: action.payload,
        },
      };
    }
    case actionNames.STORE_FROM_VISITOR: {
      let visitor = state.visitors?.sessions.find(
        (session) => session._id === action.payload._id
      );
      if (visitor) {
        visitor.loadingStore = true;
        visitor.errorStore = false;
        state.visitors.sessions.map((session) => {
          if (session._id === visitor._id) {
            session = visitor;
          }
        });
      }
      return { ...state };
    }
    case actionNames.STORE_FROM_VISITOR_FAILURE: {
      let visitor = state.visitors?.sessions.find(
        (session) => session._id === action.payload._id
      );
      if (visitor) {
        visitor.loadingStore = false;
        visitor.errorStore = true;
        state.visitors.sessions.map((session) => {
          if (session._id === visitor._id) {
            session = visitor;
          }
        });
      }
      return { ...state };
    }
    case actionNames.STORE_FROM_VISITOR_SUCCESS: {
      let newState = Object.assign({}, state);
      let visitor = newState.visitors?.sessions.find(
        (session) => session._id === action.payload._id
      );
      if (visitor) {
        let contact = newState?.contacts.find(
          (c) => c.id === action.payload?.id
        );
        if (!contact && action.payload.id) {
          newState.contacts.push(action.payload);
          contact = action.payload;
        }
        visitor.user = contact;
        visitor.loadingStore = false;
        visitor.errorStore = false;
        newState.visitors.sessions.map((session) => {
          if (session._id === visitor._id) {
            session = visitor;
          }
        });
      }
      return newState;
    }
    case actionNames.FETCH_SESSIONS_WEBCHAT: {
      if (!state.visitors || !state.visitors.sessions) {
        return {
          ...state,
          hasAsyncRequest: false,
          visitors: {
            loading: false,
            loadingMessagesOneChat: false,
            error: false,
            sessions: action.payload,
          },
        };
      }

      let sessions = state.visitors.sessions;
      let newListSessions = [];
      action.payload.map((session) => {
        let oldSession = sessions.find((se) => se._id === session._id);
        if (oldSession && oldSession.user && !session.user) {
          session.user = oldSession.user;
        }
        newListSessions.push(session);
      });
      return {
        ...state,
        hasAsyncRequest: false,
        visitors: {
          loading: false,
          loadingMessagesOneChat: false,
          error: false,
          sessions: newListSessions,
        },
      };
    }
    case actionNames.NEW_VISITOR_WEBCHAT: {
      if (!state.visitors) {
        return state;
      }

      let sessions = state.visitors.sessions || [];
      if (!sessions.find((session) => session._id === action.payload._id)) {
        sessions.push(action.payload);
      }
      return {
        ...state,
        visitors: { ...state.visitors, sessions },
      };
    }
    case actionNames.UPDATED_VISITOR_WEBCHAT: {
      if (!state.visitors) {
        return state;
      }

      let sessions = state.visitors.sessions || [];
      if (!sessions.find((session) => session._id === action.payload._id)) {
        sessions.push(action.payload);
      } else {
        let newSessions = [];
        sessions.map((session) => {
          if (session._id === action.payload._id) {
            newSessions.push(action.payload);
          } else {
            newSessions.push(session);
          }
        });

        sessions = newSessions;
      }
      return {
        ...state,
        visitors: { ...state.visitors, sessions },
      };
    }
*/

    /**
     * Reducers da cobrança e carrinho
     */

    case actionNames.OPEN_PAYMENT_CHAT_MODAL: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || null;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            return { ...chat, payment_chat_modal_open: true };
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLOSE_PAYMENT_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let _chat = Object.assign({}, chat);
            if (_chat.payment_chat_modal_open)
              delete _chat.payment_chat_modal_open;
            // if (_chat.payment) delete _chat.payment;
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.PAYMENT_CHAT_REQUEST:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            return {
              ...chat,
              payment: { loadingPayment: true, error: null, edit: false },
            };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.PAYMENT_CHAT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            return {
              ...chat,
              payment: {
                loadingPayment: false,
                error: true,
              },
            };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.PAYMENT_CHAT_REQUEST_SUCCESS:
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || null;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            return {
              ...chat,
              payment: {
                data: action.payload,
                loadingPayment: false,
                success: true,
                error: null,
                edit: false,
              },
            };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.PAYMENT_CHAT_EDIT_PAYMENT:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            return {
              ...chat,
              payment: {
                ...chat.payment,
                loadingPayment: false,
                success: false,
                error: null,
                edit: true,
              },
            };
          } else {
            return chat;
          }
        }),
      });

    case actionNames.PAYMENT_CHAT_CLEAR_PAYMENT:
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || null;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let _chat = Object.assign({}, chat);
            if (_chat.payment_chat_modal_open)
              delete _chat.payment_chat_modal_open;
            if (_chat.payment) delete _chat.payment;
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.OPEN_CART_CHAT_MODAL: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || null;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            return { ...chat, cart_chat_modal_open: true };
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLOSE_CART_CHAT_MODAL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let _chat = Object.assign({}, chat);
            if (_chat.cart_chat_modal_open) delete _chat.cart_chat_modal_open;
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.SHOW_CART_BUTTON: {
      if (!action.id) {
        action.id = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.id) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart_option_show = action.value;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.OPEN_CATALOG_CAROUSEL: {
      if (!action.id) {
        action.id = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.id) {
            let currentChat = Object.assign({}, chat);

            currentChat.catalog_carousel_show = true;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLOSE_CATALOG_CAROUSEL:
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.open === true) {
            let _chat = Object.assign({}, chat);
            if (_chat.catalog_carousel_show)
              _chat.catalog_carousel_show = false;
            return _chat;
          } else {
            return chat;
          }
        }),
      });

    case actionNames.FETCH_CATALOG:
      return {
        ...state,
        hasAsyncRequest: true,
        catalog: {
          ...state.catalog,
          loading: true,
          categories: null,
          products: null,
        },
      };

    case actionNames.FETCH_CATALOG_SUCCESS: {
      return {
        ...state,
        hasAsyncRequest: false,
        catalog: {
          ...state.catalog,
          loading: false,
          loadingMessagesOneChat: false,
          products: action.payload?.products
            ? action.payload.products
            : state.catalog.products
            ? state.catalog.products
            : null,
          categories: action.payload?.categories
            ? action.payload.categories
            : state.catalog.categories
            ? state.catalog.categories
            : null,
          currentPagination: action.payload.currentPagination,
          lastPage: action.payload.lastPage,
        },
      };
    }

    case actionNames.FETCH_MORE_PRODUCT_SUCCESS: {
      let obj = {
        ...state,
        hasAsyncRequest: false,
        catalog: {
          ...state.catalog,
          loading: false,
          loadingMessagesOneChat: false,
          categories: action.payload?.categories
            ? action.payload.categories
            : state.catalog.categories
            ? state.catalog.categories
            : null,
          currentPagination: action.payload.currentPagination,
          lastPage: action.payload.lastPage,
        },
      };

      if (action.payload.currentPagination == 1) {
        obj.catalog.products = action.payload.products;
      } else {
        obj.catalog.products = [
          ...state.catalog.products,
          ...action.payload.products,
        ];
      }
      return obj;
    }

    case actionNames.FETCH_CATALOG_FAILURE: {
      return {
        ...state,
        hasAsyncRequest: false,
        catalog: {
          ...state.catalog,
          loading: false,
          loadingMessagesOneChat: false,
          categories: action.payload?.categories
            ? action.payload.categories
            : state.catalog.categories
            ? state.catalog.categories
            : null,
          products: action.payload?.products
            ? action.payload.products
            : state.catalog.products
            ? state.catalog.products
            : null,
          error: 'Não carregou os dados',
        },
      };
    }

    case actionNames.FETCH_CATALOG_CATEGORIES:
      return {
        ...state,
        hasAsyncRequest: true,
        catalog: {
          ...state.catalog,
          loading: true,
          categories: null,
          error: null,
        },
      };

    case actionNames.INCOMING_CATALOG_PRODUCT_UPDATE: {
      let newProduct = action.payload;

      if (state.catalog?.products) {
        let newProductList = Object.assign({}, state.catalog).products;
        newProductList = newProductList.filter(
          (product) => product.id !== newProduct.id
        );

        if (newProduct.status) {
          newProductList.push(newProduct);
        }

        let newState = Object.assign({}, state);
        newState.catalog.products = newProductList;

        return newState;
      } else {
        return state;
      }
    }

    case actionNames.INCOMING_CATALOG_CATEGORY_UPDATE: {
      let newCategory = action.payload;

      if (state.catalog?.categories) {
        let newCategoriesList = Object.assign({}, state.catalog).categories;
        newCategoriesList = newCategoriesList.filter(
          (category) => category.id !== newCategory.id
        );

        if (newCategory.status) {
          newCategoriesList.push(newCategory);
        }

        let newState = Object.assign({}, state);
        newState.catalog.categories = newCategoriesList;

        return newState;
      } else {
        return state;
      }
    }

    case actionNames.UPDATE_SHOPPING_CART_FAILURE: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (
            chat.id == action.chat_id &&
            chat.cart &&
            chat.cart.id === action.cart_id
          ) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart.needSyncToServer = true;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CLEAR_SHOPPING_CART: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (
            chat.id == action.chat_id &&
            chat.cart &&
            chat.cart.id === action.cart_id
          ) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart = null;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.MARK_CHAT_SHOPPING_CART_SERVER_SYNCED: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (
            chat.id == action.chat_id &&
            chat.cart &&
            chat.cart.id === action.cart_id
          ) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart.needSyncToServer = false;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.UPDATE_CART_GATEWAY: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (
            chat.id == action.chatId &&
            chat.cart &&
            chat.cart.id === action.cartId
          ) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart.integration_id = action.newGatewayId;
            currentChat.cart.needSyncToServer = true;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.CHANGE_CHAT_CART: {
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);

            let products = [];
            let address = {};
            let shipping_cost = 0;
            let integration_id = null;

            if (action.data) {
              products = action.data.products ? action.data.products : [];
              address = action.data.address ? action.data.address : {};
              shipping_cost = action.data.shipping_cost
                ? action.data.shipping_cost
                : 0;
              integration_id = action.data.integration_id ?? null;
            }

            currentChat.cart = {
              type: 'order',
              products: products,
              address: address,
              shipping_cost: shipping_cost,
              integration_id,
            };
            currentChat.cart = action.data;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.ADD_ITEM_TO_CART: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);
            //verifica se existe o item no carrinho
            const productExistsInCart = currentChat.cart?.products?.find(
              (product) => product.id === action.productId
            );

            const currentAmount = productExistsInCart
              ? productExistsInCart.quantity
              : 0;

            // verificar se tem a quantidade de produto em estoque
            // retorna falso ou o produto
            let hasInStock = hasProductInStock(
              state.catalog?.products,
              action.productId,
              currentAmount
            );

            if (hasInStock) {
              if (productExistsInCart) {
                const productIndex = currentChat.cart.products.findIndex(
                  (product) => product.id === productExistsInCart.id
                );

                if (productIndex >= 0) {
                  currentChat.cart.products[productIndex].quantity =
                    productExistsInCart.quantity + 1;
                }
              } else {
                let productToAdd = {
                  id: hasInStock.id,
                  name: hasInStock.name,
                  price: hasInStock.price,
                  quantity: 1,
                };
                currentChat.cart.products = [
                  ...currentChat.cart.products,
                  productToAdd,
                ];
              }

              currentChat.cart.needSyncToServer = true;

              toast.success('Produto adicionado ao carrinho!');

              return currentChat;
            }
            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.UPDATE_AMOUNT_ITEM_FROM_CART: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || 0;
      }
      if (action.amount <= 0) return state;
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);

            let hasInStock = hasProductInStock(
              state.catalog?.products,
              action.productId,
              action.amount
            );

            if (hasInStock) {
              const productIndex = currentChat.cart.products.findIndex(
                (product) => product.id === action.productId
              );

              if (productIndex >= 0) {
                currentChat.cart.products[productIndex].quantity =
                  action.amount;

                currentChat.cart.needSyncToServer = true;
              }

              return currentChat;
            }

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.REMOVE_ITEM_FROM_CART: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart.products = currentChat.cart.products.filter(
              (product) => product.id !== action.productId
            );

            currentChat.cart.needSyncToServer = true;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.UPDATE_CART_ADDRESS: {
      if (!action.chatId) {
        action.chatId = state.chatSelectedId || 0;
      }
      return Object.assign({}, state, {
        chats: state.chats.map((chat) => {
          if (chat.id === action.chatId) {
            let currentChat = Object.assign({}, chat);

            currentChat.cart.shipping_cost = action.shipping_cost;
            currentChat.cart.address = {
              ...currentChat.cart.address,
              ...action.address,
            };

            currentChat.cart.needSyncToServer = true;

            return currentChat;
          } else {
            return chat;
          }
        }),
      });
    }

    case actionNames.DISTRIBUTE_CHAT: {
      // Configuração do laravel (adm/gestor) para notificar APENAS mensagens para eles
      const shouldPlayOnlyMySound = Boolean(
        state?.config?.user?.settings?.notifications_per_user
      );
      // ID do operador
      const chatOperatorId = action.payload.user_id;
      // Se é um gestor/supervisor
      const isManager = state?.config?.user?.superRole !== 'agent';
      // Se foi atribuido a um atendente.
      const hasOperator =
        chatOperatorId !== null && chatOperatorId !== undefined;
      let getPendences = function () {
        let pendences = [];

        if (state.pendences) {
          pendences = Object.assign([], state.pendences);
        }

        pendences.push(action.payload.contact_id);
        return pendences;
      };

      if (
        action.payload &&
        action.payload.contact_id &&
        action.payload.user_id
      ) {
        const chat_id = parseInt(action.payload.contact_id);
        let user_id = parseInt(action.payload.user_id);
        let chats = Object.assign([], state.chats);

        if (chats && Array.isArray(chats)) {
          let index = chats.findIndex((chat) => chat.id === chat_id);

          if (index >= 0) {
            // o chat já existe, precisamos apenas alterar o user_id
            if (chats[index].contact) {
              if (typeof chats[index].contact.operator != 'undefined') {
                chats[index].chat.chat_read = 0;
                chats[index].contact.operator = user_id;
                chats[index].contact.department_id =
                  action.payload.department_id;
              }
            }

            chats[index].chat.chat_read = 0;
            chats[index].chat.origin_id = user_id;
            chats[index].contact.department_id = action.payload.department_id;

            let pendences = getPendences();

            return {
              ...state,
              chats,
              pendencesLoading: false,
              pendences: pendences,
            };
          } else {
            ///é um novo chat
            let pendences = state.pendences || [];
            if (!pendences.find((id) => id == action.payload.contact_id)) {
              let shouldPlaySound = false;
              const localStorageEnabledSound =
                localStorage.getItem('@poli/notificationsEnabled') === 'true';
              // Tem que entrar o contador de novas mensagens por aqui
              pendences.push(action.payload.contact_id);
              let count_no_read = state.count_no_read || 0;
              notification.lastTime(action.payload.dtm);
              notification.updateTitle(++count_no_read);
              // Se é gestor e é um chat não atribuido (double check).
              if (isManager && !hasOperator) {
                shouldPlaySound =
                  Boolean(
                    state?.config?.general?.settings?.can_send_notifications
                  ) &&
                  localStorageEnabledSound &&
                  !shouldPlayOnlyMySound;
              }
              return {
                ...state,
                pendences,
                count_no_read,
                shouldPlaySound,
              };
            }
          }
        } else {
          return state;
        }
      } else {
        return state;
      }
    }

    /**
     * Reducer para deletar uma mensagem do instagram
     */
    case actionNames.ON_DELETED_INSTAGRAM_MESSAGE:
      if (action.payload) {
        if (state.chats.find((chat) => chat.id === action.payload.contact_id)) {
          //verifica se já existe este chat ou não
          return Object.assign({}, state, {
            lastDeletedMessage: action.payload.message_uid,
            chats: state.chats.map((chat) => {
              if (chat.id === action.payload.contact_id) {
                if (chat.messages) {
                  chat.messages = chat.messages.map((message) => {
                    if (message.uid === action.payload.message_uid) {
                      message.deleted_at = action.payload.deleted_at;
                    }
                    return message;
                  });
                }
              }
              return chat;
            }),
          });
        }
      }
      return state;

    /**
     * Remove o último atendente do chat após redirecionamento
     *  */
    case actionNames.FINISHED_LAST_USER_BY_REDIRECT: {
      let chats = [...state.chats];
      let contact_id;
      if (action?.payload && action.payload.contact.id) {
        contact_id = action.payload.contact.id;
      }
      const index = chats.findIndex((chats) => chats.contact.id === contact_id);
      if (index > -1) chats.splice(index, 1);
      return {
        ...state,
        chats,
      };
    }

    case actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_REQUEST: {
      return { ...state, currentContact: null };
    }
    case actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_FAILURE:
    case actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_SUCCESS: {
      return {
        ...state,
        currentContact: {
          contact: action.payload?.contact,
          all: action.payload?.all || false,
          cards: {
            opportunities: action.payload?.cards?.opportunities,
            tickets: action.payload?.cards?.tickets,
          },
        },
      };
    }

    case actionNames.PLAY_SOUND_NOTIFICATION: {
      return {
        ...state,
        shouldPlaySound: action.payload,
      };
    }

    case actionNames.PLAY_SOUND_NOTIFICATION: {
      return {
        ...state,
        shouldPlaySound: action.payload,
      };
    }

    default:
      return state;
  }
}
