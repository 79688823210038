import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import {
  PageContentContainer,
  PageMainContainer,
  PageMenuContainer,
} from '../../components/common/pages/PageContainer/PageContainer.styles';

import { OperatorsPerformance } from './operatorsPerformance';
import { OperatorsEfficiency } from './operatorsEfficiency';
import { laravelAxios } from 'src/config/axios/laravelAxios';
import { TypeFiltersProps } from './shared/types/Filters';
import { Welcome } from './shared/components/Welcome';
import { FiltersOptionsReport } from './filterOptionsReport';
import { ExportReport } from './exportReport';
import { CompanySummary } from './companySummary';
import { useFlags } from 'flagsmith/react';
import { customUseFlags } from 'src/hooks/customUseFlags';
import { isMobile } from 'react-device-detect';

export function Report() {
  let { path, url } = useRouteMatch();
  const pathname = window?.location?.pathname;
  const [collapsed, setCollapsed] = useState(true);
  const [isSummary, setIsSummary] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const customer_id: number = useSelector(
    // @ts-ignore
    (state) => state?.general?.current_customer_id
  );
  const [menuActive, setMenuActive] = useState<string>();
  const [filters, setFilters] = useState<TypeFiltersProps | undefined>(
    undefined
  );
  const [error, setError] = useState(false);
  const flags = useFlags(['reports_exports', 'metabase_reports']);
  const metabaseReports = customUseFlags(flags.metabase_reports);
  const renderCommonBorder = isSummary
    ? { overflow: 'auto', marginBottom: `${isMobile ? '3rem' : '0rem'}` }
    : {
        paddingRight: '2.563rem',
        paddingLeft: '0.625rem',
        overflow: 'auto',
        marginBottom: '1.875rem',
        marginLeft: '2.4375rem',
      };

  const isExportEnabled = (): boolean => {
    const current_customer_id = useSelector(
      (state: any) => state.general.current_customer_id
    );

    if (flags?.reports_exports?.enabled && flags?.reports_exports?.value) {
      const customersEnableExports = JSON.parse(
        flags.reports_exports?.value as string
      );
      return customersEnableExports.customers.includes(current_customer_id);
    }

    return false;
  };

  async function fetchFilters() {
    setError(false);

    try {
      const [departments, channels, operators, ads] = await Promise.all([
        fetchDepartments(),
        fetchChannels(),
        fetchOperators(),
        fetchAds(),
      ]);

      setFilters({
        departments,
        channels,
        operators,
        ads,
      });
    } catch (error) {
      setError(true);
    }
  }

  async function fetchDepartments() {
    const response = await laravelAxios.get('/report-v2/departments');

    return response.data;
  }

  async function fetchChannels() {
    const response = await laravelAxios.get('/report-v2/channels');

    return response.data;
  }

  async function fetchOperators() {
    const response = await laravelAxios.get('/report-v2/operators');

    return response.data;
  }

  async function fetchAds() {
    const response = await laravelAxios.get('/report-v2/ads');

    return response.data;
  }

  useEffect(() => {
    const _menuAtive = path;
    setMenuActive('');
    setMenuActive(_menuAtive);
    setError(false);
    if (pathname === '/v2-report/relatorio') {
      setIsSummary(true);
    } else {
      setIsSummary(false);
    }
  }, [pathname, path, url]);

  useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <>
      <PageMainContainer>
        {isSummary && !isMobile ? <></> : <FiltersOptionsReport />}

        <PageContentContainer style={renderCommonBorder}>
          <Switch>
            <Route exact path="/v2-report/operators-efficiency">
              <OperatorsEfficiency
                filters={filters}
                error={error}
                onError={setError}
              />
            </Route>

            <Route exact path="/v2-report/operators-performance">
              <OperatorsPerformance
                filters={filters}
                error={error}
                onError={setError}
              />
            </Route>

            <Route exact path="/v2-report/export-report">
              <ExportReport
                filters={filters}
                error={error}
                onError={setError}
              />
            </Route>
            {metabaseReports && (
              <Route exact path="/v2-report/relatorio">
                <CompanySummary />
              </Route>
            )}

            {isExportEnabled() ? (
              <Route exact path="/v2-report">
                <div style={{ marginTop: '1rem' }}>
                  <Welcome />
                </div>
              </Route>
            ) : (
              <></>
            )}
          </Switch>
        </PageContentContainer>
      </PageMainContainer>
    </>
  );
}
