import * as Sentry from '@sentry/browser';
import store from '../store';
import { isMobile } from 'react-device-detect';
import {
  distributeChat,
  storeUserSettings,
  fetchChatConfigSuccess,
  fetchChatSuccess,
  fetchChatConfigFailure,
  fetchCountNoReadsFailure,
  fetchCountNoReadsSuccess,
  markAsRead,
  fetchChatFailure,
  getProtocolSuccess,
  getProtocolFailure,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  fetchMoreMessagesSuccess,
  fetchMoreMessagesFailure,
  fetchMoreChatsSuccess,
  fetchMoreChatsFailure,
  fetchPendencesChatSuccess,
  fetchPendencesChatFailure,
  finishChatSuccess,
  finishChatFailure,
  openTransferChatModalSuccess,
  fetchUsersSuccess,
  fetchUsersFailure,
  showBlockOne,
  transferChatSuccess,
  transferChatFailure,
  receiveAck,
  receiveMessage,
  //storeReceivedChatWithMessage,
  fetchContactExternalSuccess,
  fetchAllContactsSuccess,
  fetchAllContactsFailure,
  fetchMoreContactsSuccess,
  // Icoming Contacts Event
  storeUser,
  removeUser,
  storeContact,
  removeContact,
  // Create contact events in SPA
  createContactSuccess,
  fetchUniqueChatRequest,
  createContactFailure,
  updateContactSuccess,
  updateContactFailure,
  sendMessageSuccess,
  sendMessageFailure,
  insertTagToContactSuccess,
  insertTagToContactFailure,
  storeChannel,
  updateChannel,
  deleteChannel,
  closeTagChatModal,
  // Incoming Tags Event
  incomingTagCreated,
  incomingTagDeleted,
  // Get unique chat
  fetchUniqueChat,
  fetchUniqueChatSuccess,
  fetchUniqueChatFailure,
  //removeTagFromContactSuccess,
  fetchQuickMessagesSuccess,
  fetchQuickMessagesFailure,
  createQuickMessageSuccess,
  createQuickMessageFailure,
  updateQuickMessageSuccess,
  updateQuickMessageFailure,
  createdQuickMessage,
  updatedQuickMessage,
  deletedQuickMessage,
  // Search Contacts
  contactSearchSuccess,
  contactSearchFailure,
  updateStateUploadFilesMessage,
  uploadFileMessage,
  //Send Message
  sendMessage,

  //settings
  updateSettings,

  // Onboarding WABA
  updateCustomerOnboarding,

  //merge
  mergeContactSearchFailure,
  mergeContactSearchSuccess,
  mergeContactsSuccess,
  mergeContactsFailure,
  unmergeContactsSuccess,
  unmergeContactsFailure,
  // startVisitorsServiceSuccess,
  // startVisitorsServiceFailure,
  // storeFromVisitorFailure,
  // storeFromVisitorSuccess,
  // fetchSessionsWebchat,
  // newVisitorWebchat,
  // updatedVisitorWebchat,
  //actions montagem do pagamento
  paymentChatRequestSuccess,
  paymentChatRequestFailure,
  onUserLogin,
  onUserLogout,
  //catalogo
  incomingCatalogProductUpdate,
  incomingCatalogCategoryUpdate,
  // Delete contact external
  deleteContactExternalSuccess,
  deleteContactExternalFailure,
  receiveOpenChat,
  //instagram delete message
  onDeletedInstagramMessage,
  //remove last user before redirect chat
  finishedLastUserByRedirect
} from '../store/modules/chat/actions';

import { storeUserDataOnGeneral, updateUserDataOnGeneral } from '../store/modules/general/actions';

import {
  startVisitorsServiceSuccess,
  startVisitorsServiceFailure,
  storeFromVisitorFailure,
  storeFromVisitorSuccess,
  fetchSessionsWebchat,
  newVisitorWebchat,
  updatedVisitorWebchat,
} from '../store/modules/visitors/actions';

import {
  fetchChatAlertsFailure,
  fetchChatAlertsSuccess,
} from '../store/modules/alerts/actions';

function ChatListener(message) {
  try {
    let msg = JSON.parse(message);

    if (msg) {
      switch (msg.subject) {
        // Abrir socket
        case 'client-data':
          store.dispatch(storeUserDataOnGeneral(msg.data.user));
          store.dispatch(storeUserSettings(msg.data));
          break;

        case 'chat-distribute':
          store.dispatch(distributeChat(msg.data));
          break;

        // Resposta da remoção do contact external

        case 'delete-contact-external-success':
          store.dispatch(deleteContactExternalSuccess(msg.data));
          break;

        case 'delete-contact-external-failure':
          store.dispatch(deleteContactExternalFailure(msg.data));
          break;

        // status do usuario
        case 'on-user-login-success': {
          store.dispatch(onUserLogin(msg.data));
          break;
        }
        case 'on-user-logout-success': {
          store.dispatch(onUserLogout(msg.data));
          break;
        }
        // Buscar dados de configuração
        case 'chat-config-success':
          store.dispatch(fetchChatConfigSuccess(msg.data));
          break;

        case 'chat-config-failure':
          store.dispatch(fetchChatConfigFailure(msg.data));
          break;

        // Buscar quantidade de não lidos
        case 'chat-count-no-reads-success':
          store.dispatch(fetchCountNoReadsSuccess(msg.data));
          break;

        case 'chat-read':
          store.dispatch(markAsRead(msg.data.chat_id, false));
          break;

        case 'chat-count-no-reads-failure':
          store.dispatch(fetchCountNoReadsFailure(msg.data));
          break;

        // Buscar chats
        case 'chat-fetch-chats-success':
          store.dispatch(fetchChatSuccess(msg.data, msg.data.length >= 20));
          break;

        case 'chat-fetch-chats-failure':
          store.dispatch(fetchChatFailure(msg.data));
          break;

        //Buscar o protocolo
        case 'get-protocol-success':
          store.dispatch(getProtocolSuccess(msg.data));
          break;

        case 'get-protocol-failure':
          store.dispatch(getProtocolFailure(msg.data));
          break;

        // Buscar as mensagens do chat
        case 'chat-fetch-messages-success':
          store.dispatch(fetchMessagesSuccess(msg.data));
          break;

        case 'chat-fetch-messages-failure':
          store.dispatch(fetchMessagesFailure(msg.data));
          break;

        // Buscar todos os usuários
        case 'chat-fetch-operators-success':
          store.dispatch(fetchUsersSuccess(msg.data));
          break;

        case 'chat-fetch-operators-failure':
          store.dispatch(fetchUsersFailure(msg.data));
          break;

        // Buscar mais mensagens do chat
        case 'chat-fetch-more-messages-success':
          store.dispatch(fetchMoreMessagesSuccess(msg.data));
          break;

        case 'chat-fetch-more-messages-failure':
          store.dispatch(fetchMoreMessagesFailure(msg.data));
          break;

        // Buscar mais chats
        // Retorno dos chats Pendentes tbm cai aqui
        case 'chat-fetch-more-chats-success':
          store.dispatch(
            fetchMoreChatsSuccess(msg.data, msg.data.data.length >= 20)
          );
          break;

        case 'chat-fetch-more-chats-failure':
          store.dispatch(fetchMoreChatsFailure(msg.data));
          break;

        case 'chat-fetch-pendences-success':
          store.dispatch(fetchPendencesChatSuccess(msg.data));
          break;

        case 'chat-fetch-pendences-failure':
          store.dispatch(fetchPendencesChatFailure(msg.data));
          break;
        //Remove ultimo atendente após o redirecionamento do chat
        case 'finished_last_user_by_redirect':
          store.dispatch(finishedLastUserByRedirect(msg.data));
          break;
        // Encerrar chats
        case 'chat-finish-success':
          let finalizedDataTime = msg.data.dateFinished;

          // Para testes
          // let finalizedDataTime = '2020-11-12T02:59:59.418Z';
          let dateNavigator = new Date();
          let currentGMT = dateNavigator.getTimezoneOffset() / 60;

          let finalizedData = finalizedDataTime.split('T')[0];
          let finalizedTime = finalizedDataTime.split('T')[1];
          let finalizedYear = finalizedData.split('-')[0];
          let finalizedMonth = finalizedData.split('-')[1];
          let finalizedDay = finalizedData.split('-')[2];

          let finalizedHour = String(finalizedTime.split(':')[0]);
          let finalizedMinutes = finalizedTime.split(':')[1];

          // Nos blocos abaixo, evitamos que o horário fique negativo

          // GMT = 3 -> Brasília, Uruguay, Argentina
          if (currentGMT == 3) {
            if (finalizedHour == '00') {
              finalizedHour = '21';
            } else if (finalizedHour == '01') {
              finalizedHour = '22';
            } else if (finalizedHour == '02') {
              finalizedHour = '23';
            } else if (finalizedHour == '03') {
              finalizedHour = '00';
            } else {
              finalizedHour = `${parseInt(finalizedHour) - currentGMT}`;
              if (parseInt(finalizedHour) < 10) {
                finalizedHour = `0${finalizedHour}`;
              }
            }
          }

          // GMT = 4 -> Amazonas
          if (currentGMT == 4) {
            if (finalizedHour == '00') {
              finalizedHour = '20';
            } else if (finalizedHour == '01') {
              finalizedHour = '21';
            } else if (finalizedHour == '02') {
              finalizedHour = '22';
            } else if (finalizedHour == '03') {
              finalizedHour = '23';
            } else if (finalizedHour == '04') {
              finalizedHour = '00';
            } else {
              finalizedHour = `${parseInt(finalizedHour) - currentGMT}`;
              if (parseInt(finalizedHour) < 10) {
                finalizedHour = `0${finalizedHour}`;
              }
            }
          }

          //GMT = 5 -> Acre
          if (currentGMT == 5) {
            if (finalizedHour == '00') {
              finalizedHour = '19';
            } else if (finalizedHour == '01') {
              finalizedHour = '20';
            } else if (finalizedHour == '02') {
              finalizedHour = '21';
            } else if (finalizedHour == '03') {
              finalizedHour = '22';
            } else if (finalizedHour == '04') {
              finalizedHour = '23';
            } else if (finalizedHour == '05') {
              finalizedHour = '00';
            } else {
              finalizedHour = `${parseInt(finalizedHour) - currentGMT}`;
              if (parseInt(finalizedHour) < 10) {
                finalizedHour = `0${finalizedHour}`;
              }
            }
          }

          // Mensagem customizada para log de quem fechou os chats
          // Será enviada para ser armazenada no banco de dados -> tabela de mensagens
          let newBody = `Esta conversa foi finalizada por ${msg.data.who_closed_name} em ${finalizedDay}/${finalizedMonth}/${finalizedYear} às ${finalizedHour}:${finalizedMinutes}`
          // restante do codigo a cima, foi comentado como paleativo devido ao prazo fechado,
          //${msg.data.closed_on_channel && ` no canal ${msg.data.closed_on_channel}`}`;

          let dataFinished = {
            channel_id: msg.data.channel_id,
            cuid: `system-cfw-close-${msg.data.id}`,
            message_body: newBody,
            contact_id: msg.data.id,
            user_id: msg.data.who_closed_id,
            type: 'sys_cfw_closed',
            event: 'system',
            external_id: '',
          };
          // Enviando mensagem de finalizado para salvar no banco
          store.dispatch(sendMessage(dataFinished));
          // Sucesso ao finalizar chat

          // Usei timeout para forçar esta ação executar após o recebimento da confirmação de envio da mensagem
          /* para atualizacao dinamica foi movido para case chat-receive-message
          setTimeout(() => {
            store.dispatch(finishChatSuccess(msg.data));
          }, 3000);
          */
          break;

        case 'chat-finish-failure':
          store.dispatch(finishChatFailure(msg.data));
          break;

        // Abrir modal de transferir chat
        case 'chat-open-transfer-modal-success':
          store.dispatch(openTransferChatModalSuccess(msg.data));
          break;

        case 'chat-redirect-success':
          // Ao transferir chat modifica a visualização de telas
          isMobile && store.dispatch(showBlockOne());
          store.dispatch(transferChatSuccess(msg.data));
          break;

        case 'chat-redirect-failure':
          store.dispatch(transferChatFailure(msg.data));
          break;

        case 'chat-receive-ack':
          store.dispatch(receiveAck(msg.data));
          break;

        case 'open-chat':
          store.dispatch(receiveOpenChat(msg.data));
          break;

        case 'chat-receive-message':
          store.dispatch(receiveMessage(msg.data));

          // Chat Encerrado
          if (msg.data.type === 'sys_cfw_closed') {
            store.dispatch(finishChatSuccess({ id: msg.data.contact_id }));
          }
          break;

        case 'chat-fetch-contact-external-success':
          store.dispatch(fetchContactExternalSuccess(msg.data));
          break;

        // Envia ação de gravar chat no array
        case 'chat-send-message-success':
          store.dispatch(sendMessageSuccess(msg.data));
          break;

        // Envia ação de gravar chat no array
        case 'chat-send-message-failure':
          store.dispatch(sendMessageFailure(msg.data));
          /**
           * Quando disparamos para finalizar um chat a mensagem de close vem parar neste listener
           * Portanto se tivermos uma falha nesse close, deveremos informar a action de finishChatFailure
           * */
          if (msg.data.front_cuid.match('system-cfw-close')) {
            const error =
              'O chat foi fechado sem mensagem do sistema. Verifique se o canal está conectado.';
            // console.log(error)
            store.dispatch(finishChatFailure(error));
          }
          break;

        // Recebe evento de sucesso de uma etiqueta ser atrelada à conversa
        case 'chat-insert-tag-to-contact-success':
          store.dispatch(insertTagToContactSuccess(msg.data));
          store.dispatch(closeTagChatModal());
          break;

        case 'chat-incoming-insert-tag-to-contact':
          store.dispatch(insertTagToContactSuccess(msg.data));
          break;

        // Recebe evento de falha de uma etiqueta ser atrelada à conversa
        case 'chat-insert-tag-to-contact-failure':
          store.dispatch(insertTagToContactFailure(msg.data));
          store.dispatch(closeTagChatModal());
          break;

        case 'chat-remove-tag-from-contact-success':
          //nao e necessario
          break;

        // Envia ação de gravar contatos no estado
        case 'fetch-contact-success':
          store.dispatch(fetchAllContactsSuccess(msg.data));
          break;

        case 'fetch-contact-failure':
          store.dispatch(fetchAllContactsFailure(msg.data));
          break;

        /**
         * Incoming Contacts Events
         */
        case 'chat-incoming-contact-created':
          store.dispatch(storeContact(msg.data));
          break;

        case 'chat-incoming-contact-updated':
          store.dispatch(storeContact(msg.data));
          break;

        case 'chat-incoming-contact-deleted':
          store.dispatch(removeContact(msg.data));
          break;

        case 'chat-incoming-contact-restored':
          store.dispatch(storeContact(msg.data));
          break;

        /**
         * Incoming User Events
         */
        case 'chat-incoming-user-created':
          store.dispatch(storeUser(msg.data));
          break;

        case 'chat-incoming-user-updated':
          store.dispatch(storeUser(msg.data));
          store.dispatch(updateUserDataOnGeneral(msg.data));
          break;

        case 'chat-incoming-user-deleted':
          store.dispatch(removeUser(msg.data));
          break;

        case 'chat-incoming-user-restored':
          store.dispatch(storeUser(msg.data));
          break;

        /**
         * Incoming Tags Events
         */
        case 'chat-incoming-tag-created':
          store.dispatch(incomingTagCreated(msg.data));
          break;

        case 'chat-incoming-tag-updated':
          store.dispatch(incomingTagCreated(msg.data));
          break;
        case 'chat-incoming-tag-deleted':
          store.dispatch(incomingTagDeleted(msg.data));
          break;

        /**
         * Incoming Channel Events
         * @todo crud
         */
        case 'chat-incoming-channel-created':
          store.dispatch(storeChannel(msg.data));
          break;
        case 'chat-incoming-channel-updated':
          store.dispatch(updateChannel(msg.data));
          break;
        case 'chat-incoming-channel-deleted':
          store.dispatch(deleteChannel(msg.data));
          break;

        /**
         * Ações disparadas pela SPA
         */

        // Buscar mais contatos
        case 'contact-fetch-more-contacts-success':
          store.dispatch(fetchMoreContactsSuccess(msg.data));
          break;

        case 'contact-fetch-more-contacts-failure':
          // store.dispatch(fetchMoreContactsFailure(msg.data));
          break;

        // Ação com o retorno da criação de um contato
        case 'create-contact-success':
          store.dispatch(createContactSuccess(msg.data));
          // Ação para buscar chat no banco e abrí-lo
          store.dispatch(fetchUniqueChatRequest(msg.data.id));
          break;

        case 'create-contact-failure':
          store.dispatch(createContactFailure(msg.data));
          break;

        // Ação com o retorno da atualização de um contato
        case 'update-contact-success':
          store.dispatch(updateContactSuccess(msg.data));
          break;

        case 'update-contact-failure':
          store.dispatch(updateContactFailure(msg.data));
          break;

        // Ação com retorno de um único chat
        case 'chat-fetch-chat':
          store.dispatch(fetchUniqueChat(msg.data));
          break;
        case 'chat-fetch-chat-success':
          store.dispatch(fetchUniqueChatSuccess(msg.data));
          break;
        case 'chat-fetch-chat-failure':
          store.dispatch(fetchUniqueChatFailure(msg.data));
          break;
        // Buscar mensagens rapidas
        case 'chat-fetch-quick-messages-success':
          store.dispatch(fetchQuickMessagesSuccess(msg.data));
          break;
        // Buscar mensagens rapidas
        case 'chat-fetch-quick-messages-failure':
          store.dispatch(fetchQuickMessagesFailure(msg.data));
          break;

        case 'chat-update-quick-message-success':
          store.dispatch(updateQuickMessageSuccess(msg.data));
          break;
        case 'chat-update-quick-message-failure':
          store.dispatch(updateQuickMessageFailure(msg.data));
          break;
        case 'chat-create-quick-message-success':
          store.dispatch(createQuickMessageSuccess(msg.data));
          break;
        case 'chat-create-quick-message-failure':
          store.dispatch(createQuickMessageFailure(msg.data));
          break;
        case 'created-quick-message':
          store.dispatch(createdQuickMessage(msg.data));
          break;
        case 'updated-quick-message':
          store.dispatch(updatedQuickMessage(msg.data));
          break;
        case 'deleted-quick-message':
          store.dispatch(deletedQuickMessage(msg.data));
          break;
        case 'search-contact-success':
          store.dispatch(contactSearchSuccess(msg.data));
          break;
        case 'search-contact-failure':
          store.dispatch(contactSearchFailure(msg.data));
          break;
        case 'update-settings':
          store.dispatch(updateSettings(msg.data));
          break;
        case 'update_customer_onboarding':
          store.dispatch(updateCustomerOnboarding(msg.data));
          break;
        case 'get-s3-signed-url-file-message-success':
          try {
            let signedData = msg.data;
            if (
              signedData.chat_id &&
              signedData.files &&
              signedData.files.length
            ) {
              //chama o saga para fazer o upload
              for (let i = 0; i < signedData.files.length; i++) {
                let param = {
                  chat_id: signedData.chat_id,
                  file_id: signedData.files[i].id,
                  destination: signedData.files[i].signed_url_data,
                };
                store.dispatch(uploadFileMessage(param));
              }
            }
          } catch (_) {
            // console.log('error on handle signed url response');
          }
          break;
        case 'get-s3-signed-url-file-message-failure':
          try {
            let signedData = msg.data;
            if (
              signedData.chat_id &&
              signedData.files &&
              signedData.files.length
            ) {
              store.dispatch(updateStateUploadFilesMessage(signedData));
            }
          } catch (_) {
            // console.log('error on handle signed url response', e);
          }
          break;
        case 'chat-merge-contact-search-failure':
          store.dispatch(mergeContactSearchFailure(msg.data));
          break;
        case 'chat-merge-contact-search-success':
          store.dispatch(mergeContactSearchSuccess(msg.data));
          break;
        case 'chat-merge-contact-failure':
          store.dispatch(mergeContactsFailure(msg.data));
          break;
        case 'chat-merge-contact-success':
          store.dispatch(mergeContactsSuccess(msg.data));
          break;
        case 'chat-unmerge-contact-success':
          store.dispatch(unmergeContactsSuccess(msg.data));
          break;
        case 'chat-unmerge-contact-failure':
          store.dispatch(unmergeContactsFailure(msg.data));
          break;
        /**
         * Webchat/Visitors listeners
         */
        case 'chat-fetch-sessions-webchat-success':
          store.dispatch(startVisitorsServiceSuccess(msg.data));
          break;
        case 'chat-fetch-sessions-webchat-failure':
          store.dispatch(startVisitorsServiceFailure(msg.data));
          break;
        case 'chat-store-from-visitor-failure':
          store.dispatch(storeFromVisitorFailure(msg.data));
          break;
        case 'chat-store-from-visitor-success':
          store.dispatch(storeFromVisitorSuccess(msg.data));
          break;
        case 'chat-fetch-sessions-webchat':
          store.dispatch(fetchSessionsWebchat(msg.data));
          break;
        case 'chat-new-visitor-webchat':
          store.dispatch(newVisitorWebchat(msg.data));
          break;
        case 'chat-updated-visitor-webchat':
          store.dispatch(updatedVisitorWebchat(msg.data));
          break;
        /**
         * Payment and Catalog listeners
         */
        case 'payment-chat-request-success':
          store.dispatch(paymentChatRequestSuccess(msg.data));
          break;
        case 'payment-chat-request-failure':
          store.dispatch(paymentChatRequestFailure(msg.data));
          break;
        case 'catalog-chat-incoming-product-created':
          store.dispatch(incomingCatalogProductUpdate(msg.data));
          break;
        case 'catalog-chat-incoming-product-updated':
          store.dispatch(incomingCatalogProductUpdate(msg.data));
          break;
        case 'catalog-chat-incoming-category-created':
          store.dispatch(incomingCatalogCategoryUpdate(msg.data));
          break;
        case 'catalog-chat-incoming-category-updated':
          store.dispatch(incomingCatalogCategoryUpdate(msg.data));
          break;
        case 'on_deleted_instagram_message':
          store.dispatch(onDeletedInstagramMessage(msg.data));
          break;

        // Alertas da tela de chat
        case 'fetch-chat-alerts-success':
          store.dispatch(fetchChatAlertsSuccess(msg.data));
          break;
        case 'fetch-chat-alerts-failure':
          store.dispatch(fetchChatAlertsFailure(msg.data));
          break;

        default:
          break;
      }
    }
  } catch (_) {
    // console.error(e);
  }
}

export default ChatListener;
