import React from 'react';
import { Link } from 'react-router-dom';
import { Icon }from '@polichat/flamboyant';
import { useSelector } from 'react-redux';
import poliConfig from 'src/utils/poliConfig';

import { connect } from 'react-redux';

function SidebarItem({props, userConfig}) {
  const current_customer_id = useSelector((state) => state.general.current_customer_id);

  const handleClick = () => {
    if(props.url == '/home'){
       const url = `${poliConfig.poliAppLaravelUrl}${props.url}`;
       window.location.assign(url)
    } else if (props.url == '/shopping' || props.url == '/tools' || props.url == '/ads' || props.url == '/chat' || props.url == '/v2-report' || props.url == '/automation') {
      window.location.assign(props.url)
    } else {
      const url = `${poliConfig.poliAppLaravelUrl}${props.url}/${current_customer_id}`;
      window.location.assign(url);
    }
  };

  const userSuperRole = userConfig?.superRole;
  const active = props.active;
  let style = styles.container;
  let iconStyle = styles.icon;
  let className = 'sidebarMenuItem';
  if (active) {
    className = 'sidebarMenuItem active';
    style = {
      ...style,
      color: 'var(--activated-icons-color)',
      backgroundColor: 'var(--icon-sidebar-background)',
      borderLeft: '0.25em solid var(--green-polichat)',
    };

    iconStyle = { ...iconStyle, transform: 'matrix(-1, 0, 0, 1, 0, 0)' };
  }

  if (userConfig && (userSuperRole === 'adm' || userSuperRole === 'manager')) {
    return (
      <div style={{ textDecoration: 'none' }} onClick={handleClick}>
        <div style={style} className={className}>
          <Icon icon={props.icon} style={iconStyle} />
          <span>{props.title}</span>
        </div>
      </div>
    );
  }
  else {
    if (props.url === '/' || props.url === '/chat') {
      return (
        <Link to={props.url} style={{ textDecoration: 'none' }}>
          <div style={style} className={className}>
            <Icon icon={props.icon} style={iconStyle} />
            <span>{props.title}</span>
          </div>
        </Link>
      )
    } else {
      return <></>
    }
  }

}

const styles = {};

const mapStateToProps = (state, ownProps) => ({
  userConfig: state?.general?.user,
  props: ownProps,
});

export default connect(mapStateToProps)(SidebarItem);
