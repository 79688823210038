import {
  Button,
  CustomInput,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Label,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import Animation from 'src/components/common/animation';
import { CustomerContactCreditContext } from 'src/context/CustomerContactCredit/context';

import { useSelector } from 'react-redux';
import {
  getMonthAndNextMont,
  separateCreditsByType,
  updateCredits,
} from './helpers';

import { Controller } from 'react-hook-form';
import history from 'src/services/history';
import { Credits } from './helpers/types';
import './index.css';
import { useLocalForm } from './useLocalForm';

interface MonthDate {
  month: string;
  nextMonth: string;
}

export function ContactCredits() {
  const ADM_MANAGER_ROLES = ['adm', 'manager'];

  const [date, setDate] = useState<MonthDate>();
  const [credits, setCredits] = useState<Credits>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<any>();

  const form = useLocalForm();
  const selectedItem = form.watchedValue.credit_id;

  const channels_settings = useSelector(
    //@ts-ignore
    (state) => state?.general?.user?.channels
  );
  const userIsAdminOrManager = useSelector((state) => {
    //@ts-ignore
    let userRole = state?.general?.user?.superRole;
    if (!userRole) return false;
    return ADM_MANAGER_ROLES.indexOf(userRole) >= 0;
  });
  const isWl = useSelector(
    //@ts-ignore
    (state) => state?.whitelabel?.config?.is_white_label
  );

  const context = useContext(CustomerContactCreditContext);

  function handleClickOnDropDown() {
    if (!isOpenDropdown) {
      context.refresh();
    }
  }

  function handleBuyCredits() {
    handleToggleDropdown();
    history.push({
      pathname: '/tools/credit',
    });
  }

  const handleToggleDropdown = useCallback(() => {
    setIsOpenDropdown((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!date) {
      setDate(getMonthAndNextMont());
    }
  }, []);

  useEffect(() => {
    if (
      context.data &&
      Object.keys(context.data).length &&
      channels_settings?.length
    ) {
      if (!credits) {
        setCredits(separateCreditsByType(context.data, channels_settings));
      } else {
        setCredits((current) => updateCredits(context.data, current));
      }
    }
  }, [context.data, setCredits]);

  useEffect(() => {
    if (!credits) return;

    // setar pela primeira vez
    if (selectedItem === '') {
      if (credits.wabaGroups?.length != 0) {
        form.setValue('channel_credit_id', credits.wabaGroups[0]?.id);
        setSelectedChannel({
          ...credits.wabaGroups[0],
          name: 'Conta Waba',
          icon: {
            name: 'poli-icon pi-whatsapp-business-fill',
            color: 'var(--whatsapp-color)',
          },
        });
      } else {
        form.setValue('channel_credit_id', 'detached');
        setSelectedChannel({
          ...credits?.detached,
          name: 'Créditos adquiridos',
          icon: {
            name: 'poli-icon pi-pay-line',
            color: 'var(--primary)',
          },
        });
      }
    } else {
      // atualiza item selecionado
      if (selectedItem === 'detached') {
        setSelectedChannel(credits?.detached);
        return;
      }

      if (!!Number(selectedItem) && credits?.regular.length != 0) {
        setSelectedChannel(
          credits?.regular.find(
            (channel) => Number(channel.id) === Number(selectedItem)
          )
        );
      } else {
        setSelectedChannel(
          credits?.wabaGroups.find((channel) => channel.id === selectedItem)
        );
      }
    }
  }, [credits, selectedItem, channels_settings]);

  return (
    <Fragment>
      <Dropdown
        isOpen={isOpenDropdown}
        toggle={handleToggleDropdown}
        className="visitorsNotification"
        color={'neutral'}
        disabled={context.loading}
      >
        <DropdownToggle
          color={'neutral'}
          border="border-none"
          id="tooltipContactCredits"
          caret
          onClick={handleClickOnDropDown}
        >
          <UncontrolledTooltip
            placement="bottom"
            target="tooltipContactCredits"
          >
            Saldo de Créditos para consumo no mês.
          </UncontrolledTooltip>

          {context.loading && !selectedItem ? (
            <div className="credits_loading">
              <span>Carregando</span>
              <Animation icon="loading" size={14} />
            </div>
          ) : (
            <div className="credits_selected_container">
              <Icon
                icon={
                  selectedChannel?.icon?.name
                    ? selectedChannel?.icon?.name
                    : 'poli-icon pi-whatsapp-fill'
                }
                size={22}
                color={
                  selectedChannel?.icon?.color
                    ? selectedChannel?.icon?.color
                    : 'var(--primary)'
                }
              />
              <div className="credits_selected">
                <span className="credits_selected_title">
                  {selectedChannel?.name}
                </span>
                <p className="credits_selected_values">
                  <span
                    className={`credits_value ${
                      selectedChannel?.color
                        ? `credits_value_${selectedChannel?.color}`
                        : ''
                    }`}
                  >
                    {selectedChannel?.used}
                  </span>
                  /<span>{selectedChannel?.total}</span> Créditos
                </p>
              </div>
              <Icon icon="poli-icon pi-simple-arrow-down-line" />
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu className={`plateBox${isWl ? 'Wl' : ''}`} style={{ width: isWl ? '300px' : '232px' }}>
          {/* Header */}
          <div className="selectorVisitorBoxHeader plateBoxHeader">
            <p
              className="plateBoxName credits_menu_title"
              style={{
                fontSize: '1.2rem',
                width: '-webkit-fill-available',
                margin: '0px 0px 10px 0px !important',
              }}
            >
              Créditos
            </p>
            <p
              className="plateBoxUpLine credits_menu_date"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '-webkit-fill-available',
                margin: '0',
              }}
            >
              <span>{date?.month ?? ''}</span>
              <br />
              <span style={{ fontSize: '0.7rem', textAlign: 'right' }}>
                Créditos serão renovados em 01/
                {date?.nextMonth ?? ''}
              </span>
            </p>
          </div>
          {/* Itens */}
          <div className="selectorVisitorBoxBody plateBoxBody">
            {context.loading && (
              <div className="credits_loading">
                <span>Carregando</span>
                <Animation icon="loading" size={14} />
              </div>
            )}

            <ul>
              {/* grupos waba */}
              {!context.loading && credits?.wabaGroups.length != 0 && (
                <Fragment>
                  <Controller
                    control={form.control}
                    name="channel_credit_id"
                    render={({ field }) => (
                      <Fragment>
                        {credits?.wabaGroups?.map((wabagroup) => (
                          <li
                            key={`${wabagroup.id}`}
                            className="credits_waba_container"
                          >
                            <div className="credits_selected_container">
                              <div className="credits_channel_info">
                                <CustomInput
                                  type="radio"
                                  id={`${wabagroup.id}`}
                                  value={wabagroup.id}
                                  onChange={field.onChange}
                                  checked={field.value === String(wabagroup.id)}
                                />
                                <Icon
                                  size={25}
                                  icon={'poli-icon pi-whatsapp-business-fill'}
                                  color={'var(--whatsapp-color)'}
                                />
                                <Label for={`${wabagroup.id}`}>
                                  Conta WABA
                                </Label>
                              </div>

                              <div className="credits_selected">
                                <span>Saldo</span>
                                <p className="credits_selected_values">
                                  <span
                                    className={`credits_value ${
                                      wabagroup?.color
                                        ? `credits_value_${wabagroup?.color}`
                                        : ''
                                    }`}
                                  >
                                    {wabagroup.used}
                                  </span>
                                  {'/'}
                                  <span>{wabagroup.total}</span>
                                </p>
                              </div>
                            </div>
                            <ul className="credits_waba_channels">
                              {wabagroup.channels?.map((wabachannel) => (
                                <li key={wabachannel.id}>
                                  <span>{wabachannel.name}</span>
                                  <p className="credits_selected_values">
                                    Consumo:{' '}
                                    <span
                                      className={`credits_value ${
                                        wabagroup?.color
                                          ? `credits_value_${wabagroup?.color}`
                                          : ''
                                      }`}
                                    >
                                      {wabachannel.used}
                                    </span>
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </Fragment>
                    )}
                  />
                </Fragment>
              )}

              {/* canais gerais */}
              {!context.loading && credits?.regular?.length != 0 && !isWl && (
                <Fragment>
                  <Controller
                    control={form.control}
                    name="channel_credit_id"
                    render={({ field }) => (
                      <Fragment>
                        {credits?.regular?.map((channel) => (
                          <li
                            className="credits_selected_container"
                            key={`${channel.id}`}
                          >
                            <div className="credits_channel_info">
                              <CustomInput
                                type="radio"
                                id={`regular-${channel.id}`}
                                onChange={field.onChange}
                                value={channel.id}
                                checked={field.value === String(channel.id)}
                              />
                              <Icon
                                size={25}
                                icon={'poli-icon pi-whatsapp-fill'}
                                color={'var(--whatsapp-color)'}
                              />
                              <Label for={`regular-${channel.id}`}>
                                {channel.name}
                              </Label>
                            </div>
                            <div className="credits_selected">
                              <span>Saldo</span>
                              <p className="credits_selected_values">
                                <span
                                  className={`credits_value ${
                                    channel?.color
                                      ? `credits_value_${channel?.color}`
                                      : ''
                                  }`}
                                >
                                  {channel.used}
                                </span>
                                {'/'}
                                <span>{channel.total}</span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </Fragment>
                    )}
                  />
                </Fragment>
              )}

              {/* creditos avulsos */}
              {!context.loading && credits?.detached && (
                <Fragment>
                  <Controller
                    control={form.control}
                    name="channel_credit_id"
                    render={({ field }) => (
                      <Fragment>
                        <li
                          className="credits_selected_container"
                          key={'detached-item'}
                        >
                          <div className="credits_channel_info">
                            <CustomInput
                              type="radio"
                              id={'detached-radio-item'}
                              value={'detached'}
                              onChange={field.onChange}
                              checked={field.value === 'detached'}
                            />

                            <Icon
                              size={25}
                              icon="poli-icon pi-pay-line"
                              color={'var(--primary)'}
                            />
                            <div>
                              <Label for={'detached-radio-item'}>
                                Créditos adquiridos
                              </Label>
                            </div>
                          </div>
                          <div className="credits_selected">
                            <span>Saldo</span>
                            <p className="credits_selected_values">
                              <span
                                className={`credits_value ${
                                  credits.detached?.color
                                    ? `credits_value_${credits.detached?.color}`
                                    : ''
                                }`}
                              >
                                {credits?.detached.used}
                              </span>
                              {'/'}
                              <span>{credits?.detached.total}</span>
                            </p>
                          </div>
                        </li>
                      </Fragment>
                    )}
                  />
                </Fragment>
              )}
            </ul>
          </div>
          {/* Buy credits button */}
          {userIsAdminOrManager && !isWl && (
            <div className="credits_footer">
              <Button width="total" color="primary" onClick={handleBuyCredits}>
                Comprar crédito
              </Button>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
}
