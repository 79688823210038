import * as Sentry from '@sentry/browser';
import { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { ReactTinyLink } from 'react-tiny-link';
import scheduleMessage from './scheduleMessage';
import './task.css';
import TaskReview from './TaskReview';
import TaskSecondStep from './taskSecondStep';

import {
  Alert,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import poliApiGatewayRequest from '../../../../utils/poliApiGatewayRequest';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

import setBgColorMessages from '../../../../utils/backgroundColorMessages';

import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { mountMessage } from '../../../../store/modules/chat/functions';
import ButtonRecordAudio from './audio/ButtonRecordAudio';
import RecordAudioBase64 from './audio/RecordAudioBase64';

import RecorderMP3WhitLamejs from './audio/recorders/RecorderMP3WhitLamejs';
import RecorderMP3WhitVMSG from './audio/recorders/RecorderMP3WhitVMSG';

import ChannelSelector from './ChannelSelector';
import ChannelSelectorNew from './ChannelSelectorNew';
import ContactExternalSelector from './ContactExternalSelector';
import PreviewThumbnail from './preview/previewThumbnail/index.tsx';
import {
  QuickMessages,
  QuickMessageSelector,
} from './quickMessages/QuickMessages';

import Audio from '../../message-types/audio/Audio.component';
import CartPanel from './cart/CartPanel';
import CatalogCarousel from './catalog/CatalogCarousel';
import CartImage from './modals/src/CartImage';
import PayImage from './modals/src/PayImage';

import { getMessageBuilderTemplateText } from './quickMessages/functions/getMessageBuilderTemplateText';

import { useFlags } from 'flagsmith/react';
import { useWhiteLabel } from '../../../../hooks/useWhiteLabel';
import { isJson } from '../../../../utils/isJson';
import poliConfig from '../../../../utils/poliConfig';
import CustomBox from '../../../common/structure/header/CustomBox';
import { CustomerChannelBalanceContext } from '../contexts/customerChannelBalance/context';
import { CustomerContactDayAlert } from './customerContactDayAlert';

// Quick Message Selector - componente refatorado
import { QuickMessageSelectorV2 } from './quickMessages/v2';
import { useQuickMessageSelectorFlag } from './quickMessages/v2/hooks/useFlag';
import { useGetQuickMessageFromShortcut } from './quickMessages/v2/hooks/useGetQuickMessageFromShortcut';
import { KeyboardCodes } from './quickMessages/v2/types/constants';
import { useScheduleMessage } from './useScheduleMessageFlag';

function TextBox({
  chat,
  opennedChat,
  addMessage,
  sendMessage,
  getProtocol,
  updateMessageTextArea,
  updateChatDropzone,
  toggleQuickMessagesSelector,
  navQuickMessagesSelector,
  searchQuickMessages,
  clearReplyingMessage,
  openCatalogCarousel,
  closeCatalogCarousel,
}) {
  QuickMessages.setState(chat);

  const TEAM_LINK =
    'https://api.whatsapp.com/send/?phone=556299800123&text=Quero+conhecer+o+Poli+Flow%21&type=phone_number&app_absent=0';

  const [emojiPickerState, setEmojiPicker] = useState(false);
  const currentUser = chat?.config?.user;
  // const oldOpennedChatId = opennedChat?.chat?.id;

  const [isZapiOrWebchat, setIsZapiOrWebchat] = useState(false);

  const chatOwner = opennedChat?.chat?.origin_id;

  const quickMessagesIsOpen = chat.quickMessages?.isOpen;

  const showCatalogCarousel = opennedChat.catalog_carousel_show;

  const [recordingAudio, recordingAudioToggle] = useState(false);

  const [currentText, setCurrentText] = useState('');
  const [opennedChatId, setOpennedChatId] = useState('');
  const [currentQuickMessageId, setCurrentQuickMessageId] = useState(null);
  const [lastTimeout, setLastTimeout] = useState(0); //timeout para verificar se o usuario esta ou nao digitando
  const [sentMsg, setSentMsg] = useState(false);

  const [fontStylesSwitch, setFontStylesSwitch] = useState(false);

  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [isButtonsMessageSelected, setIsButtonsMessageSelected] =
    useState(false);
  const [isTemplateListSelected, setIsTemplateListSelected] = useState(false);
  const [isProcessingAudio, setIsProcessingAudio] = useState(false);

  const [showSchedulingForm, setShowSchedulingForm] = useState(false);
  const [step, setStep] = useState(0); // 0: Nenhuma etapa, 1: Seleção de data/hora, 2: Revisão
  const [scheduleData, setScheduleData] = useState({
    date: '',
    time: '',
  });

  const [channelId, setChannelId] = useState(
    opennedChat?.view?.selectedChannel?.id || opennedChat?.chat?.channel_id
  );

  const { scheduleMessageFlag } = useScheduleMessage();

  const channels = chat?.config?.general?.channels;
  const settings = chat?.config?.general?.settings;

  const canChat = canSendMessage();

  const showCatalog = chat?.catalog; // && chat?.config?.general?.settings?.catalog_service;
  const showCart = opennedChat?.cart_option_show;
  const isMessageBuilder = isTemplateSelected | isTemplateListSelected | isButtonsMessageSelected;

  const [urlPreview, setUrlPreview] = useState();
  const [mediaFormat, setMediaFormat] = useState();
  const [headerPreview, setHeaderPreview] = useState();
  const [bodyPreview, setBodyPreview] = useState();
  const [footerPreview, setFooterPreview] = useState();
  const [isReceptiveActive, setIsReceptiveActive] = useState(true);

  const [canOpenActiveChat, setCanOpenActiveChat] = useState(
    permissionsToOpenActiveChat(
      isReceptiveActive,
      hasRolePermissionToStartActiveChat,
      activeChatSetting
    )
  );

  const [blockSendMessageButton, setBlockSendMessageButton] = useState(
    validatePermissionToDisableSendMessageButton()
  );

  const [
    validationPermissionSendTextArea,
    setValidationPermissionSendTextArea,
  ] = useState(validationPermissionTextArea());

  const flags = useFlags([
    'audio_refactor',
    'audio_recorder_v1',
    'audio_logs',
    'new_channel_selector',
  ]);
  //feature flag audio refactor
  const current_customer_id = useSelector(
    (state) => state.general.current_customer_id
  );
  const [isCustomerIncludedNewRecorder, setIsCustomerIncludedNewRecorder] =
    useState(false);

  useEffect(() => {
    if (flags.audio_refactor.enabled && flags.audio_refactor.value) {
      let audioRefactorValues = JSON.parse(flags.audio_refactor.value);
      let customersEnabledToUseNewRecoder = audioRefactorValues.customers;

      setIsCustomerIncludedNewRecorder(
        customersEnabledToUseNewRecoder.includes(current_customer_id)
      );
    }

    if (flags.audio_recorder_v1?.enabled && flags.audio_recorder_v1?.value) {
      let audioV1RecorderValues = JSON.parse(flags.audio_recorder_v1?.value);
      let customersEnabledToUseRecorderV1 = audioV1RecorderValues.customers;

      if (!customersEnabledToUseRecorderV1.length) {
        setIsCustomerIncludedNewRecorder(true);
      } else {
        setIsCustomerIncludedNewRecorder(
          customersEnabledToUseRecorderV1.includes(current_customer_id)
        );
      }
    }
  }, [flags]);

  const enabledActiveChatFlag = flags?.message_active?.enabled || false;
  const isWhiteLabel = useSelector(
    (state) => state?.whitelabel?.config?.is_white_label
  );

  const whiteLabelPartner = useSelector((state) => {
    return state?.whitelabel.config?.is_white_label;
  });
  const customerHasPoliFlow = useSelector((state) => {
    return state.chat?.config?.general?.crm?.permissions?.customerPermission;
  });

  const userHasPoliFlow = useSelector((state) => {
    return state.chat?.config?.general?.crm?.permissions?.userPermission;
  });

  const isWL = useWhiteLabel().isWl;

  const getUserRole = useSelector(
    (state) => state.chat?.config?.user?.superRole
  );

  const activeChatSetting = useSelector(
    /**
     * @param {*} state
     * @returns boolean
     * o valor de active na tabela settings
     * está no redux em state.chat?.config?.general?.settings?.active
     *
     * 0: desabilitado => operador e supervisor NÃO pode fazer chat ativo
     * 1: habilitado => operador e supervisor pode fazer chat ativo
     */
    (state) => state.chat?.config?.general?.settings?.active != 0
  );

  const hasRolePermissionToStartActiveChat = ['adm', 'manager'].includes(
    getUserRole
  );

  const showCustomBoxWL = useSelector((state) => {
    return (
      state?.whitelabel?.config?.script_injection &&
      state?.whitelabel?.config?.script_injection !== ''
    );
  });

  const { showQuickMessageSelectorV2 } = useQuickMessageSelectorFlag();
  const { findQuickMessageWithShortcut } = useGetQuickMessageFromShortcut();

  const receiveSelectedQuickMessage = useCallback(
    (quickMessage) => {
      updateText(
        quickMessage.text, //texto da mensagem
        quickMessage.id, // id da quick_quickMessage
        quickMessage.template && quickMessage.validated === 1, // é template válido,
        quickMessage.template_name?.includes('list'), // é mensagem tipo lista
        quickMessage.template_name?.includes('buttons') // é mensagem tipo buttons
      );
      closeQuickMessageSelectorAndClearSearchTerm();
    },
    [quickMessagesIsOpen]
  );

  useEffect(() => {
    if (currentText?.length > 2) {
      const _url = linkify(currentText);
      if (isJson(currentText)) {
        const _templateObj =
          _url &&
          (isTemplateSelected ||
            isTemplateListSelected ||
            isButtonsMessageSelected) &&
          JSON.parse(currentText);
        const _mediaFormat = _templateObj && _templateObj.header.mediaFormat;
        const _header = _templateObj && _templateObj.header.text;
        const _body = _templateObj && _templateObj.body;
        const _footer = _templateObj && _templateObj.footer;
        setUrlPreview(_url);
        setHeaderPreview(_header);
        setBodyPreview(_body);
        setFooterPreview(_footer);
        setMediaFormat(_mediaFormat);
      } else {
        setBodyPreview(currentText);
      }
    } else {
      setUrlPreview(undefined);
    }
  }, [currentText]);

  useEffect(() => {
    let chatChannelId = opennedChat?.view?.selectedChannel?.id;
    if (!chatChannelId) {
      chatChannelId = opennedChat?.chat?.channel_id;
    }
    if (chatChannelId && chatChannelId !== channelId) {
      setChannelId(chatChannelId);
    }
    setStep(0);
  }, [
    currentQuickMessageId,
    opennedChat?.chat?.id,
    opennedChat?.view?.selectedChannel?.id,
    opennedChat?.chat?.channel_id,
    channelId,
  ]);

  // TODO: option usar o ApiList do src/enums/channels.js
  const channelApiTemplate = {
    waba: 33,
    BSP_Poli: 10,
    BSP_360: 34,
  };

  /**
   * TODO: sugestão
   * mudar o nome da variavel
   * colocar essa variavel como useState
   * criar um useEffect para escutar o oppenedChat.api para setar o valor do estado
   * a função que avalia esse valor da api protegida por useCallback
   *  */
  const isTemplateApi =
    opennedChat?.view?.selectedChannel?.api === channelApiTemplate?.waba ||
    opennedChat?.view?.selectedChannel?.api === channelApiTemplate?.BSP_360 ||
    opennedChat?.view?.selectedChannel?.api === channelApiTemplate?.BSP_Poli;

  const [mediaLink, setMediaLink] = useState('');
  const [modalOpenPhoto, setModalOpenPhoto] = useState(false);

  useEffect(() => {
    if (urlPreview) {
      const _mediaLink = `${urlPreview}`?.split(',')[0]?.replace('"', '');
      setMediaLink(_mediaLink);
    }
  }, [urlPreview]);

  const handleClickPreviewThumb = useCallback(() => {
    setModalOpenPhoto(!modalOpenPhoto);
  }, []);

  const linkify = useCallback((text) => {
    try {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      let urlWithProtocol = undefined;

      if (text?.includes('.')) {
        text
          .replace(urlRegex, function (url) {
            url = url.trim();
            urlWithProtocol = /(http|https|Http|Https|rtsp|Rtsp)/.test(url)
              ? url
              : 'http://' + url;
          })
          .trim();
      }

      const isUrl = new URL(urlWithProtocol);
      if (isUrl) {
        return urlWithProtocol;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }, []);

  const { isUserAbleToSendMessage, loading: isLoadingCustomerChannelContext } =
    useContext(CustomerChannelBalanceContext);

  // Atualiza o texto e atualiza o state algum momento depois
  const updateText = useCallback(
    (text, quick_message_id, isTemplate, isTemplateList, isButtonsMessage) => {
      setSentMsg(false);
      setCurrentText(text);
      setCurrentQuickMessageId(quick_message_id);
      checkTyping(text);
      setIsTemplateSelected(isTemplate);
      setIsTemplateListSelected(isTemplateList);
      setIsButtonsMessageSelected(isButtonsMessage);
    },
    []
  );

  const setFocusOnInputText = useCallback((e) => {
    if (!isMobile && document.getElementById('textAreaMessage')) {
      if (chatOwner && currentUser) {
        if (chatOwner === currentUser.id) {
          document.getElementById('textAreaMessage').focus();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (opennedChat?.chat?.id) {
      getProtocol(opennedChat?.id);
      setFocusOnInputText();
      // Usado para saber o Id do Chat Atual
      setOpennedChatId(opennedChat?.chat?.id);

      // Corrigindo o bug catalogo ficar aberto em outro chat ou some caixa de texto se catalogo do chat anterior tiver aberto
      if (showCatalog && showCatalogCarousel) {
        handleOpenCatalog();
      } else {
        handleCloseCatalog();
      }
    }
  }, [opennedChatId]);

  useEffect(() => {
    <CartPanel />;
  }, [showCart]);

  useEffect(() => {
    if (opennedChat.replying_message) setFocusOnInputText();
  }, [opennedChat.replying_message]);

  /**
   * Logica de Janela 24 horas do facebook
   */
  const [alert_timer_to_finish, setAlertTimerToFinish] = useState('');
  useEffect(() => {
    const selectedChannelType = opennedChat?.view?.selectedChannel?.type;
    const selectedChannelId = opennedChat?.view?.selectedChannel?.id ?? '';

    if (['facebook', 'instagram', 'waba'].includes(selectedChannelType)) {
      const check = () => {
        if (opennedChat.loadMessages) {
          // Pegando ultima mensagem do canal selecionado
          const _lastReceivedMsg =
            opennedChat?.last_message_received[selectedChannelType];

          // Se for waba pegar ultima mensagem do channel_id selecionado
          const lastReceivedMessage =
            selectedChannelType === 'waba' && _lastReceivedMsg
              ? _lastReceivedMsg[selectedChannelId]
              : _lastReceivedMsg;

          let is_active = true;
          const exist_template =
            opennedChat?.view?.selectedChannel?.type !== 'facebook';

          if (!lastReceivedMessage) {
            is_active = false;
            if (exist_template && !chat?.quickMessages?.isOpen) {
              toggleQuickMessagesSelector();
            }

            setAlertTimerToFinish(
              <Alert color="danger" className="alert-timer-to-finish">
                <Icon icon="poli-icon pi-alert-fill" /> Este é um novo contato,
                {exist_template
                  ? ' envie um dos seus templates para iniciar uma conversa com este contato.'
                  : ' aguarde até que ele lhe envie uma mensagem ou crie uma nova Template para ser enviada.'}
              </Alert>
            );
            setCurrentText('');
            return;
          } else {
            if (lastReceivedMessage?.channel_id == selectedChannelId) {
              const lastReceivedMessageDate = parseInt(lastReceivedMessage.dtm);
              const nowInSeconds = Math.floor(Date.now() / 1000);
              is_active = nowInSeconds - lastReceivedMessageDate <= 86400 - 30; ///30 é margem de segurança
            } else {
              is_active = false;
            }
          }

          if (selectedChannelType === 'instagram' || is_active) {
            setAlertTimerToFinish('');
            if (quickMessagesIsOpen) toggleQuickMessagesSelector();
          } else {
            if (exist_template && !chat.quickMessages?.isOpen) {
              toggleQuickMessagesSelector();
            }

            setAlertTimerToFinish(
              <Alert color="danger" className="alert-timer-to-finish">
                <Icon icon="poli-icon pi-alert-fill" /> O período de 24 horas
                para manter uma conversa expirou,
                {exist_template
                  ? ' envie um dos seus templates para voltar a conversar com este contato'
                  : ' aguarde seu contato voltar a conversar'}
              </Alert>
            );
            setCurrentText('');
            setIsTemplateSelected('');
          }
        }
      };

      check();
    } else {
      if (alert_timer_to_finish !== '' && chat.quickMessages?.isOpen) {
        toggleQuickMessagesSelector();
      }
      setAlertTimerToFinish('');
    }
  }, [
    opennedChat?.view?.selectedChannel,
    opennedChatId,
    opennedChat?.messages,
    opennedChat?.last_message,
    canOpenActiveChat,
    hasRolePermissionToStartActiveChat,
    activeChatSetting,
    isZapiOrWebchat,
  ]);

  // Disable/enable textArea and buttonSendMessage.
  useEffect(() => {
    setValidationPermissionSendTextArea(!!validationPermissionTextArea());
    setBlockSendMessageButton(!!validatePermissionToDisableSendMessageButton());

    setIsZapiOrWebchat(
      !['facebook', 'instagram', 'waba'].includes(
        opennedChat?.view?.selectedChannel?.type
      )
    );

    setCanOpenActiveChat(
      permissionsToOpenActiveChat(
        isReceptiveActive,
        hasRolePermissionToStartActiveChat,
        activeChatSetting
      )
    );
  }, [
    opennedChat?.chat?.id,
    canChat,
    isUserAbleToSendMessage,
    alert_timer_to_finish,
    opennedChat?.loadMessages,
    opennedChat?.view?.selectedChannel,
    isTemplateListSelected,
    isButtonsMessageSelected,
    isTemplateApi,
    isTemplateSelected,
    enabledActiveChatFlag,
    canOpenActiveChat,
    isReceptiveActive,
    hasRolePermissionToStartActiveChat,
    activeChatSetting,
  ]);
  const isZapi = !['facebook', 'instagram', 'waba', 'webchat'].includes(
    opennedChat?.view?.selectedChannel?.type
  );

  /* End -> Janela 24 Horas */
  // Verifica se o usuário tem permissão para abrir o chat ativo fora da janela de 24h.
  function permissionsToOpenActiveChat(
    isReceptiveActive,
    activeChatSetting,
    isAdminOrManager
  ) {
    // Returns default true if flag disabled to prevent invalid block.
    return enabledActiveChatFlag
      ? isAdminOrManager || isReceptiveActive || activeChatSetting
      : true;
  }

  // Usado para saber se o Chat Atual é o mesmo do anterior
  if (opennedChatId != opennedChat?.chat?.id) {
    setIsTemplateSelected(false);
    setCurrentText('');
    setOpennedChatId(opennedChat?.chat?.id);
    setIsReceptiveActive(true);
  }

  if (!canChat) {
    return (
      <Alert
        color="warning"
        style={{ margin: 0, justifyContent: 'center' }}
        fade={false}
      >
        <Icon icon="poli-icon pi-alert-fill" color="warning" size="45px" />
        <span>Não existe nenhum canal compatível com este contato.</span>
      </Alert>
    );
  }
  let emojiTheme = 'light';
  let emojiPicker;

  if (localStorage.getItem('@polichat/darkmode') === 'true') {
    emojiTheme = 'dark';
  }

  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        set="apple"
        theme={emojiTheme}
        title=""
        emoji="point_up"
        onSelect={(emoji) => setCurrentText(currentText + emoji.native)}
        style={{
          width: '100%',
          borderBottom: 'none',
        }}
        i18n={{
          search: 'Pesquisar emoji',
          clear: 'Limpar',
          notfound: 'Emoji não encontrado',
          categories: {
            search: 'Emojis Econtrados',
            recent: 'Recentes',
            smileys: 'Smileys & Emotion',
            people: 'Emojis & Pessoas',
            nature: 'Animais & Natureza',
            foods: 'Comida & Bebida',
            activity: 'Atividade',
            places: 'Viagens & Locais',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Bandeiras',
            custom: 'Personalizado',
          },
        }}
        showCloseButton={true}
      />
    );
  }

  function triggerPicker(event) {
    event.preventDefault();

    if (quickMessagesIsOpen) {
      toggleQuickMessagesSelector();
    }
    setEmojiPicker(!emojiPickerState); //emoji true
    closeCatalogCarousel();
  }

  function handleOpenCatalog() {
    if (emojiPickerState) {
      setEmojiPicker(false);
    }

    if (quickMessagesIsOpen) {
      toggleQuickMessagesSelector();
    }

    // if (document.querySelector('.textBoxContainer')) {
    //   document.querySelector('.textBoxContainer')?.classList.add('hidden');
    // }

    // if (document.querySelector('.contactExternalContainer')) {
    //   document
    //     .querySelector('.contactExternalContainer')
    //     ?.classList.add('hidden');
    // }

    // document.querySelector('.buttonUploadFiles')?.classList.add('hidden');
    // document
    //   .querySelector('#buttonHandleQuickMessages')
    //   ?.classList.add('hidden');

    // document.querySelector('.buttonHandleEmoji')?.classList.add('hidden');
    // document.querySelector('.buttonHandleAudio')?.classList.add('hidden');
    // document.querySelector('#sendMessageButton')?.classList.add('hidden');
    // document.querySelector('.groupActionsLeft')?.classList.add('hidden');

    if (isMobile) {
      document.querySelector('.cartPanel')?.classList?.add('hidden');
    }
    openCatalogCarousel();
  }

  function handleCloseCatalog() {
    closeCatalogCarousel();
  }

  function closePickerEmoji(event) {
    event.preventDefault();
    setEmojiPicker(false);
    closeCatalogCarousel();
  }

  function handleStyleBold(text) {
    if (text === '') {
      return null;
    }
    let textMessage = String(
      document.querySelector('#textAreaMessage').value.trimEnd()
    );
    let newText = textMessage.split(text).join(`*${text}*`);
    return setCurrentText(newText);
  }

  function handleStyleItalic(text) {
    if (text === '') {
      return null;
    }
    let textMessage = document
      .querySelector('#textAreaMessage')
      .value.trimEnd();
    let newText = textMessage.split(text).join(`_${text}_`);
    return setCurrentText(newText);
  }

  function handleStyleStrike(text) {
    if (text === '') {
      return null;
    }
    let textMessage = document
      .querySelector('#textAreaMessage')
      .value.trimEnd();
    let newText = textMessage.split(text).join(`~${text}~`);
    return setCurrentText(newText);
  }

  function handleStyleCode(text) {
    if (text === '') {
      return null;
    }
    let textMessage = document
      .querySelector('#textAreaMessage')
      .value.trimEnd();

    let newText = textMessage.split(text).join('```' + text + '```');
    return setCurrentText(newText);
  }

  function isValidJson(isObject) {
    try {
      const valid = typeof isObject === 'object' ? true : false;
      return valid;
    } catch {
      return false;
    }
  }

  // Verifica as hotkeys ou shift + enter
  function handleKeyPressEvent(event) {
    if (event.nativeEvent.keyCode === 13) {
      if (quickMessagesIsOpen) {
        setEmojiPicker(false);
        toggleQuickMessagesSelector();

        let messages = chat.quickMessages.search;
        if (!messages) {
          messages = chat.quickMessages.messages;
        }
        let nav = chat.quickMessages.nav || 0;
        if (nav > messages.length) {
          nav = messages.length - 1;
        }
        if (messages && messages.length >= nav && messages[nav]) {
          if (isValidJson(nav ? messages[nav] : messages[0])) {
            const navOrZero = nav ? nav : 0;
            if (!QuickMessages.selectTable(messages[navOrZero], opennedChat)) {
              return;
            }
            QuickMessages.setMessage(messages[navOrZero]);
            let textMessage = QuickMessages.updateText(
              currentText,
              opennedChat,
              true
            );
            updateText(
              textMessage,
              messages[navOrZero].id,
              (messages[navOrZero].template &&
                messages[navOrZero].validated === 1) ||
                messages[navOrZero].template_name?.indexOf(
                  `messages[${navOrZero}]`
                ) > -1,
              messages[navOrZero].template_name?.indexOf(
                `messages[${navOrZero}]`
              ) > -1
            );
          } else {
            let textMessage = QuickMessages.updateText(
              currentText,
              opennedChat
            );
            updateText(textMessage);
          }
          event.nativeEvent.stopPropagation();
          event.nativeEvent.preventDefault();
          return false;
        }
      } else {
        return handleSubmitTextEvent(event);
      }
    } else if (
      event.nativeEvent.keyCode === 35 &&
      !quickMessagesIsOpen &&
      chat.quickMessages.messages &&
      chat.quickMessages.messages.length
    ) {
      setEmojiPicker(false);
      toggleQuickMessagesSelector();
    } else if (quickMessagesIsOpen) {
      let selectionStart =
        document.querySelector('#textAreaMessage').selectionStart;
      let term = '';
      if (selectionStart > 0) {
        let text = currentText;
        for (let i = text.length; i > 0; i--) {
          if (text.substr(i - 1, 1) === '#') {
            term = text.substr(i, selectionStart) + event.nativeEvent.key;
          }
        }
      }
      searchQuickMessages(term);
    }
  }

  // Essa função separada do handleKeyDown é necessária para o correto funcionamento
  // no android
  function handleInputEvent(event) {
    // Funcionar as pesquisas das mensagens rapidas no android
    if (isMobile) {
      const _txt = event.target.value;
      updateText(_txt);

      let selectionStart =
        document.querySelector('#textAreaMessage')?.selectionStart;
      let term = '';
      if (selectionStart > 0) {
        for (let i = _txt.length; i > 0; i--) {
          if (_txt.substr(i - 1, 1) === '#') {
            term = _txt.substr(i, selectionStart - 1);
          }
        }
      }
      searchQuickMessages(term);
    }
    /* end */

    let text = event.nativeEvent.data;
    if (text) {
      // Abre o painel de quick messages quando o usuário digita '#'
      if (text.slice(-1) === '#' && !quickMessagesIsOpen) {
        setEmojiPicker(false);
        toggleQuickMessagesSelector();
      } else if (text.slice(-1) === ' ' && quickMessagesIsOpen) {
        setEmojiPicker(false);
        toggleQuickMessagesSelector();
      }
    }
  }

  function handleKeyDownEvent(event) {
    QuickMessages.onKeyDownEvent(event);
    if (QuickMessages.existMessage()) {
      let textMessage = QuickMessages.updateText(currentText, opennedChat);
      return updateText(textMessage);
    } else if (
      (event.nativeEvent.keyCode === 32 || event.nativeEvent.keyCode === 27) &&
      quickMessagesIsOpen
    ) {
      setEmojiPicker(false);
      toggleQuickMessagesSelector();
    } else if (event.nativeEvent.keyCode === 8 && quickMessagesIsOpen) {
      const text = currentText;
      const selectionStart =
        document.querySelector('#textAreaMessage').selectionStart;
      let term = '';
      for (let i = text.length; i > 0; i--) {
        if (text.substr(i - 1, 1) === '#') {
          term = text.substr(i, selectionStart - 2);
        }
      }
      searchQuickMessages(term);
    } else if (
      (event.nativeEvent.keyCode === 38 || event.nativeEvent.keyCode === 40) &&
      quickMessagesIsOpen
    ) {
      navQuickMessagesSelector(
        event.nativeEvent.keyCode === 38 ? 'up' : 'down'
      );
      event.nativeEvent.stopPropagation();
      event.nativeEvent.preventDefault();
    }
  }

  function closeQuickMessageSelectorAndClearSearchTerm() {
    searchQuickMessages('', showQuickMessageSelectorV2);

    if (quickMessagesIsOpen) {
      toggleQuickMessagesSelector();
    }

    setFocusOnInputText();
  }

  function handleKeyEventsV2(event) {
    // se seletor está fechado aperta enter deve enviar a mensagem
    if (event.key === KeyboardCodes.ENTER && !quickMessagesIsOpen) {
      return handleSubmitTextEvent(event);
    }

    if (event.key === KeyboardCodes.ESCAPE && quickMessagesIsOpen) {
      closeQuickMessageSelectorAndClearSearchTerm();
    }

    // Lida com os atalhos das mensagens rápidas
    // Detecta se o Ctrl for pressionado COM outra telca (i.e.: ignora só o control sozinho)
    if (event.ctrlKey && event.key !== KeyboardCodes.CONTROL) {
      let result = findQuickMessageWithShortcut(event.key);

      if (result) {
        receiveSelectedQuickMessage(result);
      }
    }
  }

  function wrapperKeyPressEvent(event) {
    if (!showQuickMessageSelectorV2) {
      handleKeyPressEvent(event);
    }
  }

  function wrapperKeyDownEvent(event) {
    if (!showQuickMessageSelectorV2) {
      handleKeyDownEvent(event);
      return;
    }
    handleKeyEventsV2(event);
  }

  function wrapperInputEvent(event) {
    if (!showQuickMessageSelectorV2) {
      handleInputEvent(event);
      return;
    }
    // Abrir o quick message selector o primeiro item digitado é #
    if (
      event.target.value.length === 1 &&
      event.nativeEvent.data === KeyboardCodes.HASH_TAG &&
      !quickMessagesIsOpen
    ) {
      toggleQuickMessagesSelector();
    }

    // Se houver texto apos # realizar a busca do Quick Message Selector
    if (
      quickMessagesIsOpen &&
      event.target.value[0] === KeyboardCodes.HASH_TAG
    ) {
      // Se o ultimo caractere for espaço deve fechar o seletor
      if (event.target.value[event.target.value.length - 1] === ' ') {
        closeQuickMessageSelectorAndClearSearchTerm();
        return;
      }
      // o parametro showQuickMessageSelector é utilizado para passar a flag ao reducer e evitar calculo
      searchQuickMessages(
        event.target.value.substr(1),
        showQuickMessageSelectorV2
      );
    }
  }

  // Submete o texto
  function handleSubmitTextEvent(event) {
    if (event.nativeEvent.shiftKey) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();

      if (currentText) {
        setSentMsg(true);
        setCurrentText(''); // zera o texto atual
        checkTyping(null); // Forçando limpar o texto
        clearTimeout(lastTimeout); //evita enviar o texto antigo para o state novamente
        updateMessageTextArea(opennedChat.id, null); //envia o texto para o state
        sendMessageEvent(); //evento de enviar mensagem
      }
    }
  }

  // Verifica se o usuario parou de digitar e armazena o texto dele no state
  function checkTyping(text) {
    clearTimeout(lastTimeout);
    setLastTimeout(
      setTimeout(
        () => {
          updateMessageTextArea(opennedChat.id, sentMsg ? '' : text);
        },
        isMobile ? 0 : 1000
      )
    );
  }

  function findChannelId() {
    if (opennedChat?.view && opennedChat?.view?.selectedChannel) {
      return opennedChat.view.selectedChannel.id;
    }
    return null;
  }

  // Envia a mensagem pro back via websocket
  function sendMessageEvent() {
    if (blockSendMessageButton) return;

    if (canSendMessage() && isUserAbleToSendMessage) {
      if (currentText) {
        let message_data = mountMessage(opennedChat, currentText, 'chat');

        let data_send = {
          chat_id: opennedChat?.id,
          channel_id: findChannelId(),
          user_id: chat.config?.user?.id,
          contact_id: opennedChat?.contact?.id,
          external_id: findSelectedExternalId(),
          message_body: currentText,
          cuid: message_data.cuid,
          type: message_data.type,
        };

        if (opennedChat.replying_message) {
          message_data.reply_message = opennedChat.replying_message;
          data_send.reply_message_uid =
            opennedChat.replying_message && opennedChat.replying_message.uid
              ? opennedChat.replying_message.uid
              : null;
        }
        if (currentQuickMessageId > 0) {
          try {
            const canSendQuickMessage = validQuickMessageVariables(currentText);
            if (!canSendQuickMessage) {
              return;
            }
          } catch (e) {}

          data_send.quick_message_id = currentQuickMessageId;
        }

        clearReplyingMessage({ chat_id: opennedChat.id });

        addMessage(message_data);
        sendMessage(data_send);
        updateMessageTextArea(opennedChat.id, null);
        setCurrentText('');
        setCurrentQuickMessageId(null);
        setIsTemplateListSelected(false);
        setIsButtonsMessageSelected(false);
      }
    } else {
      toast.warning('Não foi possível enviar a mensagem.');
    }
  }

  function validQuickMessageVariables(quickMessageText) {
    let messageType = 'Mensagem rápida';

    // Trycatch para evitar erro no JSON.parse se for mensagem rápida (not-json).
    try {
      let templatePayload = JSON.parse(quickMessageText) || undefined;

      if (templatePayload && templatePayload.name) {
        if (templatePayload.name.includes('polichat_list_message_')) {
          messageType = 'Lista';
        }

        if (templatePayload.name.includes('polichat_buttons_message_')) {
          messageType = 'Botões interativos';
        }

        if (templatePayload.name.includes('polichat_quick_message_')) {
          messageType = 'Template';
        }
      }
    } catch (e) {}

    // TODO validar as outras variáveis, escrever as mensagens e revisar com o GH.

    if (quickMessageText.includes('[contact_name]')) {
      toast.error(
        `Erro ao enviar ${messageType}. \n A variável contact_name não foi preenchida pois o nome do contato não foi registrado.`
      );
      return false;
    }

    if (quickMessageText.includes('[contact_first_name]')) {
      toast.error(
        `Erro ao enviar ${messageType}. \n A variável contact_first_name não foi preenchida pois o nome do contato não foi registrado.`
      );
      return false;
    }

    if (quickMessageText.includes('[contact_email]')) {
      toast.error(
        `Erro ao enviar ${messageType}. \n A variável contact_email não foi preenchida pois o email do contato não foi registrado.`
      );
      return false;
    }

    return true;
  }

  function canSendMessage() {
    const result = !!(
      opennedChat &&
      opennedChat.chat &&
      opennedChat.view &&
      opennedChat.view.selectedChannel &&
      opennedChat.contact &&
      opennedChat.contact?.externals &&
      opennedChat.contact?.externals?.length &&
      channels.length
    );

    return result;
  }

  function findSelectedExternalId() {
    let selected = opennedChat.contact.externals.find(
      (external) => external.selected === true
    );

    if (selected) return selected.id;

    return null;
  }

  async function onSendAudioBase64(base64, blob) {
    setIsProcessingAudio(true);

    function treatMountedMessageToNanoAudioUpload(mountedMessage) {
      let nanoaudioMessage = {
        message_cuid: `nanoaudio-v2-${mountedMessage.cuid}`,
        type: mountedMessage.type,
        send_type: 'media',
        user_id: chat.config.user.id,
        customer_id: chat.config.user.current_customer_id,
        customer_channel: mountedMessage.channel_id,
        channel_id: mountedMessage.channel_id,
        contact_id: mountedMessage.contact_id,
        external_id: findSelectedExternalId() || 0,
        url: mountedMessage.message_body.url,
        reply_message_uid:
          mountedMessage.reply_message && mountedMessage.reply_message.uid
            ? mountedMessage.reply_message.uid
            : undefined,
      };

      return nanoaudioMessage;
    }

    try {
      let audioMessage = mountMessage(opennedChat, base64, 'ptt');

      if (opennedChat.replying_message) {
        audioMessage.reply_message = opennedChat.replying_message;
      }

      let audioMessageToNanoAudio =
        treatMountedMessageToNanoAudioUpload(audioMessage);

      if (flags?.audio_logs?.enabled) {
        console.log('DEV-292: ', {
          base64,
          blob,
          audioMessage,
          audioMessageToNanoAudio,
        });
      }

      const formData = new FormData();
      formData.append('file', blob);
      formData.append('data', JSON.stringify(audioMessageToNanoAudio));

      await poliApiGatewayRequest
        .post('/nanoaudio/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          clearReplyingMessage({ chat_id: opennedChat.id });
          addMessage({
            ...audioMessage,
            cuid: audioMessageToNanoAudio.message_cuid,
          });
        })
        .catch(function (err) {
          throw err;
        });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        Sentry.captureMessage(`Erro de CORS ao enviar audio.`);
        Sentry.captureException(error);
      }

      console.log('catch error: ', error);
      toast.error('Ocorreu um erro no envio da mensagem de audio');
    } finally {
      setIsProcessingAudio(false);
    }
  }

  function validationPermissionTextArea() {
    if (enabledActiveChatFlag) {
      return (
        !canOpenActiveChat ||
        !canChat ||
        alert_timer_to_finish !== '' ||
        !opennedChat?.loadMessages ||
        !isUserAbleToSendMessage ||
        isTemplateSelected ||
        isTemplateListSelected ||
        isButtonsMessageSelected ||
        (!isTemplateApi && isTemplateSelected)
      );
    }
    return (
      !canChat ||
      alert_timer_to_finish !== '' ||
      !opennedChat?.loadMessages ||
      !isUserAbleToSendMessage ||
      isTemplateSelected ||
      isTemplateListSelected ||
      isButtonsMessageSelected ||
      (!isTemplateApi && isTemplateSelected)
    );
  }

  function validatePermissionToDisableSendMessageButton() {
    if (enabledActiveChatFlag) {
      return (
        !canOpenActiveChat ||
        !canChat ||
        !isUserAbleToSendMessage ||
        (!isTemplateApi && isTemplateSelected)
      );
    }

    return (
      !canChat ||
      !isUserAbleToSendMessage ||
      (!isTemplateApi && isTemplateSelected)
    );
  }

  function getCurrentText() {
    if (
      isTemplateSelected ||
      isTemplateListSelected ||
      isButtonsMessageSelected
    ) {
      // extract from JSON of quick_message.text
      return getMessageBuilderTemplateText(currentText);
    }

    return currentText;
  }

  const checkScheduleWindow = (scheduleDate, scheduleTime) => {
    if (!opennedChat.loadMessages) return false;
    const selectedChannelType = opennedChat?.view?.selectedChannel?.type;
    const _lastReceivedMsg =
      opennedChat?.last_message_received[selectedChannelType];
    const lastReceivedMessage =
      selectedChannelType === 'waba' && _lastReceivedMsg
        ? _lastReceivedMsg[opennedChat?.view?.selectedChannel?.id]
        : _lastReceivedMsg;

    if (!lastReceivedMessage) return false;

    const lastReceivedMessageDate = parseInt(lastReceivedMessage.dtm);

    const [scheduleYear, scheduleMonth, scheduleDay] = scheduleDate
      .split('-')
      .map(Number);
    const [scheduleHour, scheduleMinute] = scheduleTime.split(':').map(Number);

    const scheduleDateTime =
      new Date(
        scheduleYear,
        scheduleMonth - 1,
        scheduleDay,
        scheduleHour,
        scheduleMinute
      ).getTime() / 1000;
    // Verifica se a data de agendamento está dentro da janela de 24 horas da última mensagem
    const isWithin24Hours = scheduleDateTime - lastReceivedMessageDate <= 86400;

    return isWithin24Hours;
  };

  const channelType = opennedChat?.view?.selectedChannel?.type;

  // Função para alternar a exibição do formulário de agendamento
  const toggleSchedulingForm = () => {
    if (!customerHasPoliFlow) {
      toast.warn(
        <div>
          Para agendar mensagens, contrate o Poli Flow. Entre em contato com o
          nosso{' '}
          <a href={TEAM_LINK} target="_blank" rel="noopener noreferrer">
            time
          </a>
        </div>
      ,{theme:'colored',className:'flow-warning'});
      return;
    }

    if(!userHasPoliFlow){
      toast.warn('Para agendar mensagens, primeiro clique no ícone do Poli Flow (botão de funil na barra lateral) e ative sua conta',{theme:'colored',className:'flow-warning'});
      return;
    }
    if (!currentText) {
      toast.warning('Escolha um template ou digite uma mensagem para agendar envio da mensagem.');
      return;
    }

    if (!userHasPoliFlow) {
      toast.warn(
        'Para agendar mensagens, comece clicando no ícone do Poli Flow em nossa barra lateral. Em seguida, ative seu acesso para continuar com o agendamento.',
        { theme: 'colored', className: 'flow-warning' }
      );
      return;
    }
    if (!currentText) {
      toast.warning(
        'Escolha um template ou digite uma mensagem para agendar envio da mensagem.'
      );
      return;
    }

    if (['whatsapp', 'instagram', 'waba'].includes(channelType) || isZapi) {
      setShowSchedulingForm(!showSchedulingForm);
    } else if (['messenger', 'webchat'].includes(channelType)) {
      toast.warning(
        'Não é possível agendar mensagens para o canal selecionado. Por favor, escolha um canal de WhatsApp ou Instagram.'
      );
    } else {
      toast('Canal não suportado para agendamento.');
    }
    if (!showSchedulingForm) {
      setStep(1); // Inicia na etapa de seleção de data/hora
    }
  };

  const nextStep = () => {
    const { date, time } = scheduleData;

    const isWithin24Hours = checkScheduleWindow(date, time);

    // Se não for uma mensagem template e não estiver dentro da janela de 24 horas
    if (!isZapi && !currentQuickMessageId && !isWithin24Hours) {
      toast.error(
        'A data de agendamento deve estar dentro da janela de 24 horas da última mensagem.Envie um dos seus templates para iniciar uma conversa com este contato.'
      );
      return;
    }

    setStep(step + 1);
  };

  // Função para voltar para a etapa anterior
  const prevStep = () => {
    setStep(step - 1);
  };

  // Função para gerenciar mudanças na data e hora
  const handleScheduleChange = (e) => {
    const { name, value } = e.target;

    setScheduleData({
      ...scheduleData,
      [name]: value,
    });
  };

  const cancelScheduling = () => {
    setShowSchedulingForm(false);
    setStep(0);
    setScheduleData({ date: '', time: '' });
  };

  const handleScheduleSubmit = async () => {
    if (!currentText) {
      toast.error('A mensagem não pode ser vazia para agendar.');
      return;
    }
    let customField = 'send_text';
    if (currentQuickMessageId) {
      customField = 'send_template';
    }
    const { date, time } = scheduleData;

    const messageData = {
      message: currentText,
      ...scheduleData,
      contact_id: chat.chatSelectedId,
      responsible: currentUser.id,
      customer_id: currentUser?.settings?.customer_id,
      subtype: opennedChat?.view?.selectedChannel?.id,
      customField: customField,
      selectedChannelId: channelId,
    };
    if (currentQuickMessageId) {
      messageData.template_id = currentQuickMessageId;
    }
    try {
      const response = await scheduleMessage(messageData);
      const formattedDate = date.split('-').reverse().join('/');
      toast.success(
        `Sua mensagem foi agendada para ${formattedDate} às ${scheduleData.time} `
      );
      setStep(0);
    } catch (error) {
      toast.error(
        `Erro ao agendar mensagem: Verifique se o canal, contato e usuário estão cadastrados corretamente no Poli Flow`
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpenPhoto}
        toggle={() => {
          handleClickPreviewThumb;
        }}
        className={'modalLightBox'}
      >
        <ModalHeader
          toggle={() => {
            handleClickPreviewThumb;
          }}
        >
          <div className="btnActionLightBox">
            <span
              onClick={() => {
                setModalOpenPhoto(false);
                window.open(mediaLink);
              }}
              style={{ cursor: 'pointer', marginRight: '10px' }}
            >
              <Icon icon="poli-icon pi-download-line" size={24} color="#fff" />
            </span>
            <span
              onClick={() => {
                setModalOpenPhoto(false);
              }}
            >
              <Icon icon="poli-icon pi-close-line" size={24} color="#fff" />
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          {mediaFormat === 'IMAGE' ? (
            <img
              src={mediaLink}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 0,
                padding: 0,
                borderRadius: 0,
              }}
            />
          ) : mediaFormat === 'VIDEO' ? (
            <div
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 0,
                padding: 0,
                borderRadius: 0,
              }}
            >
              <video controls>
                <source src={mediaLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            ''
          )}
        </ModalBody>
      </Modal>

      {alert_timer_to_finish}
      {isProcessingAudio && (
        <Alert
          color="warning"
          className="alert-timer-to-finish alert-timer-audio"
        >
          <Icon icon="poli-icon pi-alert-fill" /> Aguarde o processamento do
          áudio
        </Alert>
      )}
      {(isWL || isMobile) && (
        <CustomerContactDayAlert
          channelId={Number(opennedChat?.view?.selectedChannel?.id)}
        />
      )}
      {emojiPicker && <div className="emojiContainer">{emojiPicker}</div>}
      {showQuickMessageSelectorV2 ? (
        <QuickMessageSelectorV2
          showOnlyTemplateOption={Boolean(alert_timer_to_finish !== '')}
          setSelectedQuickMessage={receiveSelectedQuickMessage}
        />
      ) : (
        <QuickMessageSelector
          onUpdateMessage={updateText}
          text={currentText}
          onFocusInputText={setFocusOnInputText}
          onlyTemplate={alert_timer_to_finish !== ''}
        />
      )}
      {showCatalogCarousel ? (
        <div className="carouselContainer">
          <CatalogCarousel quickMessagesActivated={quickMessagesIsOpen} />
        </div>
      ) : null}
      {opennedChat.replying_message ? (
        <>
          <div className="replyMessageContainer">
            <div
              className="replyMessageText"
              style={{
                borderLeft: `5px solid ${setBgColorMessages(
                  opennedChat.contact.picture,
                  'replyInTextBox'
                )}`,
              }}
            >
              <p
                className="authorName"
                style={{
                  color: setBgColorMessages(
                    opennedChat.contact.picture,
                    'replyInTextBox'
                  ),
                }}
              >
                {opennedChat.replying_message.author_name}
              </p>
              {opennedChat.replying_message.documentType === 'image' ? (
                <img
                  src={
                    opennedChat.replying_message.thumb &&
                    opennedChat.replying_message.thumb !==
                      opennedChat.replying_message.url
                      ? `data:${opennedChat.replying_message.mimeType};base64,${opennedChat.replying_message.thumb}`
                      : opennedChat.replying_message.url
                  }
                  width={150}
                />
              ) : opennedChat.replying_message.documentType === 'ptt' ||
                opennedChat.replying_message.documentType === 'audio' ? (
                <div className="receivedMessageBalloon">
                  <Audio urlMedia={opennedChat.replying_message.url} />
                </div>
              ) : opennedChat.replying_message.documentType === 'video' ? (
                <video width={320} height={240} controls>
                  <source
                    src={opennedChat.replying_message.url}
                    type={opennedChat.replying_message.mimeType}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : opennedChat.replying_message.documentType === 'document' ||
                opennedChat.replying_message.documentType === 'file' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-file-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'compacted' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-zip-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'compacted' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-zip-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'textEditor' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-word-file-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'textSheet' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-excel-file-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType ===
                'apresentation' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-apresentation-file-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'pdf' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-pdf-file-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  {opennedChat.replying_message.file_name ||
                    opennedChat.replying_message.caption ||
                    opennedChat.replying_message.text}
                </div>
              ) : opennedChat.replying_message.documentType === 'link' ? (
                <div>
                  <Icon
                    icon="poli-icon pi-link-fill"
                    size={40}
                    color="var(--primary-font-color)"
                  />
                  <span>
                    {opennedChat.replying_message.url ||
                      opennedChat.replying_message.caption ||
                      opennedChat.replying_message.text}
                  </span>
                </div>
              ) : opennedChat.replying_message.documentType === 'location' ? (
                <span>
                  <Icon icon="poli-icon pi-location-line" size={15} />{' '}
                  Localização
                </span>
              ) : opennedChat.replying_message.documentType === 'vcard' ? (
                <span>
                  <Icon icon="poli-icon pi-contacts-line" size={15} /> Contato
                </span>
              ) : opennedChat.replying_message.documentType ===
                'multi_vcard' ? (
                <span>
                  <Icon icon="poli-icon pi-contacts-line" size={15} /> Contato
                </span>
              ) : opennedChat.replying_message.documentType === 'media' ? (
                <p className="text">{opennedChat.replying_message.text}</p>
              ) : opennedChat.replying_message.documentType === 'product' ? (
                <>
                  <img
                    style={{ marginRight: '20px', borderRadius: '8px' }}
                    src={
                      opennedChat.replying_message.product &&
                      opennedChat.replying_message.product?.picture &&
                      opennedChat.replying_message.product?.picture
                    }
                    width={100}
                    height={100}
                    alt={opennedChat.replying_message.product?.name}
                  />
                  <span>
                    {opennedChat.replying_message.product?.name
                      ? opennedChat.replying_message.product?.name
                      : 'Catalogo'}
                  </span>
                </>
              ) : opennedChat.replying_message.documentType ===
                'payment_link' ? (
                <>
                  <div
                    style={{
                      marginRight: '20px',
                      borderRadius: '8px',
                      background: 'white',
                      maxWidth: '60px',
                      textAlign: 'center',
                    }}
                  >
                    {opennedChat.replying_message.payment?.reference_type ===
                    'debt' ? (
                      <PayImage width="50" height="50" />
                    ) : (
                      <CartImage width="50" height="50" />
                    )}
                  </div>
                  <span>Pagamento</span>
                </>
              ) : (
                <p className="text">
                  {getMessageBuilderTemplateText(
                    opennedChat.replying_message.text
                  )}
                </p>
              )}
            </div>
            <Button
              border="border-none"
              color="link"
              onClick={() => {
                clearReplyingMessage({ chat_id: opennedChat.id });
              }}
            >
              <Icon icon="poli-icon pi-close-fill" size={24} color={'white'} />
            </Button>
          </div>
          {isTemplateApi ? (
            <Alert color="danger" className="alert-no-reply">
              <Icon icon="poli-icon pi-alert-fill" /> Canais do tipo WABA não
              permitem o uso de respostas
            </Alert>
          ) : null}
        </>
      ) : null}

      {!(
        isTemplateSelected ||
        isTemplateListSelected ||
        isButtonsMessageSelected
      ) &&
        urlPreview?.length > 1 && (
          <div className="previewLink">
            <ReactTinyLink
              cardSize="small"
              showGraphic={true}
              maxLine={2}
              minLine={1}
              url={urlPreview}
              proxyUrl={`${process.env?.REACT_APP_API_GATEWAY_HOST}/proxy`}
            />
          </div>
        )}

      {((isTemplateApi &&
        (isTemplateSelected ||
          isTemplateListSelected ||
          isButtonsMessageSelected)) ||
        (opennedChat?.view?.selectedChannel?.api == 4 &&
          (isTemplateListSelected || isButtonsMessageSelected))) &&
        urlPreview && (
          <PreviewThumbnail
            click={() => {
              handleClickPreviewThumb;
            }}
            media={mediaLink}
            type={mediaFormat}
            header={headerPreview}
            body={bodyPreview}
            footer={footerPreview}
          />
        )}

      {!isTemplateApi && isTemplateSelected && (
        <Alert color="danger" className="alert-timer-to-finish">
          <Icon icon="poli-icon pi-alert-fill" /> Templates só podem ser
          enviados pelo canal WABA. Utilize uma mensagem rápida para conversar
          por este canal.
        </Alert>
      )}

      {/* TODO: rever texto com o time */}
      {!canOpenActiveChat && isZapiOrWebchat && (
        <Alert color="danger" className="alert-timer-to-finish">
          <Icon icon="poli-icon pi-alert-fill" /> conversas ativamente fora da
          janela de 24 horas.
        </Alert>
      )}

      {/* TODO: rever texto com o time */}
      {!canOpenActiveChat && isZapiOrWebchat && (
        <Alert color="danger" className="alert-timer-to-finish">
          <Icon icon="poli-icon pi-alert-fill" /> conversas ativamente fora da
          janela de 24 horas.
        </Alert>
      )}
      {flags.new_channel_selector?.enabled && <ChannelSelectorNew />}
      <div className="textBoxContainer">
        <div className="groupTextArea">
          {settings.can_send_audio ? (
            flags.audio_recorder_v1?.enabled &&
            isCustomerIncludedNewRecorder ? (
              <RecordAudioBase64
                recording={recordingAudio}
                onSend={onSendAudioBase64}
                onChangeRecording={recordingAudioToggle}
              />
            ) : flags.audio_refactor?.enabled &&
              isCustomerIncludedNewRecorder ? (
              <RecorderMP3WhitLamejs
                onSend={onSendAudioBase64}
                recording={recordingAudio}
                onChangeRecording={recordingAudioToggle}
              />
            ) : (
              <RecorderMP3WhitVMSG
                onSend={onSendAudioBase64}
                recording={recordingAudio}
                onChangeRecording={recordingAudioToggle}
              />
            )
          ) : null}
          {!flags.new_channel_selector?.enabled && <ChannelSelector />}
          <div className="textBoxMessage">
            {currentText.length &&
            !(showCatalog && showCatalogCarousel) &&
            !recordingAudio ? (
              <Button
                border="border-none"
                color="link"
                onClick={() => updateText('')}
                id="tooltipClearMessage"
                className="clearMessageText"
              >
                <UncontrolledTooltip
                  placement="bottom"
                  target="tooltipClearMessage"
                >
                  Limpar mensagem
                </UncontrolledTooltip>
                <Icon
                  icon={'poli-icon pi-close-line'}
                  color="var(--red-polichat)"
                  size={20}
                />
              </Button>
            ) : null}

            {validationPermissionSendTextArea ? (
              <div className="input-box">{getCurrentText()}</div>
            ) : (
              <Input
                disabled={validationPermissionSendTextArea}
                style={{
                  cursor: isLoadingCustomerChannelContext ? 'wait' : null,
                }}
                type="textarea"
                name=""
                id="textAreaMessage"
                value={getCurrentText()}
                onChange={(event) => updateText(event.target.value)}
                className="textAreaMessage f-control"
                placeholder="Aa"
                // onClick={(event) => verifyHasOwner(event)}
                onKeyPress={(event) => wrapperKeyPressEvent(event)}
                onKeyDown={(event) => wrapperKeyDownEvent(event)}
                onInput={(event) => wrapperInputEvent(event)}
              />
            )}
          </div>

          {/* Renderiza o formulário de agendamento */}
          {showSchedulingForm && step === 1 && (
            <TaskSecondStep
              date={scheduleData.date}
              time={scheduleData.time}
              handleChange={handleScheduleChange}
              nextStep={nextStep}
              cancelScheduling={cancelScheduling}
            />
          )}

          {/* Renderiza a revisão da mensagem */}
          {showSchedulingForm && step === 2 && (
            <TaskReview
              message={currentText}
              isMessageBuilder={isMessageBuilder}
              date={scheduleData.date}
              time={scheduleData.time}
              prevStep={prevStep}
              handleSubmit={handleScheduleSubmit}
              cancelScheduling={cancelScheduling}
            />
          )}
        </div>
      </div>

      <div className="groupActions">
        <div className="groupActionsLeft">
          {!isMobile && (
            <Dropdown
              direction="up"
              isOpen={fontStylesSwitch}
              toggle={() =>
                setFontStylesSwitch((fontStylesSwitch) => !fontStylesSwitch)
              }
            >
              <DropdownToggle color="link">
                <Icon
                  icon="poli-icon pi-text-fast-fill"
                  size={20}
                  color={'var(--principal-icons-color)'}
                />
              </DropdownToggle>
              <DropdownMenu className="dropdownFontStyles">
                <DropdownItem
                  onClick={() =>
                    handleStyleBold(window.getSelection().toString())
                  }
                >
                  <Icon icon={'poli-icon pi-bold-fill'} size={20} />
                  <span style={{ fontWeight: 'bold' }}>Negrito</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    handleStyleItalic(window.getSelection().toString())
                  }
                >
                  <Icon icon={'poli-icon pi-italic-fill'} size={20} />
                  <span style={{ fontStyle: 'italic' }}>Itálico</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    handleStyleStrike(window.getSelection().toString())
                  }
                >
                  <Icon icon={'poli-icon pi-strike-fill'} size={20} />
                  <span style={{ textDecoration: 'line-through' }}>
                    Riscado
                  </span>
                </DropdownItem>

                <DropdownItem
                  onClick={() =>
                    handleStyleCode(window.getSelection().toString())
                  }
                >
                  <Icon icon={'poli-icon pi-type-code-fill'} size={20} />
                  <span>Código</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}

          {channels && canChat && opennedChat.contact.externals.length > 0 ? (
            <div className="contactExternalContainer">
              <ContactExternalSelector />
            </div>
          ) : (
            ''
          )}
        </div>

        {showCustomBoxWL ? <CustomBox local="footChat" /> : <></>}
        <div className="groupActionsRight">
          {showCart ? <CartPanel /> : null}

          {!isWhiteLabel &&
            (showCatalog ? (
              showCatalogCarousel ? (
                <Button
                  border="border-none"
                  color="link"
                  onClick={handleCloseCatalog}
                  id="tooltipCatalog"
                >
                  <UncontrolledTooltip
                    placement="bottom"
                    target="tooltipCatalog"
                  >
                    Fechar Catálogo
                  </UncontrolledTooltip>
                  <Icon
                    icon={'poli-icon pi-close-line'}
                    color="var(--red-polichat)"
                    size={20}
                  />
                </Button>
              ) : (
                <Button
                  border="border-none"
                  color="link"
                  onClick={handleOpenCatalog}
                  id="tooltipCatalog"
                >
                  <UncontrolledTooltip
                    placement="bottom"
                    target="tooltipCatalog"
                  >
                    Catálogo
                  </UncontrolledTooltip>
                  <Icon
                    icon={'poli-icon pi-catalogo-fill'}
                    color="var(--principal-icons-color)"
                    size={20}
                  />
                </Button>
              )
            ) : null)}

          {settings.can_send_file ? (
            <Button
              border="border-none"
              color="link"
              className="buttonUploadFiles"
              onClick={(e) => {
                e.preventDefault();
                updateChatDropzone(opennedChat.id, true, true);
                closeCatalogCarousel();
              }}
              disabled={!isUserAbleToSendMessage}
            >
              <Icon
                icon={'poli-icon pi-attach-fill'}
                size={20}
                color={'var(--principal-icons-color)'}
              />
            </Button>
          ) : null}
          <Button
            border="border-none"
            color="link"
            onClick={() => {
              setFocusOnInputText();
              setEmojiPicker(false);
              toggleQuickMessagesSelector();
              closeCatalogCarousel();
            }}
            className="buttonHandleQuickMessages"
            id="buttonHandleQuickMessages"
            disabled={!isUserAbleToSendMessage}
          >
            <Icon
              icon={'poli-icon pi-rocket-fill'}
              size={20}
              color={'var(--principal-icons-color)'}
            />
          </Button>
          {emojiPickerState === false ? (
            <Button
              border="border-none"
              color="link"
              onClick={triggerPicker}
              className="buttonHandleEmoji"
              disabled={!isUserAbleToSendMessage}
            >
              <Icon
                icon={'poli-icon pi-smille-fill'}
                size={20}
                color={'var(--principal-icons-color)'}
              />
            </Button>
          ) : (
            <Button
              border="border-none"
              color="link"
              className="buttonHandleEmoji"
              onClick={closePickerEmoji}
            >
              <Icon
                icon={'poli-icon pi-close-line'}
                color="var(--red-polichat)"
                size={20}
              />
            </Button>
          )}
          {settings.can_send_audio &&
          isUserAbleToSendMessage &&
          !poliConfig.channelsCantSendAudioMessage.includes(
            opennedChat?.view?.selectedChannel?.type
          ) ? (
            <ButtonRecordAudio
              recording={recordingAudio}
              onChangeRecording={recordingAudioToggle}
              emojiActivated={emojiPickerState}
              quickMessagesActivated={quickMessagesIsOpen}
            />
          ) : null}
          {scheduleMessageFlag && !whiteLabelPartner && (
            <Button
              id="buttonHandleSchedule"
              border="border-none"
              color="link"
              onClick={toggleSchedulingForm}
              title={
                !currentText
                  ? 'Selecione uma mensagem ou template para agendar'
                  : 'Agendar mensagem'
              }
            >
              <Icon icon="poli-icon pi-day-fill" size={20} />
            </Button>
          )}
          <Button
            id="sendMessageButton"
            border="border-none"
            color="link"
            disabled={blockSendMessageButton}
            onClick={() => sendMessageEvent()}
            title="Enviar"
          >
            <Icon
              icon={'poli-icon pi-set-fill'}
              color="var(--icon-color-font)"
              size={20}
            />
          </Button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  chat: state.chat,
  crm: state.chat.config.general?.crm,
  customerOnboarding: state?.general?.user?.customer_onboarding,
  opennedChat:
    state.chat?.chats?.filter((chat) => {
      return chat.open === true;
    })[0] || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextBox);
